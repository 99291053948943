<!-- 底部 -->
<template>
  <!--PC端-->
  <div class="footer-content hidden lg:block px-10 pt-8 pb-12">
    <div class="index-content-max-width w-full h-full mx-auto flex justify-between items-center flex-wrap">
      <!--  备案信息  -->
      <div>
        <div>&copy; 2023-2026 寻根问祖.COM 版权所有 ICP证：XXXXXXXXXXX</div>
        <div class="mt-1">公安网备 XXXXXXXXXXX号 该网站仅用于测试，不用于上线使用，如有侵权，请联系删除</div>
      </div>

      <!--  菜单  -->
      <div class="menu flex justify-between gap-px">
        <div v-for="menuItem in menuList" :key="menuItem.title" class="menu-item px-10 cursor-pointer"><a :href="menuItem.path">{{ $t("www."+menuItem.title)}}</a></div>
      </div>
    </div>
  </div>

  <!--移动端-->
  <div class="footer-content-mobile block lg:hidden px-10 pt-8 pb-28">

    <!--  菜单  -->
    <div class="menu">
      <div v-for="menuItem in menuList.slice(0, menuList.length - 1)" :key="menuItem.title" class="menu-item footer-common-border-bottom cursor-pointer py-4">
        <a :href="menuItem.path">+ &nbsp; {{ $t("www."+menuItem.title)}}</a>
      </div>
    </div>
    <div class="footer-common-border-bottom text-3xl text-[var(--light-orange)] mt-2 py-4">{{ $t('联系我们') }}</div>
    <div class="footer-common-border-bottom text-lg text-white py-4">{{ $t('地址') }}｜{{$t('地址1')}}</div>
    <div class="footer-common-border-bottom text-lg text-white py-4">{{ $t('邮箱') }}｜2asdufu@yahoo.com</div>
    <div class="footer-common-border-bottom text-lg text-white py-4">{{ $t('电话') }}｜+86 222-222-222</div>
    <!--  备案信息  -->
    <div class="footer-common-border-bottom py-8 text-[var(--middle-grey)]">
      <div>&copy; {{ $t("版权") }}</div>
      <div class="mt-1">{{$t("备案")}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  setup() {
    return {
      // 底部菜单
      menuList: [{
        title: '首页',
        path: '/'
      },{
        title: '寻根谒祖',
        path: '/ancestry'
      },{
        title: '谱牒档案',
        path: '/genealogy'
      },{
        title: '姓氏宗亲',
        path: '/surname'
      },{
        title: '联系我们',
        path: '/contact'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
// PC端
.footer-content {
  font-size: 1.125rem;
  color: white;
  background-color: var(--dark-blue);
  border-top: 0.25rem solid var(--light-orange);

  .menu {

    .menu-item {
      position: relative;
      text-align: center;

      &:not(:last-child)::after {
        content: '';
        width: 0.01rem;
        height: 1rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
      }
    }
  }
}

// 移动端
.footer-content-mobile {
  font-size: 1.125rem;
  color: white;
  background-color: var(--dark-blue);
  border-top: 0.25rem solid var(--light-orange);

  .menu {

    .menu-item {
      border-bottom: 0.06rem solid var(--grey-1);
    }
  }

  .footer-common-border-bottom {
    border-bottom: 0.06rem solid var(--grey-1);
  }
}
</style>
