<template>
  <div class="profile-content">
    <div class="top-banner w-full">
      <div>
        <div class="personal-top-wrapper flex items-center px-4 lg:px-16">
          <img v-if="userInfo?.sex == SEX_MAN" class="w-28 h-28" src="@/assets/images/family-tree/self_active.svg">
          <img v-else class="w-28 h-28" src="@/assets/images/family-tree/spouse_active.svg">
          <div class="personal-basic ml-8">
            <div class="name">{{userInfo?.surname}}{{userInfo?.last_name}}</div>
            <div class="intro flex items-center">
              <span>{{ userInfo?.sex == 1 ? $t('user.男') : userInfo?.sex == 0 ? $t('user.女') : $t('user.未知') }}</span>
              <span v-if="userInfo && userInfo.native_place">{{ userInfo.native_place }}</span>
              <span v-if="userInfo && userInfo.birthday">{{ $moment(userInfo.birthday).format('YYYY-MM-DD') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--资料-->
    <div class="personal-data-detail mt-8 mx-auto grid grid-cols-9 gap-x-4 items-stretch px-4 lg:px-0">
      <div class="menu w-full h-full rounded-2xl py-12 col-span-2 max-lg:col-span-3">
        <div class="menu-item w-full flex items-center" :class="{active: activeMenu == 1}" @click="changeMenuTab(1)">
          <img v-if="activeMenu == 1" class="w-6 h-6" src="@/assets/images/profile/my_information_active.svg">
          <img v-else class="w-6 h-6" src="@/assets/images/profile/my_information_unactive.svg">
          <span class="ml-4">{{ $t('user.我的信息') }}</span>
        </div>
        <div class="menu-item w-full flex items-center" :class="{active: activeMenu == 2}" @click="changeMenuTab(2)">
          <img v-if="activeMenu == 2" class="w-6 h-6" src="@/assets/images/profile/my_genealogy_active.svg">
          <img v-else class="w-6 h-6" src="@/assets/images/profile/my_genealogy_unactive.svg">
          <span class="ml-4">{{ $t('user.我的家谱') }}</span>
        </div>
        <div class="menu-item w-full flex items-center" :class="{active: activeMenu == 3}" @click="logout">
          <img v-if="activeMenu == 3" class="w-6 h-6" src="@/assets/images/profile/logout_active.svg">
          <img v-else class="w-6 h-6" src="@/assets/images/profile/logout_unactive.svg">
          <span class="ml-4">{{ $t('user.退出登录') }}</span>
        </div>
      </div>

      <!--我的信息-->
      <div v-if="activeMenu == 1" class="menu-content my-information rounded-2xl col-span-7 max-lg:col-span-6 px-16 py-12 max-lg:px-8">
        <div class="flex items-center">
          <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
          <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('user.个人资料') }}</span>
        </div>
        <el-form class="information-form mt-6" ref="myInformationFormRef" :model="myInformationForm" label-position="top" :inline="isMobileDevice ? false : true"
                 :rules="rules" :class="{'mobile': isMobileDevice}">
          <el-form-item :label="$t('user.登录账号')" prop="login_name">
            <el-input clearable :placeholder="$t('user.请输入登录账号')" v-model="myInformationForm.login_name" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.昵称')" prop="nick_name">
            <el-input clearable :placeholder="isEditStatus ? $t('user.请输入昵称') : ''" v-model="myInformationForm.nick_name" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.姓氏')" prop="surname">
            <el-input clearable :placeholder="$t('user.请输入姓氏')" v-model="myInformationForm.surname" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.名字')" prop="last_name">
            <el-input clearable :placeholder="$t('user.请输入名字')" v-model="myInformationForm.last_name" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.籍贯')" prop="native_place">
            <el-input clearable :placeholder="isEditStatus ? $t('user.请输入籍贯') : ''" v-model="myInformationForm.native_place" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.手机号码')" prop="mobile">
            <el-input clearable :placeholder="$t('user.请输入手机号码')" v-model="myInformationForm.mobile" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.所在地')">
            <el-select class="province w-1/2" :placeholder="$t('user.省')" v-model="myInformationForm.province"
                       :disabled="!isEditStatus" @change="getCityOptions">
              <template v-for="province in provinceOptions" :key="province.id">
                <el-option :label="province.aname" :value="province.id"></el-option>
              </template>
            </el-select>
            <el-select class="city w-1/2" :placeholder="$t('user.市')" v-model="myInformationForm.city" :disabled="!isEditStatus">
              <template v-for="city in cityOptions" :key="city.id">
                <el-option :label="city.aname" :value="city.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('user.电子邮箱')" prop="email">
            <el-input clearable :placeholder="$t('user.请输入电子邮箱')" v-model="myInformationForm.email" :disabled="!isEditStatus"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.出生日期')" prop="birthday">
            <el-date-picker
                v-model="myInformationForm.birthday"
                type="date" value-format="YYYY-MM-DD"
                :placeholder="$t('user.请输入出生日期')" :disabled="!isEditStatus"
            />
          </el-form-item>
          <el-form-item :label="$t('user.性别')" prop="sex">
            <el-radio-group v-model="myInformationForm.sex" :disabled="!isEditStatus">
              <el-radio-button :label="$t('user.男')" value="1" />
              <el-radio-button :label="$t('user.女')" value="0" />
              <el-radio-button :label="$t('user.保密')" value="2" />
            </el-radio-group>
          </el-form-item>
        </el-form>

        <el-button v-if="!isEditStatus" round class="edit-button h-10" @click="isEditStatus = true;">{{ $t('user.更新资料') }}</el-button>

        <el-button v-else round class="edit-button h-10" @click="updateUserInfo">{{ $t('user.保存') }}</el-button>

      </div>

      <!--我的家谱-->
      <div v-else class="menu-content my-genealogy rounded-2xl col-span-7 max-lg:col-span-6">
        <div class="my-genealogy-top w-full min-h-64 flex flex-col lg:flex-row items-center relative pt-8 px-40 pb-4 max-lg:px-4 max-lg:pt-24">
          <div class="menu-title flex items-center absolute">
            <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
            <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('user.我的家谱') }}</span>
          </div>
          <img class="w-84 h-48" src="@/assets/images/profile/family_tree.svg">
          <div class="ml-20 max-lg:ml-4">
            <div class="title">{{ $t('user.快速创建属于自己的家谱') }}</div>
            <div class="subtitle">{{ $t('user.连接家族记忆与身份认同') }}</div>
            <el-button round class="edit-button mt-12" @click="openCreateDialog(1)">{{ $t('user.立即创建') }}</el-button>
          </div>
        </div>
        <!--已创建家谱-->
        <div class="created-genealogy px-16 py-6 max-lg:px-4">
          <div class="title">{{ $t('user.已创建家谱') }}</div>
          <el-divider class="mt-1 mb-1"/>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-16 mt-6">
            <div class="book-item flex items-end relative" v-for="bookItem in myBooksList" :key="bookItem.id">
              <img class="w-32 h-48" src="@/assets/images/common/book.jpg">
              <div class="book-name"><div>{{bookItem.title}}</div></div>
              <div class="grow">
                <div class="book-title w-full h-12 bg-[var(--middle-grey)] flex items-center px-6 text-[var(--dark-blue)]">{{ bookItem.title }}</div>
                <div class="book-buttons flex flex-wrap items-end w-full h-28 bg-[var(--grey-3)] px-2 py-6 gap-2 max-lg:py-1 max-lg:gap-1">
                  <el-button round @click="openCreateDialog(2, bookItem)">{{ $t('user.编辑信息') }}</el-button>
                  <el-button round><a :href="'/family-tree?id=' + bookItem.id" target="_blank">{{ $t('user.编辑成员') }}</a></el-button>
                  <el-button round @click="deleteBook(bookItem)">{{ $t('删除') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="treeCreateDialogVisible" width="500">
      <template #header>
        <div class="flex items-center">
          <img class="w-5 h-5 mr-2" src="@/assets/images/common/title_icon.svg">
          <span>{{treeCreateDialogType == 1 ? $t('创建家谱') : $t('更新家谱')}}</span>
        </div>
      </template>
      <el-form ref="treeCreateDialogFormRef" :model="treeCreateDialogForm" label-width="auto" label-position="top" :rules="treeCreateDialogFormRules">
        <el-form-item :label="$t('家谱名字')">
          <el-input :class="{'active': treeCreateDialogForm.title}" v-model="treeCreateDialogForm.title" />
        </el-form-item>
        <el-form-item :label="$t('姓氏')">
          <el-input :class="{'active': treeCreateDialogForm.surname}" v-model="treeCreateDialogForm.surname" />
        </el-form-item>
        <el-form-item :label="$t('修撰者')">
          <el-input :class="{'active': treeCreateDialogForm.author}" v-model="treeCreateDialogForm.author" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button round @click="treeCreateDialogVisible = false">{{ $t('取消') }}</el-button>
          <el-button round type="primary" @click="createTreeBook">
            {{ $t('提交') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref, reactive, toRefs} from 'vue'
import ElementPlus from "element-plus";
import { mapState } from 'vuex'
import {updateUserInfo} from "@/api/auth";
import {SEX_MAN, SEX_WOMAN, STATUS_NORMAL, SUCCESS_CODE} from "@/utils/constants";
import {getPubCity} from "@/api/common";
import {getSelfTreeList} from "@/api/family-tree";
import {treeCreate, treeDel, treeUpdate} from "@/api/family-tree";

export default {
  name: "Profile",
  setup() {
    // 当前选中的菜单，默认是第一个
    const activeMenu = ref(1);

    // 我的信息是否是编辑状态
    const isEditStatus = ref(false);

    // 我的信息表单
    const myInformationForm = reactive({});

    // 省市数据
    const provinceOptions = reactive([]);
    const cityOptions = reactive([]);

    // 个人信息表单
    const myInformationFormRef = ref(null);

    // 家谱列表
    const myBooksList = ref([]);

    // 创建家谱弹窗
    const treeCreateDialogVisible = ref(false)
    // 弹窗标题
    const treeCreateDialogType = ref(1);

    // 创建家谱表单
    const treeCreateDialogForm = reactive({});

    return {
      SEX_MAN: SEX_MAN,
      activeMenu,
      isEditStatus,
      myInformationForm,
      provinceOptions,
      cityOptions,
      myInformationFormRef,
      myBooksList,
      treeCreateDialogVisible,
      treeCreateDialogType,
      treeCreateDialogForm
    }
  },
  computed: {
    ...mapState({
      // 用户信息
      userInfo: state => state.userInfo
    }),
    rules() {

      // 验证手机号
      const validateMobile = (rule, value, callback) => {
        if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      }

      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        last_name: {
          required: true,
          message: this.$t('user.请输入名字'),
          trigger: 'blur'
        },
        email: {
          required: true,
          type: 'email',
          message: this.$t('user.请输入正确格式的邮箱'),
          trigger: 'blur'
        },
        mobile: [{
          required: true,
          message: this.$t('user.请输入手机号码'),
          trigger: 'blur'
        }, {
          len: 11, message: this.$t('user.手机号必须是11个字符'), trigger: 'blur'
        },{
          validator: validateMobile, trigger: 'blur'
        }]
      }
    },
    treeCreateDialogFormRules() {
      return {
        title: {
          required: true,
          message: this.$t('请输入家谱名字'),
          trigger: 'blur'
        },
        surname: {
          required: true,
          message: this.$t('请输入姓氏'),
          trigger: 'blur'
        },
        author: {
          required: true,
          message: this.$t('请输入修撰者'),
          trigger: 'blur'
        }
      }
    },
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        let activeMenuTab = parseInt(this.$route.query['tab']);
        if (activeMenuTab) {
          this.activeMenu = parseInt(activeMenuTab);
        }
      }
    },
    userInfo: {
      deep: true,
      immediate: true,
      handler() {
        Object.assign(this.myInformationForm, this.userInfo)
      }
    },
  },
  created() {
    // 获取省份可选列表
    this.getProvinceOptions();
    // 获得家谱树列表
    this.getSelfTreeList();
  },
  methods: {
    /**
     * 更改选中菜单
     * @param tab
     */
    changeMenuTab(tab) {
      this.$router.push({
        path: this.$route.path, // 保持当前路径不变
        query: {'tab': tab} // 更新路由参数
      });
    },
    /**
     * 退出登录
     */
    logout() {
      this.$confirm(
          this.$t('user.确认退出登录吗'),
          this.$t('user.确认'),
          {
            confirmButtonText: this.$t('user.确认'),
            cancelButtonText: this.$t('user.取消'),
            type: 'warning',
            customClass: 'confirm',
          }).then(() => {
        this.$store.dispatch('userLogout').then(res => {
          // 跳转至登录页
          this.$router.push('/auth/login');
        }).catch(error => {

        })
      });
    },
    /**
     * 获取省份可选列表
     */
    getProvinceOptions() {
      getPubCity({parent_id: 1}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.provinceOptions, res.data);
        }
      }).catch(error => {

      })
    },
    /**
     * 获取市区可选列表
     */
    getCityOptions(value) {
      getPubCity({parent_id: value}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.cityOptions, res.data);
          if (!this.cityOptions.find(item => item.id == this.myInformationForm.city)) {
            this.myInformationForm.city = null;
          }
        } else {
          this.cityOptions = [];
          this.myInformationForm.city = null;
        }
      }).catch(() => {
        this.cityOptions = [];
        this.myInformationForm.city = null;
      })
    },
    /**
     * 更新用户信息
     */
    updateUserInfo() {
      this.$refs.myInformationFormRef.validate((valid, fields) => {
        if (valid) {
          let updateInfo = {
            nick_name: this.myInformationForm.nick_name,
            surname: this.myInformationForm.surname,
            last_name: this.myInformationForm.last_name,
            native_place: this.myInformationForm.native_place,
            mobile: this.myInformationForm.mobile,
            province: this.myInformationForm.province,
            city: this.myInformationForm.city,
            email: this.myInformationForm.email,
            birthday: this.myInformationForm.birthday,
            sex: this.myInformationForm.sex
          }
          updateUserInfo(updateInfo).then(res => {
            if (res.code == SUCCESS_CODE) {
              this.isEditStatus = false;
              this.$message({
                message: this.$t('user.用户信息更新成功'),
                type: 'success',
              })
              this.$store.dispatch('fetchUserInfo');
            } else {
              const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
              let messageSorry = this.$t('user.非常抱歉');
              this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t('user.我知道了'),
                callback: (action) => {
                }
              })
            }
          }).catch(error => {

          })
        }
      });
    },
    /**
     * 根据用户ID 获得用户的所有家谱列表
     */
    getSelfTreeList() {
      getSelfTreeList().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.myBooksList = res.data.filter(item => item.status == STATUS_NORMAL);
        } else {
          this.myBooksList = [];
        }
      }).catch(error => {
        this.myBooksList = [];
      })
    },
    /**
     * 打开家谱弹窗，1：创建 2：更新
     */
    openCreateDialog(type, data) {
      this.treeCreateDialogType = type;
      if (type == 1) {
        Object.assign(this.treeCreateDialogForm, {
          title: '',
          surname: '',
          author: ''
        });
      } else {
        Object.assign(this.treeCreateDialogForm, data);
      }
      this.treeCreateDialogVisible = true;
    },
    /**
     * 创建/更新家谱
     */
    createTreeBook() {
      this.$refs.treeCreateDialogFormRef.validate((valid, fields) => {
        if (valid && this.treeCreateDialogType == 1) { // 创建家谱
          treeCreate(this.treeCreateDialogForm).then(res => {
            if (res.code == SUCCESS_CODE) {
              this.$message({
                message: this.$t('user.创建成功'),
                type: 'success',
              });
              this.treeCreateDialogVisible = false;
              this.getSelfTreeList();
            } else {
              const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
              let messageSorry = this.$t('user.非常抱歉');
              this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t('user.我知道了'),
                callback: (action) => {
                }
              })
            }
          }).catch(error => {
            const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
            let messageSorry = this.$t('user.非常抱歉');
            let message = error && error.msg ? error.msg : this.$t('user.创建失败');
            this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('user.我知道了'),
              callback: (action) => {
              }
            })
          })
        } else if (valid && this.treeCreateDialogType == 2) { // 更新家谱
          let params = {
            id: this.treeCreateDialogForm.id,
            title: this.treeCreateDialogForm.title,
            surname: this.treeCreateDialogForm.surname,
            author: this.treeCreateDialogForm.author,
          }
          treeUpdate(params).then(res => {
            if (res.code == SUCCESS_CODE) {
              this.$message({
                message: this.$t('更新成功'),
                type: 'success',
              });
              this.treeCreateDialogVisible = false;
              this.getSelfTreeList();
            } else {
              const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
              let messageSorry = this.$t('user.非常抱歉');
              this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t('user.我知道了'),
                callback: (action) => {
                }
              })
            }
          }).catch(error => {
            const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
            let messageSorry = this.$t('user.非常抱歉');
            let message = error && error.msg ? error.msg : this.$t('user.创建失败');
            this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('user.我知道了'),
              callback: (action) => {
              }
            })
          })
        }
      })
    },
    deleteBook(book) {
      this.$confirm(
          this.$t('确认删除'),
          this.$t('user.确认'),
          {
            confirmButtonText: this.$t('user.确认'),
            cancelButtonText: this.$t('user.取消'),
            type: 'warning',
            customClass: 'confirm'
          }).then(() => {
        let params = {
          id: book.id,
          uid: book.uid
        }
        treeDel(params).then(res => {
          if (res.code == SUCCESS_CODE) {
            this.$message({
              message: this.$t('删除成功'),
              type: 'success',
            });
            this.getSelfTreeList();
          } else {
            const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
            let messageSorry = this.$t('user.非常抱歉');
            this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('user.我知道了'),
              callback: (action) => {
              }
            })
          }
        }).catch(error => {
          this.$message({
            message: error && error.msg ? error.msg : this.$t('user.寻根申请失败'),
            type: 'error',
          })
        })
      }).catch(error => {

      })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-content {
  padding-bottom: 3rem;

  .top-banner {
    background-image: url("@/assets/images/profile/top_banner.jpg");
    background-size: 100% 100%;
    height: calc(var(--header-height) + 12rem);

    &>div {
      width: var(--common-content-width);
      max-width: 100%;
      margin: 0 auto;
      height: 100%;
      position: relative;
    }

    .personal-top-wrapper {
      position: absolute;
      left: 0;
      bottom: -1rem;

      .personal-basic {

        .name {
          font-size: 1.5rem;
          color: white;
          font-weight: bold;
        }

        .intro {

          span {
            margin: 0 1rem 0 0;
            position: relative;
            color: white;

            &:not(:last-child)::after {
              content: '';
              width: 0.01rem;
              height: 1rem;
              background-color: white;
              position: absolute;
              right: -0.5rem;
              top: 45%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  .personal-data-detail {
    width: var(--common-content-width);
    max-width: 100%;

    .menu {
      background-color: white;

      .menu-item {
        border-top: 1px solid var(--light-grey);
        cursor: pointer;
        min-height: 4.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          border-bottom: 1px solid var(--light-grey);
        }

        &.active {
          background-color: var(--light-orange);
          color: white;
        }
      }
    }

    .menu-content {
      background-color: white;

      &.my-information {

        ::v-deep .el-form {

          .el-form-item {
            width: calc(50% - 2.5rem);
            margin-right: 0;

            &:nth-child(n) {
              margin-right: 1.5rem;
            }

            &:nth-child(2n) {
              margin-left: 1.5rem;
            }
          }

          &.mobile .el-form-item {
            width: 100%;
            margin-left: 0;
          }

          .province {
            .el-select__wrapper {
              border-radius: var(--el-border-radius-base) 0 0 var(--el-border-radius-base);
            }
          }

          .city {
            .el-select__wrapper {
              border-radius: 0 var(--el-border-radius-base) var(--el-border-radius-base) 0;
            }
          }

          .el-date-editor {
            width: 100%;
          }

          .el-radio-group {
            width: 100%;

            .el-radio-button {
              width: 33.33333%;
            }

            .el-radio-button__inner {
              width: 100%;
            }
          }
        }
      }

      .edit-button {
        background-color: var(--dark-blue);
        color: var(--light-orange);
        width: 15rem;
        margin-top: 1rem;
      }

      .my-genealogy-top {
        background-color: var(--pale-orange);
        border-radius: 1rem 1rem 0 0;
        .menu-title {
          top: 4rem;
          left: 3rem;
        }

        .title {
          font-size: 1.8rem;
          color: var(--dark-blue);
        }

        .subtitle {
          color: var(--middle-grey);
        }
      }

      .created-genealogy {

        .title {
          color: var(--dark-blue);
        }

        .book-item {

          .book-name {
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 2rem;
            padding: 0.1rem;
            background-color: white;
            max-height: 10rem;

            &>div {
              border: 0.06rem solid var(--darker-blue);
              text-align: center;
              padding: 0.5rem 0;
              font-size: 0.875rem;
              line-height: 1.2;
              height: 100%;
              overflow: hidden;
            }
          }

          .book-title {
            position: relative;

            &::before {
              content: '';
              width: 0.2rem;
              height: 1rem;
              background-color: var(--darker-blue);
              position: absolute;
              left: 1rem;
              top: 50%;
              transform: translateY(-50%);
            }

          }

          .book-buttons {
            .el-button + .el-button {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

}
</style>
