import request from '@/utils/request'

/**
 * 获取文章列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getArticleList(data) {
    return request({
        url: 'api/web/article/list',
        method: 'post',
        data
    })
}

/**
 * 获取文章详情
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getArticleInfo(data) {
    return request({
        url: 'api/web/article/info',
        method: 'post',
        data
    })
}