<template>
  <div class="ancestry-detail pb-16">
    <div class="top-img text-center px-4">
      <span class="ancestry-detail-title">{{ $t('root.祖辈足迹绘千秋') }}{{ $t('root.家族脉络续华章') }}</span>
    </div>
    <div class="contain-content mx-auto mt-7 flex flex-col lg:flex-row justify-between gap-4 px-4">
      <div class="left w-full lg:w-80 flex-none">
        <div class="ancestral-clue px-5 py-14">
          <div class="title pl-2">{{$t('root.先祖线索')}}</div>
          <el-form ref="clueFormRef" :model="clueForm" class="clue-form mt-4" label-position="top" :rules="rules">
            <el-form-item :label="$t('姓氏')" prop="surname">
              <el-input :class="{active:clueForm.surname}" clearable :placeholder="$t('root.请输入您的姓氏')" v-model="clueForm.surname"></el-input>
            </el-form-item>
            <el-form-item class="mt-10" :label="$t('root.始祖/祖辈/族人')" prop="ancestors">
              <el-input :class="{active:clueForm.ancestors}" clearable :placeholder="$t('root.请输入始祖/祖辈/族人中文名字')" v-model="clueForm.ancestors"></el-input>
            </el-form-item>
            <el-form-item class="mt-10" :label="$t('root.祖籍地')" prop="province_id">
              <el-select :class="{active:clueForm.province_id}" v-model="clueForm.province_id" :placeholder="$t('省级地区')" @change="getCityOptions">
                <template v-for="province in provinceOptions" :key="province.id">
                  <el-option :label="province.aname" :value="province.id"></el-option>
                </template>
              </el-select>
              <el-select :class="{active:clueForm.city_id}" class="mt-2.5" v-model="clueForm.city_id" :placeholder="$t('市级地区')">
                <template v-for="city in cityOptions" :key="city.id">
                  <el-option :label="city.aname" :value="city.id"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-form>
          <el-button class="clue-btn mt-3">{{$t('root.立即寻根')}}</el-button>
        </div>
        <div id="ancestry-search-root" class="ancestral-clue full-clue mt-4 px-5 py-14">
          <div class="title pl-2">{{$t('home.故土路遥')}}<br/>{{$t('home.让我们帮您找回血脉亲情')}}</div>
          <el-form ref="fullClueFormRef" :model="fullClueForm" class="clue-form mt-8" label-position="top" :rules="fullRules">
            <el-form-item :label="$t('姓氏')" prop="surname">
              <el-input :class="{active:fullClueForm.surname}" clearable :placeholder="$t('user.请输入姓氏')" v-model="fullClueForm.surname"></el-input>
            </el-form-item>
            <el-form-item :label="$t('root.联系方式')" prop="mobile">
              <el-input :class="{active:fullClueForm.mobile}" clearable :placeholder="$t('root.请输入联系方式')" v-model="fullClueForm.mobile"></el-input>
            </el-form-item>
            <el-form-item :label="$t('root.留言')" prop="describe">
              <el-input :class="{active:fullClueForm.describe}" rows="5" type="textarea" clearable :placeholder="$t('root.有什么想说的都可以留言给我们')" v-model="fullClueForm.describe"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="apply-btn mt-3" @click="rootsApply">{{$t('root.寻根申请')}}</el-button>
        </div>
      </div>

      <div class="matching-information grow -order-1 lg:order-last">
        <div class="matching-result py-8 px-8 lg:px-16 ">
          <img src="@/assets/images/ancestry/match_icon.svg" alt="">
          <div class="inline-block grow pl-8 lg:pl-20 pt-8">
            <div v-if="rootSearchResult.surname" class="text1">{{rootSearchResult.surname + $t('root.氏宗亲')}}</div>
            <div class="text2" v-for="(msg,index) in rootSearchResult.report_msg" :key="index">{{msg}}</div>
            <div class="text3">{{$t('root.匹配度')}}</div>
            <div class="text4 flex "><el-statistic :value="percent_outputValue"/>%</div>
          </div>
        </div>

        <!--匹配族谱-->
        <div v-if="rootSearchResult.books && rootSearchResult.books.length" class="matching-genealogy mt-10">
          <div class="title">
            <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
            <span>{{$t('root.匹配家谱')}}</span>
          </div>
          <el-table class="table mt-2" :data="rootSearchResult.books" :header-cell-style="{background: '#ededed'}">
            <el-table-column :label="$t('www.家谱')" align="center">
              <template #default="scope">
                {{scope.row.book_name}}
                <img v-if="scope.row.has_your_ancestors" class="w-6 h-6 inline-block"
                     src="@/assets/images/ancestry/tag.svg" :title="$t('含有你搜索先祖的名字')">
              </template>
            </el-table-column>
<!--            <el-table-column :label="$t('www.姓氏')" prop="detail" align="left" width="100">-->
<!--              {{rootSearchResult.surname}}-->
<!--            </el-table-column>-->
            <el-table-column :label="$t('root.祖籍地')" align="left">
              <template #default="scope">
                {{scope.row.province + scope.row.city}}
              </template></el-table-column>
            <el-table-column :label="$t('操作')" align="center">
              <el-button type="text" style="color: var(--light-orange)" @click="viewBookDetail">{{$t('root.查看详情')}}</el-button>
            </el-table-column>
          </el-table>
        </div>

        <!--匹配名人-->
        <div v-if="rootSearchResult.celebrities && rootSearchResult.celebrities.length" class="matching-celebrity mt-10">
          <div class="title">
            <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
            <span>{{$t('root.匹配名人')}}</span>
          </div>
          <el-table class="table" :data="rootSearchResult.celebrities" :header-cell-style="{background: '#ededed'}">
            <el-table-column :label="$t('姓名')" align="center" width="70">
              <template #default="scope">
                {{scope.row.surname + scope.row.name}}
              </template>
            </el-table-column>
            <el-table-column :label="$t('描述')" prop="detail" align="left">
              <template #default="scope">
                <div class="truncate-3-lines" :title="scope.row.detail">{{scope.row.detail}}</div>
              </template>
            </el-table-column>
<!--            <el-table-column label="操作" align="center">-->
<!--              <template v-slot="scope">-->
<!--                <el-button type="text" style="color: var(&#45;&#45;light-orange)" @click="handleClick(scope)">{{$t('了解更多')}} ></el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </div>

        <!--匹配源流-->
        <div v-if="rootSearchResult.sources && rootSearchResult.sources.length" class="matching-origin mt-10">
          <div class="title">
            <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
            <span>{{$t('root.匹配源流')}}</span>
            <el-button type="text" class="float-right mt-2 mr-2" style="color: var(--light-orange)">{{$t('root.了解更多')}} ></el-button>
          </div>
          <el-table class="table" :data="rootSearchResult.sources" :header-cell-style="{background: '#ededed'}">
            <el-table-column label="来源" prop="content" align="left"></el-table-column>
          </el-table>
        </div>

        <!--匹配文章-->
        <div v-if="rootSearchResult.articles && rootSearchResult.articles.length" class="matching-article mt-10">
          <div class="title">
            <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
            <span>{{$t('root.匹配文章')}}</span>
            <el-button type="text" class="float-right mt-2 mr-2" style="color: var(--light-orange)"><a href="/news" target="_blank">{{$t('root.了解更多')}} ></a></el-button>
          </div>
          <el-divider class="mt-2.5 mb-1"></el-divider>
          <div class="article">
            <div class="article-item w-full" v-for="item in rootSearchResult.articles" :key="item">
              <div class="dian"></div>
              <div class="text"><a :href="'/news-detail?news-id=' + item.id" target="_blank">{{item.title}}</a></div>
              <div class="time">{{$moment(item.created_at).format('YYYY-MM-DD')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="rootApplicationDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
      <el-form ref="fullClueFormRef" :model="fullClueForm" class="clue-form mt-8" label-position="top" :rules="fullRules">
        <el-form-item :label="$t('姓氏')" prop="surname">
          <el-input :class="{active:fullClueForm.surname}" clearable :placeholder="$t('user.请输入姓氏')" v-model="fullClueForm.surname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.联系方式')" prop="mobile">
          <el-input :class="{active:fullClueForm.mobile}" clearable :placeholder="$t('root.请输入联系方式')" v-model="fullClueForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.留言')" prop="describe">
          <el-input :class="{active:fullClueForm.describe}" rows="5" type="textarea" clearable :placeholder="$t('root.有什么想说的都可以留言给我们')" v-model="fullClueForm.describe"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button round @click="rootApplicationDialogVisible = false" class="bg-[var(--middle-grey)]">{{ $t('取消') }}</el-button>
          <el-button round type="primary" @click="rootsApply">
            {{ $t('一键提交') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="rootApplySuccessDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
      <div class="flex flex-col justify-center items-center">
        <img class="w-32 mt-12 translate-x-4" src="@/assets/images/ancestry/root_apply_success.svg">
        <div class="text-xl text-[var(--dark-blue)]">{{$t('root.资料提交成功')}}</div>
        <div class="text-base text-[var(--dark-grey)] mt-2">{{$t('root.我们的工作人员将与您联系')}}</div>
      </div>
      <template #footer>
        <div class="dialog-footer w-full flex flex-col justify-center items-center mt-12">
          <el-button round type="primary"  @click="rootApplySuccessDialogVisible = false" >
            {{ $t('退出') }}
          </el-button>
          <el-button round @click="rootApplySuccessDialogVisible = false" class="bg-[var(--middle-grey)] mt-4" style="margin-left: 0;">{{ $t('返回') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {rootsApply, rootsSearch} from "@/api/ancestry";
import {ref, reactive} from 'vue';
import {getPubCity} from "@/api/common";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import util from "@/utils/util";
import {useTransition} from "@vueuse/core";

export default {
  name: "AncestryDetail",
  setup(){
    // 先祖线索
    const clueForm = reactive({});

    // 省市数据
    const provinceOptions = reactive([]);
    const cityOptions = reactive([]);

    // 寻根需求
    const fullClueForm = reactive({});

    // 寻根结果
    const rootSearchResult = reactive({});

    // 寻根申请
    const rootApplicationDialogVisible = ref(false);
    // 寻根申请成功
    const rootApplySuccessDialogVisible = ref(false);

    // 匹配度
    const percent = ref(0);
    const percent_outputValue = useTransition(percent, {
      duration: 1500,
    })

    return {
      clueForm,
      provinceOptions,
      cityOptions,
      fullClueForm,
      rootSearchResult,
      rootApplicationDialogVisible,
      rootApplySuccessDialogVisible,
      percent,
      percent_outputValue
    }
  },
  computed: {
    rules() {
      return {
        surname: [
          {required: true, message: this.$t('请输入姓氏'), trigger: "blur"}
        ]
      };
    },
    fullRules() {
      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        mobile: {
          required: true,
          message: this.$t('root.请输入联系方式'),
          trigger: 'blur'
        },
      }
    }
  },
  created() {
    // 获取当前 URL 的查询参数
    const searchParams = new URLSearchParams(window.location.search);
    // 将查询参数转换为对象形式
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = key == 'province_id' || key == 'city_id' ? parseInt(value) : value;
    }
    Object.assign(this.clueForm, params);
    this.rootsSearch();

    // 获取省份可选列表
    this.getProvinceOptions();
  },
  methods:{
    handleClick(scope){
    },
    /**
     * 获取家谱信息
     */
    rootsSearch() {
      let params = {
        surname_id: this.clueForm.surname_id,
        province_id: this.clueForm.province_id || '0',
        city_id: this.clueForm.city_id || '0',
        ancestors: this.clueForm.ancestors || ''
      }
      rootsSearch(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.rootSearchResult, res.data);
          this.percent = res.data.percent;
        } else {
          this.rootSearchResult = {};
        }
      }).catch(error => {
        this.rootSearchResult = {};
      })
    },
    /**
     * 获取省份可选列表
     */
    getProvinceOptions() {
      getPubCity({parent_id: 1}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.provinceOptions, res.data);

          // 获取城市可选列表
          if (this.clueForm.province_id) {
            this.getCityOptions(this.clueForm.province_id);
          }
        } else {
          this.provinceOptions = [];
        }
      }).catch(error => {
        this.provinceOptions = [];
      })
    },
    /**
     * 获取市区可选列表
     */
    getCityOptions(value) {
      this.clueForm.province = this.provinceOptions.find(item => item.id == value).aname;
      getPubCity({parent_id: value}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.cityOptions, res.data);
        } else {
          this.cityOptions = [];
        }
      }).catch(() => {
        this.cityOptions = [];
      })
    },
    /**
     * 寻根需求
     */
    rootsApply() {
      this.$refs.fullClueFormRef.validate((valid, fields) => {
        if (valid) {
          rootsApply(this.fullClueForm).then(res => {
            if (res.code == SUCCESS_CODE && res.msg) {
              this.rootApplicationDialogVisible = false;
              this.rootApplySuccessDialogVisible = true;
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('寻根申请失败'));
          })
        }
      });
    },
    /**
     * 查看家谱详情
     */
    viewBookDetail() {
      this.$message({
        message: this.$t('家谱付费下载功能暂未开放…'),
        type: 'info'
      });
    }
  }
}
</script>

<style scoped lang="scss">
.ancestry-detail{
  background-color: #d7d7d7;
  .top-img{
    height: 28rem;
    width: 100%;
    background: url("@/assets/images/ancestry/bg.jpg") no-repeat;
    background-size: cover;
    padding-top: calc(9rem + var(--header-height));

    .ancestry-detail-title {
      color: var(--dark-blue);
      font-size: var(--common-title-font-size);
    }
  }
  .contain-content{
    width: var(--common-content-width);
    max-width: 100%;

    .left{

      .ancestral-clue{
        width: 100%;
        background-color: #ffffff;
        border-radius: 1.5rem;
        box-sizing: border-box;

        &.full-clue{
          background-color: rgba(255, 255, 255, 0.4);
        }
        .title{
          font-size: 2rem;
          color: var(--dark-blue);
        }

        ::v-deep .clue-form{
          .el-form-item__label{
            font-size: 1rem;
            color: var(--dark-blue);
          }
          .active{
            .el-input__wrapper,.el-select__wrapper{
              background-color: #e8eefc;
            }
          }
          .el-input__wrapper,.el-select__wrapper{
            .el-input__inner{
              height: 2.4rem;
              line-height: 2.4rem;
            }
          }
          .el-select__wrapper{
            height: 2.4rem;
            line-height: 2.4rem;
          }
        }
        .clue-btn{
          background-color: var(--dark-blue);
          border-color: var(--dark-blue);
          color: var(--light-orange);
          border-radius: 2rem;
          height: 3.6rem;
          width: 100%;
        }

        .apply-btn{
          background-color: var(--light-orange);
          border-color: var(--light-orange);
          color: white;
          border-radius: 2rem;
          height: 3.6rem;
          width: 100%;
        }
      }
    }

    .matching-information{
      background-color: #ffffff;
      border-radius: 1.5rem;
      padding: 1.25rem;

      .matching-result{
        background-color: #fff5eb;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        img{
          width: 30%;
        }
        .text1{
          font-size: 1.4rem;
          color: var(--light-orange);
        }
        .text2{
          font-size: 1.4rem;
        }
        .text3{
          margin-top: 1.8rem;
          font-size: 1.2rem;
          color: var(--light-orange);
        }
        .text4{
          font-size: 4rem;
          line-height: 1.2;
          font-weight: bold;
          color: var(--light-orange);

          ::v-deep .el-statistic {

            .el-statistic__content {
              font-size: unset;
            }

            .el-statistic__number {
              font-size: 4rem;
              line-height: 4rem;
              font-weight: 600;
              color: var(--light-orange);
            }
          }
        }
      }

      .title{
        font-size: 1.5rem;
        color: var(--dark-blue);
        img{
          vertical-align: sub;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      .matching-genealogy{
        .genealogy{
          margin-top: 1rem;

          .genealogy-book {
            background-image: url('@/assets/images/ancestry/book.jpg');
            background-size: 10rem auto;
            background-repeat: no-repeat;
            width: 10rem;
            position: relative;

            div {
              position: absolute;
              background-color: var(--pale-pink);
              width: 1.5rem;
              padding: 0.2rem 0;
              font-size: 1rem;
              line-height: 1rem;
              top: 1rem;
              text-align: center;
              left: 1.2rem;
            }
          }
          .genealogy-text{
            font-size: 1rem;
            div{
              margin-bottom: 0.25rem;
            }
            .genealogy-name{
              font-size: 1.25rem;
              font-weight: bold;
            }
          }
        }
      }
      .matching-celebrity{
        .title{
          margin-bottom: 0.6rem;
        }
      }
      .matching-origin{
        .origin{
          .text{
            font-size: 1rem;
            line-height: 1.5;
          }
          .img{
            width: 20rem;
            height: 30rem;
            border-radius: 1.5rem;
            overflow: hidden;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .matching-article{
        .article{
          padding-left: 2.5rem;
          padding-bottom: 2rem;
          .article-item{
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 0.06rem solid var(--light-grey);
            height: 3rem;
            align-items: center;
            justify-content: space-between;

            .dian{
              width: 0.1rem;
              height: 0.1rem;
              border-radius: 0.06rem;
              background-color: #000000;
              margin-right: 0.6rem;
              margin-left: 0.25rem;
            }
            .text{
              font-size: 1rem;
              flex: 1;
            }
            .time{
              font-size: 1rem;
              width: 6rem;
              color: var(--dark-grey);
            }
          }
        }
      }
    }
  }
}
.ljgd-btn{
  background-color: transparent;
  border-color: var(--light-orange);
  color: var(--light-orange);
  width: 6rem;
  height: 2rem;
  border-radius: 1rem;
  padding: 0;
}
</style>
