<!-- 顶部 -->
<template>
  <div class="header-content w-full bg-[var(--dark-blue)]" :class="{'bg-transparent': activePath == '/surname'}">
    <div class="index-content-max-width w-full h-full mx-auto flex justify-between px-8 pt-2 items-center text-center">
      <!--  logo+菜单  -->
      <div class="h-full flex gap-x-20 items-center">
        <div class="logo w-50">
          <el-link :href="'/'" :underline="false">LOGO</el-link>
        </div>
      </div>

      <!--  右侧工具栏  -->
      <div class="tools flex justify-end items-center gap-x-6">
        <el-select class="my-account h-10"
                   :model-value="currentLang"
                   @change="changeLang">
          <el-option
              v-for="item in langsOption"
              :key="item.key"
              :label="item.title"
              :value="item.key"
          />
        </el-select>
        <img v-if="!mobileMenuDrawer" class="w-8 h-8 cursor-pointer" src="@/assets/images/header/mobile_menu_icon.svg" @click="mobileMenuDrawer = true;">

        <el-drawer
            v-model="mobileMenuDrawer"
            direction="rtl" :modal="false"
        >
          <el-menu :default-openeds="[1]">
            <template v-for="(menuItem, index) in menuList" :key="menuItem.title">
              <el-sub-menu v-if="menuItem.children && menuItem.children.length > 0" :index="index">
                <template #title>
                  <span>{{ $t('www.'+menuItem.title) }}</span>
                </template>
                <el-menu-item v-for="submenu in menuItem.children" :key="submenu.title"><a
                    :href="submenu.path">{{ $t('www.'+submenu.title) }}</a></el-menu-item>
              </el-sub-menu>
              <el-menu-item v-else :index="index"><a :href="menuItem.path">{{ $t('www.'+menuItem.title) }}</a></el-menu-item>
            </template>
          </el-menu>

          <el-button v-if="!isAuthenticated" round
                     class="header-button login-button w-full h-auto block text-white text-base border-0 px-10 mt-12">
            <el-link href="/auth/login" :underline="false">
              {{ $t('www.登录') }}
            </el-link>
          </el-button>
          <el-button v-if="!isAuthenticated" round
                     class="header-button signup-button w-full h-auto block text-white text-base border-0 px-10 mt-4">
            <el-link href="/auth/register" :underline="false">
              {{ $t('www.注册') }}
            </el-link>
          </el-button>
          <div v-if="isAuthenticated" class="avatar-name w-full flex justify-center items-center mt-8">
            <img v-if="userInfo && userInfo.sex == SEX_MAN" class="w-10 h-10" src="@/assets/images/header/man.svg">
            <img v-else class="w-12 h-12" src="@/assets/images/header/woman.svg">
            <span class="text-white text-lg ml-2">{{userInfo && userInfo.surname}}{{userInfo && userInfo.last_name}}</span>
          </div>
          <el-select v-if="isAuthenticated" class="my-account mt-2" :placeholder="$t('user.我的账户')">
            <el-option :label="$t('user.我的信息')" value="1" @click="goToProfile(1)"></el-option>
            <el-option :label="$t('user.我的家谱')" value="2" @click="goToProfile(2)"></el-option>
            <el-option :label="$t('user.退出登录')" value="3" @click="goToProfile(3)"></el-option>
          </el-select>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {SEX_MAN, SEX_WOMAN} from "@/utils/constants";
import { mapState } from 'vuex'

export default {
  name: "MobileHeader",
  setup() {

    // 移动端菜单抽屉
    const mobileMenuDrawer = ref(false);

    return {
      // 顶部菜单
      menuList: [{
        title: '首页',
        path: '/index'
      }, {
        title: '寻根谒祖',
        children: [{
          title: '一键寻根',
          path: '/ancestry'
        },{
          title: '新闻中心',
          path: '/news'
        }]
      },{
        title: '谱牒档案',
        path: '/genealogy'
      },{
        title: '姓氏宗亲',
        path: '/surname'
      },{
        title: '家谱',
        path: '/family-tree'
      }],
      // 切换语言选项
      langsOption: [{
        title: '简体中文',
        lang: 'zh',
        key: 'zh-CN'
      }, {
        title: '繁體中文',
        lang: 'tw',
        key: 'zh-TW'
      }, {
        title: 'English',
        lang: 'en',
        key: 'en'
      }],
      SEX_MAN: SEX_MAN,
      SEX_WOMAN: SEX_WOMAN,
      mobileMenuDrawer
    }
  },
  computed: {
    ...mapState({
      // 是否登录
      isAuthenticated: state => !!state.token,
      // 用户信息
      userInfo: state => state.userInfo,
      // 当前语言
      currentLang: state => state.lang
    }),
    // 当前路径
    activePath() {
      return this.$route.path;
    },
    activeMenuIndex() {
      let path = this.$route.fullPath;
      let activeMenuIndex = 0;
      this.menuList.some((menu,index) => {
        if(path.startsWith(menu.path)) {
          activeMenuIndex = index;
          return true;
        }
        if (menu.children && menu.children.some(submenu => path.startsWith(submenu.path))) {
          activeMenuIndex = index;
          return true;
        }
        return false;
      })
      return activeMenuIndex;
    }
  },
  mounted() {
    this.unpdateMenuHover(this.activeMenuIndex);
  },
  methods: {
    /**
     * 更改语言
     */
    changeLang(value) {
      let selectLangItem = this.langsOption.find(item => item.key == value);
      this.$store.dispatch('langSet', selectLangItem.key).then(res => {
        this.$i18n.locale = selectLangItem.lang;
      }).catch(error => {

      })
    },
    /**
     * 跳转至个人详情页
     * @param value
     */
    goToProfile(value) {
      this.$router.push('/profile?tab=' + value);
      this.mobileMenuDrawer = false;
    },
    /**
     * 更新hover效果
     * @param menuIndex
     */
    unpdateMenuHover(menuIndex) {
      if (this.$refs && this.$refs.menuBgRef) {
        this.$refs.menuBgRef.style['-webkit-transform'] = 'translate(' + (menuIndex * 9 - 2.9) + 'rem,-50%)'
      }
    }
  }
}
</script>

<style scoped lang="scss">
// 顶部
.header-content {
  height: var(--header-height);

  .logo {

    ::v-deep .el-link {
      color: white;
      font-size: 3rem;
      font-weight: bold;
    }
  }

  .menu {
    background-color: transparent;
    height: calc(var(--header-height) - 1.5rem);
    position: relative;

    .menu-bg {
      height: 72%;
      width: auto;
      position: absolute;
      top: 50%;
      transform: translate(-2.9rem, -50%);
      transition: all 0.3s ease;
    }

    .menu-item {
      width: 4rem;
      height: 72%;
      line-height: 100%;
      position: relative;
      text-align: center;

      .el-link {
        color: white;
        font-weight: normal;
        width: 100%;
      }

      .el-dropdown {
        .el-dropdown-link:focus-visible {
          outline: unset;
        }
      }
    }
  }

  // 右侧工具栏
  .tools {

    .header-button {
      padding: 1rem 2rem;
    }

    .login-button {
      background-color: var(--dark-grey);

      &:hover {
        color: white;
      }

      ::v-deep .el-link {
        color: white;
      }
    }

    .signup-button {
      background-color: var(--middle-grey);

      &:hover {
        color: var(--el-button-text-color);
      }

      ::v-deep .el-link {
        color: var(--el-button-text-color);
      }
    }

    ::v-deep .el-select {
      width: 9rem;
      position: relative;

      .el-select__wrapper {
        border-radius: 9999px;
        background-color: transparent;
        padding-right: 0.8rem;
        box-shadow: 0 0 0 0.06rem var(--dark-grey) inset;
        height: 2.5rem;
        line-height: 2.5rem;
        //padding: 0.25rem 0.55rem 0.25rem 0.75rem;

        .el-icon {
          color: var(--dark-grey);
        }

        &.is-focused {
          box-shadow: 0 0 0 0.06rem var(--dark-grey) inset;
        }

        .el-select__placeholder {
          color: var(--dark-grey);
        }
      }

      &.lang-select .el-select__wrapper::before {
        content: "";
        width: 2.5rem;
        height: 2.5rem;
        top: 0;
        right: 0;
        border-radius: 50%;
        border: 0.06rem solid var(--dark-grey);
        position: absolute;
      }
    }

    ::v-deep .el-select.my-account {
      width: 5.5rem;
      padding: 0;

      .el-select__wrapper {
        box-shadow: unset;
        padding: 0;

        .el-select__placeholder {
          color: var(--lighter-grey);
        }

        .el-icon {
          color: var(--lighter-grey);
        }
      }
    }

    ::v-deep .el-button + .el-button {
      margin-left: 0;
    }

    ::v-deep .el-drawer {
      background-color: var(--darker-blue);
      color: white;
      width: 18rem!important;
      height: 45rem;
      border-radius: 0 0 2rem 2rem;

      .el-drawer__close-btn {
        border: 1px solid var(--dark-grey);
        border-radius: 1rem;
        padding: 0.5rem;
      }

      .el-drawer__header {
        margin-bottom: 0;
        height: var(--header-height);
        padding: 1.5rem 2rem;
      }

      .el-drawer__body {
        padding: 0 1rem 1rem;
        flex: unset;
        height: auto;

        .el-menu {
          background-color: transparent;
          color: white;
          border-right: none;

          .el-menu-item, .el-sub-menu__title {
            color: white;
          }

          .el-menu-item:hover, .el-sub-menu__title:hover{
            background-color: transparent;
          }

          .el-sub-menu {

            .el-menu-item {
              position: relative;

              a::before {
                content: '';
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 9999px;
                background-color: var(--light-orange);
                position: absolute;
                left: 2rem;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .el-menu-item, .el-sub-menu {
          border-top: 0.06rem solid #22284f;
        }

        .el-menu.el-menu--vertical>li:last-child {
          border-bottom: 0.06rem solid #22284f;
        }
      }
    }

  }
}
</style>
