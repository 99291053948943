
<template>
  <div class="news-content">
    <!--顶部banner和标题-->
    <div class="top-wrapper">
      <el-carousel>
        <el-carousel-item v-for="item in articleList" :key="item.id">
          <div class="carousel-item">
            <img class="w-full lg:w-3/5" :src="item.image_url" alt="">
            <div class="rb-info w-11/12 lg:w-3/4 xl:w-1/2 hidden lg:block">
              <div class="time">{{ $moment(item.created_at).format('YYYY-MM-DD') }}</div>
              <div class="title">{{item.title}}</div>
              <div class="title2">{{ item.subtitle }}</div>
              <div class="text mt-8">{{item.introduction}}</div>
              <el-button round class="primary-search-button mt-9 min-w-32" @click="goToNewsDetail(item.id)">{{$t('了解详情')}}</el-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div v-if="articleList && articleList[0]"  class="rb-info-mobile w-full block lg:hidden p-8">
      <div class="time">{{ $moment(articleList[0].created_at).format('YYYY-MM-DD') }}</div>
      <div class="title">{{articleList[0].title}}</div>
      <div class="title2">{{ articleList[0].subtitle }}</div>
      <div class="text mt-8">{{articleList[0].introduction}}</div>
      <el-button round class="primary-search-button mt-9 min-w-32" @click="goToNewsDetail(articleList[0].id)">{{$t('了解详情')}}</el-button>
    </div>

    <!--热门文章-->
    <div class="hot-article mx-auto mt-8 px-4">
      <div class="article-title">
        <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
        <span>{{$t('热门文章')}}</span>
      </div>
      <div class="article-list mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div class="article-item flex flex-nowrap items-center" v-for="(item,index) in articleList" :key="index">
          <div class="left-img">
            <img :src="item.image_square_url" alt="">
          </div>
          <div class="right">
            <div class="title truncate-2-lines">{{item.title}}</div>
            <div class="roots-text mt-2 mb-8 truncate-3-lines"><span>{{item.introduction}}</span></div>
            <div class="time">{{ $moment(item.created_at).format('YYYY-MM-DD') }}</div>
            <el-button round class="search-button" @click="goToNewsDetail(item.id)">{{$t('了解详情')}} ></el-button>
          </div>
        </div>
      </div>
      <pagination class="w-full mt-4" :page-num="queryParams.page" :page-size="queryParams.limit" :total-count="totalCount"
                  @go-one="goOne" @go-pre="goPre" @go-next="goNext"></pagination>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {ArrowLeft, Back, Right} from "@element-plus/icons-vue";
import {getArticleList} from "@/api/news";
import {SUCCESS_CODE} from "@/utils/constants";
import Pagination from "@/components/Pagination";

export default {
  name: "News",
  components: {Pagination, Back, Right, ArrowLeft},
  data(){

    // 请求文章列表参数
    const queryParams = ref({
      page: 1,
      limit: 10,
    });

    // 文章列表
    const articleList = ref([]);

    // 文章总数
    const totalCount = ref(0);
    // 文章总页数
    const totalPage = ref(0);

    return {
      carouselList:[
        {id:1},
      ],
      queryParams,
      articleList,
      totalCount,
      totalPage,
    }
  },
  created() {
    this.getArticleList(this.queryParams);
  },
  methods:{
    /**
     * 跳转至第一页
     */
    goOne(){
      if (this.queryParams.page == 1) {
        this.$message({
          message: this.$t('已经在第一页了'),
          type: 'info',
        });
        return;
      }
      this.queryParams.page = 1;
      this.getArticleList(this.queryParams);
    },
    /**
     * 跳转至前一页
     */
    goPre(page){
      if (this.queryParams.page == 1) {
        this.$message({
          message: this.$t('已经在第一页了'),
          type: 'info',
        });
        return;
      }
      this.queryParams.page = page || 1;
      this.getArticleList(this.queryParams);
    },
    /**
     * 跳转至后一页
     */
    goNext(page){
      if (this.queryParams.page == this.totalPage) {
        this.$message({
          message: this.$t('已经在最后一页了'),
          type: 'info',
        });
        return;
      }
      this.queryParams.page = page > this.totalPage ? this.totalPage : page;
      this.getArticleList(this.queryParams);
    },
    /**
     * 获取文章列表
     * @param page
     */
    getArticleList(queryParams) {
      let params = {
        id: 4,
        ...queryParams
      }
      getArticleList(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.articleList = res.data.list.map(item => {
            item.text = '4月10日，2024“中国寻根之旅”春令营-遇见河南.根亲营开营仪式在河南郑州举行，来自澳大利亚、新西兰、泰国、印尼的45名年根亲营开营仪式在河南郑州举行，来自澳大利亚、新西兰、泰国、印尼的45名年';
            return item;
          });
          this.totalCount = res.data.total_count;
          this.totalPage = res.data.total_page;
        }
      }).catch(error => {

      })
    },
    goToNewsDetail(newsId) {
      this.$router.push('/news-detail?news-id=' + newsId);
    }
  }
}
</script>

<style scoped lang="scss">
.news-content{
  background-color: #f0f0f0;

  .top-wrapper {

    ::v-deep .el-carousel {
      --news-carousel-height: 40rem;

      .el-carousel__container {
        height: var(--news-carousel-height);
      }
    }
  }

  .carousel-item{
    position: relative;
    background-color: var(--dark-blue);
    img{
      height: var(--news-carousel-height);
      object-fit: cover;
    }
    .rb-info{
      position: absolute;
      //width: 43%;
      top: calc(4rem + var(--header-height));
      right: 3rem;
      background-color: #ffffff;
      border-radius: 1.5rem;
      padding: 3rem 5rem;
      box-sizing: border-box;

      .time{
        font-size: 0.9rem;
        color: var(--dark-grey);
      }
      .title{
        font-size: 3rem;
        color: var(--dark-blue);
      }
      .title2{
        font-size: 1rem;
        color: var(--dark-blue);
      }
      .text{
        font-size: 1rem;
        color: var(--dark-grey);
      }
    }
  }
  .rb-info-mobile{
    padding: 2rem 2rem;

    .time{
      font-size: 0.9rem;
      color: var(--dark-grey);
    }
    .title{
      font-size: 3rem;
      color: var(--dark-blue);
    }
    .title2{
      font-size: 1rem;
      color: var(--dark-blue);
    }
    .text{
      font-size: 1rem;
      color: var(--dark-grey);
    }
  }

  ::v-deep .el-carousel__arrow {
    display: none;
  }

  ::v-deep .el-carousel__indicators{
    right: 3rem;
    left: auto;
    bottom: 1rem;
    .el-carousel__indicator{
      &.is-active{
        .el-carousel__button{
          background-color: var(--light-orange);
        }
      }
      .el-carousel__button{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .hot-article{
    width: var(--common-content-width);
    max-width: 100%;
    padding-bottom: 2rem;

    .article-title{
      font-size: 1.5rem;
      img{
        vertical-align: sub;
        height: 2rem;
        margin-right: 1rem;
      }
    }
    .article-list{

      .article-item{
        overflow: hidden;
        width: 100%;
        background-color: #ffffff;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 1.2rem;

        .right{
          flex: 1;
          margin-left: 2.2rem;
          position: relative;

          .title{
            font-size: 1.3rem;
            height: 4rem;
          }
          .time{
            font-size: 0.9rem;
            color: var(--dark-grey);
          }
          .roots-text{
            font-size: 1rem;
            letter-spacing: 0.06rem;
            color: var(--dark-grey);
          }
          .search-button{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 8rem;
            height: 2.2rem;
            background-color: transparent;
            border-color: var(--light-orange);
            color: var(--light-orange);
          }
        }
        .left-img{
          width: 44%;
          height: 100%;
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>