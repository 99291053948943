<!-- 首页 -->
<template>
    <div class="index_content">
        <!--谱牒-->
        <div class="genealogy index-content-max-width grid lg:px-6 pt-4 pb-6 justify-between w-full mx-auto">
            <div class="genealogy-left">
                <div class="title max-lg:px-4">{{ $t('home.家谱行囊中的') }}{{ $t('home.吾乡吾土') }}</div>

                <div class="detail mt-4 max-lg:px-4">{{
                    $t('home.家谱祖先风骨绘就的诗篇')
                    }}
                </div>

                <img src="@/assets/images/home/genealogy_devider_right.svg" class="mt-10 hidden lg:block"/>

              <!--PC端检索谱牒-->
                <div class="gap-x-4 mt-10 hidden lg:flex">
                    <div class="search-guide">
                        <span>{{ $t('www.检索谱牒') }}</span>
                        <el-icon class="ml-2">
                            <Right/>
                        </el-icon>
                    </div>
                    <CommonSearch v-model:search-value="genealogySearchSurname" :place-holder="$t('www.请输入您的姓氏')" :button-value="$t('www.搜索')" class="grow" @click-button="goAncestry"></CommonSearch>
                </div>

              <!--移动端检索谱牒-->
              <div class="mt-10 block lg:hidden p-8 pb-12 rounded-3xl bg-[var(--dark-blue)]">
                <div class="search-guide justify-normal pl-4">
                  <span class="text-2xl">{{ $t('www.检索谱牒') }}</span>
                  <el-icon class="ml-2">
                    <Right/>
                  </el-icon>
                </div>
                <CommonSearch v-model:search-value="genealogySearchSurname" :place-holder="$t('www.请输入您的姓氏')"
                              :button-value="$t('www.搜索')" class="grow" @click-button="goAncestry"></CommonSearch>
              </div>

<!--                <div class="hot-genealogy flex justify-start items-center mt-6">-->
<!--                    <div class="hot-genealogy-tip flex-none">-->
<!--                        <img v-if="currentLang == 'zh'" src="@/assets/images/home/genealogy_hot_zh.svg"/>-->
<!--                        <img v-else src="@/assets/images/home/genealogy_hot_en.svg"/>-->
<!--                    </div>-->
<!--                    <div class="hot-genealogy-info grow ml-4 flex flex-wrap flex-col lg:flex-row gap-x-8">-->
<!--                      <div>仁宗睿皇帝位下第五子和硕惇恪亲王家谱不分卷</div>-->
<!--                      <div>柳溪傅氏宗谱(浙江省金华市浦江县)</div>-->
<!--                      <div>资兴蒋氏五修族谱第三卷(湖南省郴州市资兴市)</div>-->
<!--                      <div>连山万竹社稼版康氏宗谱六卷(浙江省宁波市奉化市)</div>-->
<!--                    </div>-->
<!--                </div>-->

                <img src="@/assets/images/home/genealogy_devider_left.svg" class="mt-12 hidden lg:block"/>

                <div class="number-info mt-10 lg:mt-12 max-lg:mb-10 max-lg:px-8 max-lg:text-xl flex justify-start gap-x-16 max-lg:text-[var(--dark-blue)]">
                    <div>
                        <el-statistic :value="searchValue" suffix="W"/>
                        <div class="info">{{ $t('home.家谱中找寻血脉') }}</div>
                    </div>
                    <div>
                        <el-statistic :value="searchPercent" suffix="%"/>
                        <div class="info">{{ $t('home.都能发现家庭成员') }}</div>
                    </div>
                </div>

                <img src="@/assets/images/home/genealogy_devider_right.svg" class="mt-10 hidden lg:block"/>
            </div>

            <div class="genealogy-right h-full">
                <el-carousel class="carousel w-full h-full" indicator-position="none" arrow="always">
                    <el-carousel-item v-for="item in carouselList" :key="item.id">
                        <img :src="item.src">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!--寻根-->
        <div class="bg-[var(--dark-blue)] w-full">
            <div class="search-root index-content-max-width flex px-8 lg:px-16 lg:pt-28 max-lg:pt-16 pb-20 gap-x-16 mx-auto">
                <!--图片/视频轮播 PC端-->
                <div class="image-gallary max-lg:hidden h-full flex gap-x-1.5 grow">
                    <div
                            class="video-item"
                            v-for="(item, index) in seaechRootVideos"
                            :key="index"
                            :class="{ 'active': activeIndex === index }"
                            @mouseenter="changeVideoPlay(index)"
                    >
                        <video :id="'searchRootVideo' + index" class="search_root_video" muted loop
                               controls :poster="item.poster">
                            <source :src="item.src">
                            您的浏览器不支持HTML5视频。
                        </video>
                    </div>
                </div>

                <!--       移动端轮播        -->
                <el-carousel class="video-carousel lg:hidden" indicator-position="outside" arrow="always"
                             :autoplay="false" @change="changeVideoPlay">
                  <el-carousel-item v-for="(item, index) in seaechRootVideos" :key="index">
                    <video :id="'searchRootVideo' + index" class="search_root_video rounded-2xl" muted loop
                           controls :poster="item.poster">
                      <source :src="item.src">
                      您的浏览器不支持HTML5视频。
                    </video>
                  </el-carousel-item>
                </el-carousel>

                <!--寻根说明-->
                <div class="search-root-right px-4 lg:px-16 py-12 lg:text-center">
                    <template v-if="activeIndex == 1">
                        <div class="title">{{ $t('home.1_t1') }}<br>{{ $t('home.1_t2') }}</div>
                        <div class="sub-title">{{ $t('home.1_t3') }}</div>
                        <div class="info mt-10">
                            {{ $t('home.1_c1') }}
                        </div>
                    </template>
                    <template v-else-if="activeIndex == 2">
                        <div class="title">{{ $t('home.2_t1') }}<br>{{ $t('home.2_t2') }}</div>
                        <div class="sub-title">{{ $t('home.2_t3') }}</div>
                        <div class="info mt-10">
                            {{ $t('home.2_c1') }}
                        </div>
                    </template>
                    <template v-else-if="activeIndex == 3">
                        <div class="title">{{ $t('home.3_t1') }}<br>{{ $t('home.3_t2') }}</div>
                        <div class="sub-title">{{ $t('home.3_t3') }}</div>
                        <div class="info mt-10">
                            {{ $t('home.3_c1') }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="title">{{ $t('home.4_t1') }}<br>{{ $t('home.4_t2') }}</div>
                        <div class="sub-title">{{ $t('home.4_t3') }}</div>
                        <div class="info mt-10">
                            {{ $t('home.4_c1') }}
                        </div>
                    </template>
                  <el-button round class="primary-search-button mt-12 min-w-64 max-lg:w-full"><a href="/ancestry">{{ $t('www.一键寻根') }}</a></el-button>
                </div>
            </div>
        </div>

        <!--家谱-->
        <div class="bg-[var(--lighter-grey)] w-full">
            <!--     PC端     -->
            <div id="findGenealogy"
                 class="find-genealogy index-content-max-width w-full px-8 lg:px-28 py-20 grid mx-auto max-lg:hidden">
                <div class="find-info py-4 lg:px-14">
                    <div class="title">{{ $t('home.想找到自己的家谱吗') }}<br/>{{ $t('www.输入您的姓氏查询') }}</div>
                    <div class="search-info mt-8">
                      <el-select class="h-full genealogy-select"
                                 v-model="findGenealogySurname" placement="top"
                                 filterable
                                 :placeholder="$t('www.请输入您的姓氏')">
                        <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
                          <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
                        </template>
                      </el-select>
<!--                        <el-input v-model="findGenealogySurname" :placeholder="$t('www.请输入您的姓氏')"/>-->
                    </div>
                    <el-button round class="primary-search-button mt-10 min-w-64" @click="$router.push('/surname?surname-id=' + findGenealogySurname)">{{ $t('www.查询') }}</el-button>
                    <div class="create-genealoly-info mt-10">
                        {{ $t('home.如果您找不到家谱别担心') }}<br/>{{ $t('home.您可以开始创建属于自己的家谱') }}
                    </div>
                    <el-button round class="secondary-search-button mt-8 min-w-64" @click="$router.push('/profile?tab=2')">
                        <span>{{ $t('www.开始创建家谱') }}</span>
                        <el-icon class="ml-2">
                            <Right/>
                        </el-icon>
                    </el-button>
                </div>
                <div ref="findGenealogyMap" class="find-image h-full relative px-24">
                    <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_1.svg">
                    <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_2.svg">
                    <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_3.svg">
                    <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_4.svg">
                    <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_5.svg">
                    <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_6.svg">
                    <img class="w-full h-full object-contain" src="@/assets/images/home/search_genealogy_map.svg">
                </div>
            </div>
          <!--     移动端     -->
          <div id="findGenealogy"
               class="find-genealogy index-content-max-width w-full px-8 lg:px-28 py-20 grid mx-auto lg:hidden">
            <div class="find-info py-4 lg:px-14 max-lg:px-0">
              <div class="title">{{ $t('home.想找到自己的家谱吗') }}<br/>{{ $t('www.输入您的姓氏查询') }}</div>
            </div>
            <div ref="findGenealogyMap" class="find-image h-full relative" style="min-height: unset;">
              <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_1.svg">
              <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_2.svg">
              <img class="map-tag left absolute" src="@/assets/images/home/search_genealogy_map_tag_3.svg">
              <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_4.svg">
              <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_5.svg">
              <img class="map-tag right absolute" src="@/assets/images/home/search_genealogy_map_tag_6.svg">
              <img class="w-full h-full object-contain" src="@/assets/images/home/search_genealogy_map.svg">
            </div>
            <div class="find-info py-4 lg:px-14 max-lg:px-8">
              <div class="search-info mt-8">
                <el-select class="h-full genealogy-select"
                           v-model="findGenealogySurname" placement="top"
                           filterable
                           :placeholder="$t('www.请输入您的姓氏')">
                  <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
                    <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
                  </template>
                </el-select>
              </div>
              <el-button round class="primary-search-button mt-10 w-full" @click="$router.push('/surname?surname-id=' + findGenealogySurname)">{{ $t('www.查询') }}</el-button>
              <div class="create-genealoly-info mt-10 text-xl">
                {{ $t('home.如果您找不到家谱别担心') }}<br/>{{ $t('home.您可以开始创建属于自己的家谱') }}
              </div>
              <el-button round class="secondary-search-button mt-8 w-full" @click="$router.push('/profile?tab=2')">
                <span class="text-xl">{{ $t('www.开始创建家谱') }}</span>
                <el-icon class="ml-2">
                  <Right/>
                </el-icon>
              </el-button>
            </div>
          </div>
        </div>


        <!--申请寻根 + 姓氏-->
        <div class="w-full">
            <div class="apply-search-root bg-[var(--dark-blue)]  index-content-max-width lg:py-20 lg:px-24 mx-auto max-lg:pb-24 max-lg:pt-12">
                <!--申请寻根-->
                <div class="apply-search flex gap-x-24 max-lg:pb-12">
                  <!--图片-->
                    <div ref="applySearchMap" class="apply-search-map-box lg:w-3/5 w-full relative overflow-hidden">
                        <img class="apply-search-rotate w-auto h-full object-contain"
                             src="@/assets/images/home/apply_search_rotate.svg">
                        <img class="apply-search-map w-full absolute object-contain"
                             src="@/assets/images/home/apply_search_map.svg">
                        <img ref="applySearchItem1" class="apply-search-item1 absolute"
                             src="@/assets/images/home/apply_search_item1.png">
                        <img ref="applySearchItem2" class="apply-search-item2 absolute"
                             src="@/assets/images/home/apply_search_item2.png">
                        <img ref="applySearchItem3" class="apply-search-item3 absolute"
                             src="@/assets/images/home/apply_search_item3.png">
                        <img class="apply-search-locate absolute" src="@/assets/images/home/apply_search_locate.svg">
                    </div>

                  <!--文案-->
                    <div class="apply-search-right lg:w-2/5 w-full p-12 max-lg:px-16">
                        <div class="title">{{ $t('home.故土路遥') }}<br/>{{ $t('home.让我们帮您找回血脉亲情') }}</div>
                        <div class="info mt-2">{{
                            $t('home.研究员根据您提供的各种线索')
                            }}
                        </div>
                        <el-button round class="primary-search-button mt-12 min-w-64 max-lg:w-full" @click="rootApplicationDialogVisible = true;">{{ $t('www.申请寻根') }}</el-button>
                    </div>
                </div>

                <!--姓氏-->
                <div class="surname w-full mt-4 px-24 sm:px-40 lg:px-72 py-36 relative max-lg:hidden">
                    <div class="title text-center">{{
                        $t('home.传承千年的家族印记细品姓氏背后的千古风华')
                        }}<br/>{{ $t('home.启程探索') }}
                    </div>
                    <SurnameFilter class="mt-8" surname-item-bg-color="transparent" surname-list-height="16rem"
                                   @change-chosen-surname="goToSurname"></SurnameFilter>
                  <div class="surname-more-button w-full text-center absolute left-0 -bottom-6 lg:bottom-0">
                    <el-button round class="mx-auto min-w-64 h-12" @click="$router.push('/surname')"><a href="/surname">{{ $t('了解更多') }}</a></el-button>
                  </div>
                </div>
            </div>

            <!--姓氏-->
            <div class="surname-mobile w-full mt-4 px-8 pt-32 pb-20 relative lg:hidden bg-[#fffef8]">
              <div class="title h-72 absolute -top-40 left-0 flex items-center justify-center w-full px-28 max-sm:px-16 text-4xl">
                  {{$t('home.传承千年的家族印记细品姓氏背后的千古风华') }}{{ $t('home.启程探索') }}
              </div>
              <SurnameFilter class="mt-8" surname-item-bg-color="transparent" surname-list-height="16rem"
                             @change-chosen-surname="goToSurname"></SurnameFilter>
              <el-button round class="primary-search-button mx-auto w-full h-12 mt-12" @click="$router.push('/surname')"><a href="/surname">{{ $t('了解更多') }}</a></el-button>

            </div>
        </div>



      <el-dialog v-model="rootApplicationDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
        <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
        <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
        <el-form ref="fullClueFormRef" :model="fullClueForm" class="clue-form mt-8" label-position="top" :rules="fullRules">
          <el-form-item :label="$t('姓氏')" prop="surname">
            <el-input :class="{active:fullClueForm.surname}" clearable :placeholder="$t('user.请输入姓氏')" v-model="fullClueForm.surname"></el-input>
          </el-form-item>
          <el-form-item :label="$t('root.联系方式')" prop="mobile">
            <el-input :class="{active:fullClueForm.mobile}" clearable :placeholder="$t('root.请输入联系方式')" v-model="fullClueForm.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('root.留言')" prop="describe">
            <el-input :class="{active:fullClueForm.describe}" rows="5" type="textarea" clearable :placeholder="$t('root.有什么想说的都可以留言给我们')" v-model="fullClueForm.describe"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer w-full flex justify-between">
            <el-button round @click="rootApplicationDialogVisible = false" class="bg-[var(--middle-grey)]">{{ $t('取消') }}</el-button>
            <el-button round type="primary" @click="rootsApply">
              {{ $t('一键提交') }}
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog v-model="rootApplySuccessDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
        <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
        <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
        <div class="flex flex-col justify-center items-center">
          <img class="w-32 mt-12 translate-x-4" src="@/assets/images/ancestry/root_apply_success.svg">
          <div class="text-xl text-[var(--dark-blue)]">{{$t('root.资料提交成功')}}</div>
          <div class="text-base text-[var(--dark-grey)] mt-2">{{$t('root.我们的工作人员将与您联系')}}</div>
        </div>
        <template #footer>
          <div class="dialog-footer w-full flex flex-col justify-center items-center mt-12">
            <el-button round type="primary"  @click="rootApplySuccessDialogVisible = false" >
              {{ $t('退出') }}
            </el-button>
            <el-button round @click="rootApplySuccessDialogVisible = false" class="bg-[var(--middle-grey)] mt-4" style="margin-left: 0;">{{ $t('返回') }}</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {useTransition} from '@vueuse/core'
import CommonSearch from "@/components/CommonSearch";
import SurnameFilter from "@/components/SurnameFilter";
import {rootsApply} from "@/api/ancestry";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import util from "@/utils/util";
import {getPubSurname} from "@/api/common";

export default {
    name: "Index",
    components: {SurnameFilter, CommonSearch},
    setup() {

        // 家谱中找寻血脉
        const searchValueSource = ref(0)
        const searchValue = useTransition(searchValueSource, {
            duration: 2000,
        })
        searchValueSource.value = 460;

        // 发现家庭成员📖
        const searchPercentSource = ref(0)
        const searchPercent = useTransition(searchPercentSource, {
            duration: 2000,
        })
        searchPercentSource.value = 85

        // 寻根部分当前活跃视频
        const activeIndex = ref(0);

        // 查找家谱输入的姓氏
        const findGenealogySurname = ref('');


      // 寻根申请
      const rootApplicationDialogVisible = ref(false);
      // 寻根申请成功
      const rootApplySuccessDialogVisible = ref(false);

      // 寻根需求
      const fullClueForm = reactive({});

      const surnameOptions = ref([]);

        return {
            // 谱牒部分走马灯图片
            carouselList: [{
                id: 1,
                src: require('@/assets/images/home/genealogy_carousel_1.jpg')
            }, {
                id: 2,
                src: require('@/assets/images/home/genealogy_carousel_1.jpg')
            }, {
                id: 3,
                src: require('@/assets/images/home/genealogy_carousel_1.jpg')
            }],
            // 谱牒检索姓氏
            genealogySearchSurname: '',
            // 寻根部分图片
            seaechRootVideos: [
                {
                    src: process.env.VUE_APP_MEDIA_URL + '/search_root_video1.mp4',
                    poster: require('@/assets/images/home/search_root_video1_cover.jpg')
                },
                {
                    src: process.env.VUE_APP_MEDIA_URL + '/search_root_video2.mp4',
                    poster: require('@/assets/images/home/search_root_video2_cover.jpg')
                },
                {
                    src: process.env.VUE_APP_MEDIA_URL + '/search_root_video3.mp4',
                    poster: require('@/assets/images/home/search_root_video3_cover.jpg')
                },
                {
                    src: process.env.VUE_APP_MEDIA_URL + '/search_root_video4.mp4',
                    poster: require('@/assets/images/home/search_root_video4_cover.jpg')
                }
            ],
            // 查找家谱输入的姓氏
            findGenealogySurname,
            searchValue,
            searchPercent,
            // 寻根部分当前活跃视频
            activeIndex,
          fullClueForm,
          rootApplicationDialogVisible,
          rootApplySuccessDialogVisible,
          surnameOptions
        }
    },
    computed: {
        currentLang() {
            return this.$i18n.locale;
        }
    },
    mounted() {

      this.getRemoteSurname();

        // 创建 Intersection Observer 实例
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // 如果元素进入了视口
                if (entry.isIntersecting) {
                    // 添加动画
                    // 查询家谱模块地图标签增加动画
                    if (entry.target.classList.contains('find-image')) {
                        let leftMapTags = document.querySelectorAll('.map-tag.left');
                        [...leftMapTags].forEach(item => item.classList.add('left-to-right-animation'));
                        let rightMapTags = document.querySelectorAll('.map-tag.right');
                        [...rightMapTags].forEach(item => item.classList.add('right-to-left-animation'));
                    } else { // 申请寻根模块图片增加动画
                        this.$refs.applySearchItem1.classList.add('apply-search-item1-animation');
                        this.$refs.applySearchItem2.classList.add('apply-search-item2-animation');
                        this.$refs.applySearchItem3.classList.add('apply-search-item3-animation');
                    }

                    // 停止监听
                    observer.unobserve(entry.target);
                }
            });
        });

        // 监听元素
        observer.observe(this.$refs.findGenealogyMap, {threshold: [0.5]});
        observer.observe(this.$refs.applySearchMap, {threshold: [0.7]});
    },
    methods: {
        /**
         * 更改视频播放
         * @param videoIndex
         */
        changeVideoPlay(videoIndex) {
            this.activeIndex = videoIndex;

            // 停放不活跃的视频，播放当前活跃视频
            this.seaechRootVideos.forEach((item, index) => {
                let video = document.getElementById('searchRootVideo' + index);
                if (index == videoIndex) {
                    video.play();
                } else {
                    video.pause();
                }
            });
        },
        /**
         * 跳转至寻根谒祖页面
         */
        goAncestry(surnameInfo) {
          if (surnameInfo && surnameInfo.surname) {
            this.$router.push('/genealogy?surname_id=' + surnameInfo.id + '&surname=' + surnameInfo.surname);
          } else {
            this.$router.push('/genealogy');
          }
        },
        /**
         * 跳转至姓氏宗亲页
         * @param surnameId
         */
        goToSurname(letter, surnameId) {
            this.$router.push('/surname?letter=' + letter + '&surname-id=' + surnameId);
        },
      /**
       * 寻根需求
       */
      rootsApply() {
        this.$refs.fullClueFormRef.validate((valid, fields) => {
          if (valid) {
            rootsApply(this.fullClueForm).then(res => {
              if (res.code == SUCCESS_CODE && res.msg) {
                this.rootApplicationDialogVisible = false;
                this.rootApplySuccessDialogVisible = true;
              } else {
                util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
              }
            }).catch(error => {
              util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('寻根申请失败'));
            })
          }
        });
      },
      /**
       * 获取姓氏筛选列表
       */
      getRemoteSurname() {
        getPubSurname().then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.surnameOptions = res.data;
          } else {
            this.surnameOptions = [];
          }
        }).catch(error => {
          this.surnameOptions = [];
        })
      },
    }
}
</script>

<style scoped lang="scss">
.index_content {

  .genealogy {
    grid-template-columns: repeat(var(--index-module-columns), 1fr);

    .genealogy-left {
      padding: calc(4.5rem + var(--header-height)) 4rem 1rem 1rem;

      @media screen and (max-width: 959.98px) {
        & {
          padding: 4rem 2rem 2rem 2rem;
        }
      }

      .title {
        font-size: var(--index-top-title-font-size);
        line-height: 1.2;
        font-weight: bold;
      }

      .detail {
        width: 100%;
        font-size: 1.2rem;
      }

      .hot-genealogy {

        .hot-genealogy-tip {
          width: 2.5rem;
          height: 2.5rem;
        }

        .hot-genealogy-info {

          &>div>div {
            cursor: pointer;
            color: var(--grey-1);
            font-size: 1rem;
            max-width: 50%;
            text-overflow: ellipsis; /* 显示省略号 */
            white-space: nowrap;
          }
        }
      }

      .number-info {
        ::v-deep .el-statistic__content {
          font-size: 3rem;
          line-height: 1.2;
          font-weight: 900;
          text-align: left;

          @media screen and (max-width: 959.98px) {
            & {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 3rem;
            }
          }
          @media screen and (max-width: 479.98px) {
            & {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 2.5rem;
            }
          }
          .el-statistic__suffix {
            margin-left: 0.5rem;
          }
        }
        .info{
          font-size: 1.2rem;
          @media screen and (max-width: 479.98px) {
            & {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .genealogy-right {
      min-height: 60rem;
      order: -1;

      @media screen and (max-width: 959.98px) {
        & {
          min-height: 40rem;
        }
      }

      ::v-deep .carousel {
        border-radius: 2rem;

        .el-carousel__container {
          width: 100%;
          height: 100%;

          .el-carousel__item img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .el-carousel__arrow {
          background-color: var(--light-orange);
          opacity: 0.7;
          z-index:4;

          &:hover {
            opacity: 0.8;
          }

          .el-icon svg {
            width: 1rem;
            height: 1rem;
          }

          &.el-carousel__arrow--left {
            position: absolute;
            right: 5rem;
            bottom: 0;
            left: unset;
            top: unset;
          }

          &.el-carousel__arrow--right {
            position: absolute;
            right: 2rem;
            bottom: 0;
            left: unset;
            top: unset;
          }
        }
      }
    }
  }

  .search-root {
    flex-direction: var(--index-flex-direction);

    .image-gallary {
      min-height: 30rem;

      .video-item {
        width: 7rem;
        transition: flex-grow 0.3s ease-in-out; /* 添加过渡效果 */
      }

      .video-item video {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: cover;
        border-radius: 3.5rem;
      }

      .video-item.active {
        flex-grow: 1;
      }

      .video-item:not(.active) {
        flex-grow: 0;
      }
    }

    ::v-deep .el-carousel {

      .el-carousel__container {
        width: 100%;
        height: 50vw;

        .el-carousel__item {
          height: auto;
        }
      }
    }

    .search-root-right {
      width: var(--index-search-root-right-width);
      .title {
        font-size: var(--common-title-font-size);
        color: var(--light-orange);
        @media screen and (max-width: 479.98px) {
          & {
            font-size: 2.2rem;
          }
        }
      }

      .sub-title {
        color: white;
        font-size: 1.4rem;
      }

      .info {
        font-size: 1rem;
        color: var(--middle-grey);
        @media screen and (max-width: 959.98px) {
          & {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .find-genealogy {
    overflow: hidden;
    grid-template-columns: repeat(var(--index-module-columns), 1fr);

    .find-info {
      height: 35rem;
      @media screen and (max-width: 959.98px) {
        & {
          height: auto;
        }
      }

      .title {
        font-size: var(--common-title-font-size);
        color: var(--dark-blue);
        @media screen and (max-width: 479.98px) {
          & {
            font-size: 2.2rem;
          }
        }

      }

      .search-info {
        :deep(.genealogy-select) {
          width: 70%;

          @media screen and (max-width: 959.98px) {
            & {
              width: 100%;
              font-size: 1.5rem;
            }
          }

          .el-select__wrapper {
            background-color: unset;
            box-shadow: unset;
            border-bottom: 0.06rem solid #dcdfe6;

            @media screen and (max-width: 959.98px) {
              & {
                font-size: 1.5rem;
              }
            }

            .el-select__suffix {
              display: none;
            }
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 0.2rem;
            height: 1rem;
            background-color: var(--light-grey);
            transform: translateY(-52%);

            @media screen and (max-width: 959.98px) {
              & {
                height: 1.5rem;
              }
            }
          }
        }
      }
    }

    .find-image {
      min-height: 35rem;

      .map-tag {
        height: 7%;

        &:nth-child(1) {
          right: 50%;
          top: 23%;
        }

        &:nth-child(2) {
          right: 60%;
          top: 52%;
        }

        &:nth-child(3) {
          right: 44%;
          top: 72%;
        }

        &:nth-child(4) {
          left: 62%;
          top: 18%;
        }

        &:nth-child(5) {
          left: 60%;
          top: 40%;
        }

        &:nth-child(6) {
          left: 62%;
          top: 62%;
        }
      }

      .left-to-right-animation {
        animation: left-to-right 2s forwards;
      }

      .right-to-left-animation {
        animation: right-to-left 2s forwards;
      }

      @keyframes left-to-right {
        0% {
          transform: translateX(-50%);
        }
        100% {
          transform: translateX(0);
        }
      }

      @keyframes right-to-left {
        0% {
          transform: translateX(50%);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }

  .apply-search-root {
    background-color: var(--dark-blue);

    .apply-search {
      flex-direction: var(--index-flex-direction);

      &>div {
        height: 36rem;
      }

      .apply-search-rotate {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1);
        bottom: -16%;
        animation: rotate 5s linear infinite;
      }

      @keyframes rotate {
        0% {
          transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1) rotateZ(0deg);
        }
        25% {
          transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1) rotateZ(90deg);
        }
        50% {
          transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1) rotateZ(180deg);
        }
        75% {
          transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1) rotateZ(270deg);
        }
        100% {
          transform: translateX(-50%) rotate3d(1, 0, 0, 50deg) scale(2, 1) rotateZ(360deg);
        }
      }

      .apply-search-map {
        height: auto;
        bottom: 7rem;
      }

      .apply-search-locate {
        width: 7rem;
        height: auto;
        left: 50%;
        bottom: 10rem;
        transform: translateX(-50%);
      }

      .apply-search-item1 {
        width: 22rem;
        height: auto;
        left: 50%;
        bottom: 17rem;
        transform: translate(-50%, 0);
        box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.8);
      }

      .apply-search-item1-animation {
        animation: apply-search-item1-amination 2s forwards;
      }

      @keyframes apply-search-item1-amination {
        0% {
          transform: translate(-50%, -50%);
        }
        100% {
          transform: translate(-50%, 0);
        }
      }

      .apply-search-item2 {
        transform: rotateZ(16deg);
        width: 18rem;
        height: auto;
        right: 6%;
        bottom: 11rem;
        box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.8);
      }

      .apply-search-item2-animation {
        animation: apply-search-item2-amination 2s forwards;
      }

      @keyframes apply-search-item2-amination {
        0% {
          transform: rotateZ(16deg) translateX(50%);
        }
        100% {
          transform: rotateZ(16deg) translateX(0);
        }
      }

      .apply-search-item3 {
        transform: rotateZ(-15deg);
        width: 20rem;
        height: auto;
        left: 4%;
        bottom: 9rem;
        box-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.8);
      }

      .apply-search-item3-animation {
        animation: apply-search-item3-amination 2s forwards;
      }

      @keyframes apply-search-item3-amination {
        0% {
          transform: rotateZ(-15deg) translateX(-50%);
        }
        100% {
          transform: rotateZ(-15deg) translateX(0);
        }
      }

      .apply-search-right {

        height: auto;

        .title {
          font-size: var(--common-title-font-size);
          color: var(--light-orange);
          @media screen and (max-width: 479.98px) {
            & {
              font-size: 2.2rem;
            }
          }
        }

        .info {
          font-size: 1.2rem;
          color: white;
        }
      }
    }

    .surname {
      background: url("../../assets/images/home/scroll.png") no-repeat;
      background-size: 100% 100%;

      @media screen and (max-width: 959.98px) {
        & {
          background: url("../../assets/images/home/scroll_mobile.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .title {
        font-size: var(--common-title-font-size);
        color: var(--dark-blue);
      }

      .surname-more-button {
        ::v-deep .el-button {
          background-color: transparent;
          color: var(--light-orange);
          border: 0.06rem solid var(--light-orange);
        }
      }
    }
  }

  .surname-mobile {

    .title {
      color: var(--dark-blue);
      background: url("../../assets/images/home/scroll.png") no-repeat;
      background-size: 100% 100%;
    }

    .surname-more-button {
      ::v-deep .el-button {
        background-color: transparent;
        color: var(--light-orange);
        border: 0.06rem solid var(--light-orange);
      }
    }
  }
}
</style>
