<template>
  <div class="login-content">
    <!--登录注册标题-->
    <div class="login-register-title flex justify-center">
      <div>
        <el-link class="login" href="/auth/login" :underline="false">{{$t('user.登录')}}</el-link>
      </div>
      <div>
        <el-link class="register" href="/auth/register" :underline="false">{{$t('user.注册')}}</el-link>
      </div>
    </div>

<!--    <div class="login-type flex justify-between mt-8">-->
<!--      <div :class="{active: loginType == 1}" @click="loginType = 1;">{{$t('user.使用账号登录')}}</div>-->
<!--      <div :class="{active: loginType == 2}" @click="loginType = 2;">{{$t('user.使用手机号登录')}}</div>-->
<!--    </div>-->

    <!--使用账号登录内容项-->
    <div v-if="loginType == 1" class="login-input mt-16">

      <el-form ref="loginFormRef"
          :model="loginForm" class="register-form flex flex-wrap" :rules="loginFormRules"
          label-width="auto">
        <el-form-item :label="$t('user.账号')" prop="login_name" class="w-full" :class="{active: loginForm.login_name}">
          <el-input :placeholder="$t('user.账号')" v-model="loginForm.login_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.密码')" prop="passwd" class="w-full" :class="{active: loginForm.passwd}">
          <el-input :placeholder="$t('user.密码')" type="password" v-model="loginForm.passwd"></el-input>
        </el-form-item>
      </el-form>
      <div class="forget-pwd" @click="forgetPwd">{{$t("user.忘记密码")}}？</div>
    </div>

    <!--使用手机号登录内容项-->
    <div v-if="loginType == 2" class="login-input mt-16">

      <el-form ref="mobileLoginFormRef"
          :model="mobileLoginForm" class="register-form flex flex-wrap" :rules="mobileLoginFormRules"
          label-width="auto">
        <el-form-item :label="$t('user.手机号码')" prop="mobile" class="mobile w-full" :class="{active: mobileLoginForm.mobile}">
          <div class="absolute h-10 left-14 top-0 leading-10 text-[var(--dark-grey)]">+{{mobileLoginForm.country}}</div>
          <vue-tel-input class="w-full h-10 mb-2.5"
              v-model="mobileLoginForm.mobile" mode="national" :input-options="{placeholder: $t('user.手机号码')}" :auto-format="false"
              :default-country="defaultCountry" @country-changed="changeCountry" ></vue-tel-input>
        </el-form-item>
        <el-form-item :label="$t('user.短信验证码')" prop="sms_code" class="sms w-full" :class="{active: mobileLoginForm.sms_code}">
          <el-input :placeholder="$t('user.短信验证码')" v-model="mobileLoginForm.sms_code">

            <template #append>
              <el-button @click="getSMS" :disabled="smsCountdown > 0">{{ smsCountdown > 0 ? smsCountdown : $t('user.发送验证码') }}</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <el-button round @click="login">{{$t("user.立即登录")}}</el-button>

    <div class="login-privacy">{{$t("user.登录即代表同意")}}<a href="/user-service-agreement" target="_blank">《{{$t('user.用户服务协议')}}》</a> <a href="/privacy-policy" target="_blank">《{{$t('user.隐私政策')}}》</a></div>
  </div>
</template>

<script>
import {ref, reactive} from 'vue';
import {getLoginSMS, loginPwd, loginSMS} from "@/api/auth";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import util from "@/utils/util";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "Login",
  components: {
    VueTelInput
  },
  setup() {

    const loginType = ref(1);

    const loginForm = reactive({
      login_name: '',
      passwd: '',
    })

    const mobileLoginForm = reactive({
      country: '86',
      mobile: '',
      sms_code: '',
    })

    // 短信倒计时秒数
    const smsCountdown = ref(0);

    const defaultCountry = ref('cn');

    return {
      loginType,
      loginForm,
      mobileLoginForm,
      smsCountdown,
      defaultCountry,
    }
  },
  computed: {
    loginFormRules() {
      return {
        login_name: {
          required: true,
          message: this.$t('user.请输入登录账号'),
          trigger: 'blur'
        },
        passwd: {
          required: true,
          message: this.$t('user.请输入登录密码'),
          trigger: 'blur'
        }
      }
    },
    mobileLoginFormRules() {

      return {
        mobile: [{
          required: true,
          message: this.$t('user.请输入手机号码'),
          trigger: 'blur'
        }],
        sms_code: {
          required: true,
          message: this.$t('user.请输入验证码'),
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    /**
     * 登录
     */
    login() {
      if (this.loginType == 1) {
        this.accountPasswordLogin();
      } else {
        this.mobileLogin();
      }
    },
    /**
     * 账号密码登录
     */
    accountPasswordLogin() {
      this.$refs.loginFormRef.validate((valid, fields) => {
        if (valid) {
          loginPwd(this.loginForm).then(res => {

            if (res.code == SUCCESS_CODE) {
              this.handleSucessfulLogin(res.data);
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
              })
            }
          }).catch(error => {

          })
        }
      });
    },
    /**
     * 手机号验证码登录
     */
    mobileLogin() {
      this.$refs.mobileLoginFormRef.validate((valid, fields) => {
        if (valid) {
          loginSMS(this.mobileLoginForm).then(res => {

            if (res.code == SUCCESS_CODE) {
              this.handleSucessfulLogin(res.data);
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
              })
            }
          }).catch(error => {

          })
        }
      });
    },
    /**
     * 登录成功后处理
     */
    handleSucessfulLogin(data) {
      // 存储uid
      this.$store.dispatch('setUID', data.uid);
      // 存储token
      this.$store.dispatch('login', data.token);
      // 获取用户信息
      this.$store.dispatch('fetchUserInfo').then(userInfoRes => {
        if (userInfoRes.code == SUCCESS_CODE) {
          // 跳转至首页
          this.$router.push('/index');
        } else {
          this.$message({
            message: userInfoRes.msg,
            type: 'error',
          })
        }
      }).catch(error => {

      })
    },
    /**
     * 忘记密码
     */
    forgetPwd() {
      // 跳转至首页
      this.$router.push('/auth/reset-pwd');
    },
    /**
     * 获取验证码
     */
    getSMS() {
      if (!this.mobileLoginForm.mobile) {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('user.请输入手机号码'));
        return;
      }
      getLoginSMS({"mobile": this.mobileLoginForm.mobile, country: this.mobileLoginForm.country}).then(res => {
        if (res.code == SUCCESS_CODE) {
          this.smsCountdown = 60;
          const timer = setInterval(() => {
            this.smsCountdown--;
            if (this.smsCountdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
          // TODO 临时弹窗显示密码
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, '注意', res.msg);
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('获取短信验证码失败'));
      });
    },
    changeCountry(value) {
      this.mobileLoginForm.country = value.dialCode;
    }
  }
}
</script>

<style scoped lang="scss">
.login-content {
  position: relative;
  width: 100%;
  height: 100%;

  .login-register-title {

    &>div {
      padding: 0 1.5rem;
      position: relative;
      cursor: pointer;

      ::v-deep .el-link {
        font-size: 1.8rem;
        font-weight: normal;

        &.login {
          color: var(--dark-blue);
        }

        &.register {
          color: var(--dark-grey);
        }
      }

      &:first-child::after {
        content: '';
        width: 0.06rem;
        height: 1.8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: var(--middle-grey);
      }
    }
  }

  .login-type {
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--dark-grey);

    &>div {
      cursor: pointer;
    }

    &>div.active {
      color: var(--dark-blue);
      border-bottom: 0.06rem solid var(--dark-blue);
    }
  }

  .login-input {

    ::v-deep .register-form {

      .el-form-item__error {
        font-size: 1rem;
        right: 0.25rem;
        padding-top: 0.125rem;
        bottom: 0.7rem;
        left: unset;
        top: unset;
      }

      .el-form-item.sms .el-form-item__error {
        right: 7.65rem;
      }

      .el-form-item {
        position: relative;
        margin-bottom: 0;

        .el-form-item__label-wrap {
          position: absolute;
          top: 0;
          left: 1rem;
          z-index: 1;
          display: none;
          transform: translateY(-50%);
          background-image: linear-gradient(to bottom, white 0%, white 51%, var(--blue-2) 52%, var(--blue-2) 100%);
          height: 0.875rem;
          line-height: 1;

          .el-form-item__label {
            color: var(--middle-grey);
            height: 0.875rem;
            font-size: 0.875rem;
            line-height: 1;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
          }
        }

        &.active {

          .el-form-item__label-wrap {
            display: block;
            animation: from-bottom-up 0.5s forwards;
          }

          @keyframes from-bottom-up {
            0% {
              transform: translateY(0) scale(1.2);
            }
            100% {
              transform: translateY(-50%) scale(1);
            }
          }

          .el-input__wrapper {
            background-color: var(--blue-2);
          }

          .vue-tel-input {
            background-color: var(--blue-2);

            .vti__input {
              background-color: var(--blue-2);
            }

          }
        }
      }

      .el-form-item.sms .el-form-item__error {
        right: 7.65rem;
      }

      .el-input-group__append {
        padding: 0;
        background-color: var(--light-grey);
        width: 7.4rem;

        .el-button {
          width: 100%;
          height: 100%;
          background-color: transparent;
          color: var(--dark-grey);
          border-radius: 0;
          margin-top: 0;
          padding: 0;
          line-height: 100%;
        }
      }

      .vue-tel-input {
        border: 0.06rem solid var(--el-border-color);

        .vti__dropdown {
          padding: 0.5rem;

          .vti__selection {

            .vti__flag {
              width: 1.25rem;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }

        }

        .vti__dropdown-list {
          width: 24rem;
        }

        .vti__input {
          padding-left: 2.5rem;
        }
      }

      .vue-tel-input:focus-within {
        box-shadow: unset;
        border-color: var(--light-orange);
      }
    }

    ::v-deep .el-input {
      height: 2.5rem;
      margin-bottom: 0.6rem;
      font-size: 1.3rem;

      .el-input__wrapper {
        padding: 0 1rem;
      }
    }

    .forget-pwd {
      font-size: 1rem;
      color: var(--dark-blue);
      text-align: end;
      margin-top: 0.4rem;
      cursor: pointer;
    }
  }

  ::v-deep .el-button {
    width: 100%;
    height: 3.4rem;
    margin-top: 3.6rem;
    background-color: var(--dark-blue);
    color: var(--light-orange);
    border-radius: 9999px;
    line-height: 3.4rem;
  }

  .login-privacy {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--dark-grey);
    font-size: 0.9rem;
    white-space: nowrap;

    a {
      color: var(--dark-blue);
      text-decoration: underline;
    }
  }
}

</style>
