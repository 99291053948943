<template>
  <div class="protocol-content w-full px-4">
    <div class="common-content-width mx-auto rounded-3xl bg-white px-12 py-16">
      <div class="title">用户服务协议</div>
      <el-divider class="mb-8 mt-4"/>
      <div class="text-info">
        <p>使用本网站前、请您仔细阅读下述条款。如您不同意本服务条款或中国治金地质总局网站对其进行的修改、您可以主动取消中国治金地质总局网站提供的服务;您一旦使用中国冶金地质总局网站提供的服务，即视为您已了解并完全同意本服务条款各项内容，包括中国冶金地质总局网站对服务条款所做的任何修改，并成为中国治金地质总局网站的用户(以下简称“用户”)</p>
        <p>1、版权归属</p>
        <p>中国冶金地质总局独立拥有中国治金地质总局网站相关网页内所有资料的版权，或与中国冶金地质总局网站相关网页内的资料提供者共同拥有该网页内资料的版权。未经中国治金地质总局的明确书面许可、对于中国治金地质总局网站上的任何内容，任何人不得对其进行复制或在非中国冶金地质总局所属的服务器上做镜像,。</p>
        <p>2、免责声明</p>
        <p>(1)本网站所发布的信息中可能没有包含与中国治金地质总局及其业务有关的最新信息，对于本网站所发布的信息，本公司不声明或保证其内容之正确性或完整性;对于用户通过本网站有关服务之广告、信息或要约而购买或取得之任何产品、信息或服务，本公司亦不承担质量保证之责任。</p>
        <p>(2)中国冶金地质总局对本网站提供的服务不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的之适用性及未侵害他人权利等担保或保证。</p>
        <p>(3)用户明确了解并同意，基于以下原因而造成的，包括但不限于利润、信誉、应用、数据损失或其他无形损失，中国治金地质总局不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任(即使中国冶金地质总局事先已被告知发生此种赔偿之可能性亦然):</p>
        <p>本网站提供服务之使用或无法使用;</p>
        <p>I为替换通过本网站服务购买或取得之任何商品、数据、信息、服务，或缔结的交易而发生的成本;</p>
        <p>川 您的传输或数据遭到未获授权的存取或篡改;</p>
        <p>IV 任何第三方在本服务中所作之声明或行为;</p>
        <p>V 与本服务相关的其他事宜，但本服务条款有明确规定的除外。</p>
        <p>(4)本网站转载的相关文章、资料中表述或暗示的观点不代表中国冶金地质总局的观点。</p>
        <p>(5)本网站所提供的信息，若在某地区使用或分发时会违反该地区的法律或规定，则不得在该地区使用或分发本网站信息。(6)本网站遵循互利互惠的原则进行内容合作。所转载的信息都已经过版权所有者的口头或书面的许可，如有遗漏，请与我们联系并提供相应的证明资料。3、隐私权保护</p>
        <p>(1)用户在匿名的状态下即可访问我们的网站并获取信息，在本网站请求用户提供有关信息之前，本网站会解释这些信息的用途，有些服务需要用户注册后才能够提供。在通常情况下，这类注册只要求用户提供一个电子邮件地址和一些诸如用户的工作，职务一类的基本信息，有时本网站也会请用户提供更多的信息，这样做是为了使本网站更好的理解用户的要求，以便向用户提供更为有效的服务。本网站收集的信息包括姓名，地址和移动电话号码等，用户有权随时决定不接受来自我们的任何资料。</p>
        <p>(2)用户的注册义务。用户如希望使用本网站黑注册后才能够提供的服务，应同意以下事项,依本服务注册表之提示提供您本人真实、正确、最新及完整的资料，并随时更新登记资料、确保其为真实、正确、最新及完整的资料。若您提供任何错误、不实、过时或不完整或具误导性的资料，或者本网站有理由怀疑前述资料为错误、不实、过时或不完整或具误导性的，本网站有权暂停或终止您的帐号，并拒绝您于现在和未来使用本网站所提供服务之全部或任何部分。</p>
        <p>(3)本网站将采取合理的安全手段保护用户已存储的个人信息，除非根据法律或政府的强制性规定，在未得到用户许可之前，本网站不会将用户的任何个人信息提供给任何无关的第三方(包括公司或个人)。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "UserServiceAgreement"
}
</script>

<style scoped lang="scss">
.protocol-content {
  padding-top: calc(var(--header-height) + 3rem);
  padding-bottom: 4rem;

  &>div{
    box-shadow: 0 0.125rem 0.4rem 0.4rem rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 2rem;
    text-align: center;
  }

  .text-info {

    p {
      margin-bottom: 1rem;
      color: var(--dark-blue);
    }
  }
}
</style>