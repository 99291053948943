<template>
  <div>
    <!--顶部-->
    <Header class="header"></Header>
    <MobileHeader class="mobile-header"></MobileHeader>

    <!--  嵌入路由页面  -->
    <div class="router_content" :style="{backgroundColor: currentRoute.meta.backgroundColor}">
      <router-view/>
    </div>

    <!--底部-->
    <Footer v-if="hiddenFooter"></Footer>

    <div class="search-root-guide fixed right-0 text-[var(--light-orange)] px-2 text-center cursor-pointer" @click="goToSearchRootModule">
      {{ $t('人工寻根') }}
    </div>

    <!--回到顶部-->
    <el-backtop :bottom="100" :right="0">
      <img src="@/assets/images/common/back_top.svg">
    </el-backtop>

    <el-dialog v-model="rootApplicationDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
      <el-form ref="fullClueFormRef" :model="fullClueForm" class="clue-form mt-8" label-position="top" :rules="fullRules">
        <el-form-item :label="$t('姓氏')" prop="surname">
          <el-input :class="{active:fullClueForm.surname}" clearable :placeholder="$t('user.请输入姓氏')" v-model="fullClueForm.surname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.联系方式')" prop="mobile">
          <el-input :class="{active:fullClueForm.mobile}" clearable :placeholder="$t('root.请输入联系方式')" v-model="fullClueForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.留言')" prop="describe">
          <el-input :class="{active:fullClueForm.describe}" rows="5" type="textarea" clearable :placeholder="$t('root.有什么想说的都可以留言给我们')" v-model="fullClueForm.describe"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button round @click="rootApplicationDialogVisible = false" class="bg-[var(--middle-grey)]">{{ $t('取消') }}</el-button>
          <el-button round type="primary" @click="rootsApply">
            {{ $t('一键提交') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="rootApplySuccessDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
      <div class="flex flex-col justify-center items-center">
        <img class="w-32 mt-12 translate-x-4" src="@/assets/images/ancestry/root_apply_success.svg">
        <div class="text-xl text-[var(--dark-blue)]">{{$t('root.资料提交成功')}}</div>
        <div class="text-base text-[var(--dark-grey)] mt-2">{{$t('root.我们的工作人员将与您联系')}}</div>
      </div>
      <template #footer>
        <div class="dialog-footer w-full flex flex-col justify-center items-center mt-12">
          <el-button round type="primary"  @click="rootApplySuccessDialogVisible = false" >
            {{ $t('退出') }}
          </el-button>
          <el-button round @click="rootApplySuccessDialogVisible = false" class="bg-[var(--middle-grey)] mt-4" style="margin-left: 0;">{{ $t('返回') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref, reactive} from 'vue';
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {useRoute} from "vue-router";
import MobileHeader from "@/components/MoblileHeader";
import {rootsApply} from "@/api/ancestry";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import util from "@/utils/util";

export default {
  name: "Layout",
  components: {MobileHeader, Footer, Header},
  setup() {
    // 寻根申请
    const rootApplicationDialogVisible = ref(false);
    // 寻根申请成功
    const rootApplySuccessDialogVisible = ref(false);

    // 寻根需求
    const fullClueForm = reactive({});

    return {
      fullClueForm,
      rootApplicationDialogVisible,
      rootApplySuccessDialogVisible,
    }
  },
  computed: {
    currentRoute() {
      let route = useRoute();
      return route;
    },
    // 隐藏底部
    hiddenFooter() {
      return this.currentRoute.path !== '/genealogy' && this.currentRoute.path !== '/family-tree'
          && !this.currentRoute.path.startsWith('/auth') && this.currentRoute.path !== '/ancestry' && this.currentRoute.path !== '/surname';
    }
  },
  methods: {
    /**
     * 寻根需求
     */
    rootsApply() {
      this.$refs.fullClueFormRef.validate((valid, fields) => {
        if (valid) {
          rootsApply(this.fullClueForm).then(res => {
            if (res.code == SUCCESS_CODE && res.msg) {
              this.rootApplicationDialogVisible = false;
              this.rootApplySuccessDialogVisible = true;
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('寻根申请失败'));
          })
        }
      });
    },
    goToSearchRootModule() {
      if (this.currentRoute.path === '/ancestry-detail') {
        // 获取整个文档的高度
        let ancestrySearchRoot = document.getElementById('ancestry-search-root');
        // 滚动到页面底部
        window.scrollTo(0, ancestrySearchRoot.offsetTop + 200);
      } else {
        this.rootApplicationDialogVisible = true;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  position: absolute;
  z-index: 1;
  display: none;
}

.mobile-header {
  position: absolute;
  z-index: 1;
  display: block;
}

.router_content {
  min-height: calc(100vh - 5rem);
  position: relative;
}

.search-root-guide {
  background-image: url("../assets/images/common/search_root.svg");
  background-repeat: no-repeat;
  background-size: 40px 100px;
  bottom: 140px;
  width: 40px;
  height: 96px;
  font-size: 12px;
  padding-top: 50px;
}
</style>