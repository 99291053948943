<template>
  <div class="genealogy-list-content pb-12 ">
    <div class="genealogy-list-top-banner pb-16">
      <div class="search-content max-w-full mx-auto rounded-xl p-4">
        <el-form ref="genealogySearchFormRef" :model="genealogySearchForm" class="advanced-search-form" label-position="top" :rules="genealogySearchFormRules" :inline="true">
          <el-form-item>
            <el-autocomplete :class="{active: genealogySearchForm.surname}" :placeholder="$t('请输入姓氏')"
                v-model="genealogySearchForm.surname" @select="selectSearchValue" @change="changeSearchValue"
                :fetch-suggestions="surnameSearch" value-key="surname"
                :trigger-on-focus="false"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item prop="province_id">
            <el-select :class="{active:genealogySearchForm.province_id}" v-model="genealogySearchForm.province_id"
                       :placeholder="$t('请选择族谱所在省份')" @change="changeProvince">
              <template v-for="province in provinceOptions" :key="province.id">
                <el-option :label="province.aname" :value="province.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input :class="{active:genealogySearchForm.book_name}" v-model="genealogySearchForm.book_name" :placeholder="$t('请输入族谱名')"/>
          </el-form-item>
          <el-button class="primary-search-button w-48" @click="getGenealogyList">{{ $t('立即搜索') }}</el-button>
        </el-form>
      </div>
    </div>

    <div v-loading="resultLoading" class="genealogy-result mt-4 mx-auto flex gap-x-4 px-4 lg:px-0">
      <div class="menu w-80 flex-none rounded-2xl py-12 pl-4 pr-8">
        <div class="text-lg">{{$t('显示搜索结果:')}}&nbsp;<span class="text-[var(--light-orange)]">{{total_count}}</span>{{$t('个结果')}}</div>
        <el-divider class="mt-2 mb-5"/>
        <div class="text-lg text-[var(--dark-blue)]">{{$t('谱籍地')}}</div>
        <el-collapse v-model="location" :accordion="true" @change="collapseChange">
          <template v-for="provinceItem in provinceList" :key="provinceItem.title">
            <el-collapse-item :name="provinceItem.title">
              <template #title>
                <div class="pl-5">
                  <span>{{ provinceItem.title }}({{ provinceItem.num }})</span>
                </div>
              </template>
              <div class="px-8 text-base" v-for="cityItem in provinceItem.children" :key="cityItem.city">
                <div class="common-ellipsis">{{ cityItem.title }}({{ cityItem.num }})</div>
              </div>
            </el-collapse-item>
          </template>
        </el-collapse>
        <el-divider class="mt-4 mb-5"/>
      </div>

      <!--家谱列表-->
      <div class="menu-content rounded-2xl flex-1 py-12 px-8">
        <div class="flex items-center">
          <div class="">{{ $t('所有筛选') }}&nbsp></div>
          <div v-if="confirmedSearchParams.surname" class="bg-[var(--light-orange)] text-white px-2 rounded flex items-center gap-x-2 ml-2">
            <span>{{$t('姓氏')}}：{{confirmedSearchParams.surname}}</span>
            <el-icon class="cursor-pointer" @click="clearChoice('surname')"><CircleClose /></el-icon>
          </div>
          <div v-if="confirmedSearchParams.province" class="bg-[var(--light-orange)] text-white px-2 rounded flex items-center gap-x-2 ml-2">
            <span>{{$t('谱籍地')}}：{{confirmedSearchParams.province}}</span>
            <el-icon class="cursor-pointer" @click="clearChoice('province')"><CircleClose /></el-icon>
          </div>
        </div>
        <el-table class="table mt-6" :data="tableData" :header-cell-style="{background: 'var(--grey-2)', color: 'black'}">
          <el-table-column :label="$t('谱名')" prop="book_name" align="left" width="200"/>
          <el-table-column :label="$t('姓氏')" prop="surname" align="left" width="100"/>
          <el-table-column :label="$t('谱基地')" align="left" width="140">
            <template #default="scope">
              <div>{{scope.row.province + scope.row.city}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('修撰者')" align="left">
            <template #default="scope">
              <div class="truncate-3-lines" :title="scope.row.author">{{scope.row.author}}</div>
            </template>
          </el-table-column>
        </el-table>
        <pagination class="w-full mt-4" :page-num="this.genealogySearchForm.page" :page-size="20" :total-count="total_count"
                    @go-one="goOne" @go-pre="goPre" @go-next="goNext"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, reactive} from 'vue'
import {getPubCity, getPubSurname} from "@/api/common";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import {getCityBooks, getGenealogyBook} from "@/api/genealogy";
import util from '@/utils/util';
import Pagination from "@/components/Pagination";

export default {
  name: "GenealogyList",
  components: {Pagination},
  setup() {
    // 姓氏选择列表
    const surnameOptions = ref([]);

    // 省市数据
    const provinceOptions = reactive([]);
    // 高级检索表单
    const genealogySearchForm = ref({
      surname_id: null,
      surname: null,
      page: 1,
    });

    // 结果数据
    const confirmedSearchParams = ref({});
    const tableData = reactive([]);
    const total_count = ref(0);
    const total_page = ref(0);

    // loading
    const resultLoading = ref(false);

    // 地点
    const provinceList = ref([]);

    return {
      surnameOptions,
      genealogySearchForm,
      provinceOptions,
      confirmedSearchParams,
      tableData,
      total_count,
      total_page,
      resultLoading,
      provinceList
    }
  },
  computed: {
    genealogySearchFormRules() {
      return {
        province_id: [
          {required: true, message: this.$t('请选择省份'), trigger: "blur"}
        ]
      };
    },
  },
  async mounted() {
    this.genealogySearchForm.surname_id = this.$route.query['surname_id'];
    this.genealogySearchForm.province_id = this.$route.query['province_id'];
    try {
      // 获取姓氏筛选列表、获取省份列表
      await Promise.all([this.getRemoteSurname(), this.getProvinceOptions()]);
      this.matchSurnameAndProvince();
      this.getGenealogyList();
    } catch (error) {
      util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('加载失败'));
    }
  },
  methods: {
    /**
     * 获取姓氏筛选列表
     */
    getRemoteSurname() {
      return new Promise((resolve, reject) => {
        getPubSurname().then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.surnameOptions = res.data;
            resolve();
          } else {
            this.surnameOptions = [];
          }
        }).catch(error => {
          this.surnameOptions = [];
        })
      })
    },
    /**
     * 获取省份可选列表
     */
    getProvinceOptions() {
      return new Promise((resolve, reject) => {
        getPubCity({parent_id: 1}).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            Object.assign(this.provinceOptions, res.data);
            resolve()
          } else {
            this.provinceOptions = [];
          }
        }).catch(error => {
          this.provinceOptions = [];
        })
      })
    },
    /**
     * 姓氏筛选
     * @param queryString
     * @returns {*}
     */
    surnameSearch(queryString) {
      return this.surnameOptions.filter(item => item.alias.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
    },
    getGenealogyList() {
      let params = {
        surname: this.genealogySearchForm.surname || '',
        province: this.genealogySearchForm.province || '',
        book_name: this.genealogySearchForm.book_name || '',
        page: this.genealogySearchForm.page,
      }
      this.resultLoading = true;
      Object.assign(this.confirmedSearchParams,this.genealogySearchForm);
      this.$router.push({
        path: this.$route.path, // 保持当前路径不变
        query: {'surname_id': this.genealogySearchForm.surname_id, 'province_id': this.genealogySearchForm.province_id} // 更新路由参数
      });
      getGenealogyBook(params).then(res => {
        this.resultLoading = false;
        if (res.code == SUCCESS_CODE && res.data) {
          this.tableData = res.data.list;
          this.total_page = res.data.total_page;
          this.total_count = res.data.total_count;
          if (!this.genealogySearchForm.province) {
            this.provinceList = res.data.location;
          } else {
            this.provinceList.some(item => {
              if (item.title == this.genealogySearchForm.province) {
                item.children = res.data.location;
                return true;
              }
              return false;
            });
          }
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        this.resultLoading = false;
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('获取家谱列表失败'));
      })
    },
    /**
     * 跳转至第一页
     */
    goOne(){
      if (this.genealogySearchForm.page == 1) {
        this.$message({
          message: this.$t('www.已经在第一页了'),
          type: 'info'
        });
        return;
      }
      this.genealogySearchForm.page = 1;
      this.getGenealogyList();
    },
    /**
     * 跳转至前一页
     */
    goPre(page){
      if (this.genealogySearchForm.page == 1) {
        this.$message({
          message: this.$t('www.已经在第一页了'),
          type: 'info',
        });
        return;
      }
      this.genealogySearchForm.page = page || 1;
      this.getGenealogyList();
    },
    /**
     * 跳转至后一页
     */
    goNext(page){
      if (this.genealogySearchForm.page == this.total_page) {
        this.$message({
          message: this.$t('www.已经在最后一页了'),
          type: 'info',
        });
        return;
      }
      this.genealogySearchForm.page = page > this.total_page ? this.total_page : page;
      this.getGenealogyList();
    },
    collapseChange(activeName) {
      if(!activeName) {
        return;
      }
      this.genealogySearchForm.province = activeName;
      let provinceInfo = this.provinceOptions.find(item => item.aname == activeName);
      this.genealogySearchForm.province_id = provinceInfo.id;
      this.genealogySearchForm.page = 1;

      this.getGenealogyList();
    },
    /**
     * 匹配姓氏和省份
     */
    matchSurnameAndProvince() {
      if (this.genealogySearchForm.surname_id && !this.genealogySearchForm.surname) {
        let surnameInterval
        let surnameInfo = this.surnameOptions.find(item => item.id == this.genealogySearchForm.surname_id);
        surnameInfo ? this.genealogySearchForm.surname = surnameInfo.surname : null;
      }
      if (this.genealogySearchForm.province_id && !this.genealogySearchForm.province) {
        let provinceInfo = this.provinceOptions.find(item => item.id == this.genealogySearchForm.province_id);
        provinceInfo ? this.genealogySearchForm.province = provinceInfo.id : null;
      }
    },
    changeSearchValue(value) {
      this.genealogySearchForm.surname = value;
    },
    selectSearchValue(surname) {
      this.genealogySearchForm.surname_id = surname.id;
      this.genealogySearchForm.surname = surname.surname;
    },
    changeProvince(value) {
      this.genealogySearchForm.province = this.provinceOptions.find(item => item.id == value).aname;
    },
    clearChoice(type) {
      if (type == 'surname') {
        this.genealogySearchForm.surname_id = null;
        this.genealogySearchForm.surname = null;
        this.genealogySearchForm.page = 1;
        this.getGenealogyList();
      } else if (type == 'province') {
        this.genealogySearchForm.province_id = null;
        this.genealogySearchForm.province = null;
        this.genealogySearchForm.page = 1;
        this.getGenealogyList();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.genealogy-list-content {
  .genealogy-list-top-banner {
    background-image: url("@/assets/images/genealogy/genealogy-list-bg.jpg");
    background-size: 100% 100%;
    padding-top: calc(var(--header-height) + 4rem);

    .search-content {
      width: var(--common-content-width);
      border: 0.06rem solid white;
      background-color: rgba(white, 0.2);

      ::v-deep .el-form.el-form--inline {

        .el-form-item {
          margin-right: 0.5rem;
          margin-bottom: 0;
          width: calc((100% - 13.5rem) / 3);

          .el-autocomplete {
            width: 100%;

            &.active {
              .el-input__wrapper {
                background-color: var(--blue-2);

                .el-input__inner {
                  color: black;
                }
              }
            }
          }

          .el-select {
            &.active {
              .el-select__wrapper {
                background-color: var(--blue-2);
              }
            }
          }

          .el-input {
            &.active {
              .el-input__wrapper {
                background-color: var(--blue-2);
              }
            }
          }
        }

        .el-button {
          height: 2.5rem!important;
        }
      }
    }
  }

  .genealogy-result {
    width: var(--common-content-width);
    max-width: 100%;

    .menu {
      background-color: white;

      ::v-deep .el-collapse {
        border: none;

        .el-collapse-item {

          .el-collapse-item__header {
            position: relative;
            border: none;
            height: 2rem;

            &::before {
              content: '+';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }

            &.is-active::before {
              content: '-';
            }

            .el-icon.el-collapse-item__arrow {
              display: none;
            }
          }

          .el-collapse-item__wrap {
            border: none;
          }
        }
      }
    }

    .menu-content {
      background-color: white;

      ::v-deep .el-table {
        color: black;

        .el-table__cell {
          padding: 1rem 0;
          border-bottom: 0.06rem dotted var(--light-grey);
        }
      }
    }
  }
}
</style>
