<template>
  <div class="personal-data-content" v-loading="personalDataLoading">
    <!--顶部-->
    <div class="personal-top-banner w-full">
      <div class="personal-top-wrapper mx-auto px-12 flex items-center">
        <img v-if="personInfo.sex == SEX_MAN" class="w-28 h-28" src="@/assets/images/family-tree/self_active.svg">
        <img v-else class="w-28 h-28" src="@/assets/images/family-tree/spouse_active.svg">
        <div class="personal-basic ml-8">
          <div class="name">{{ personInfo.surname + personInfo.name }}</div>
          <div class="intro flex items-center">
            <span>{{ personInfo.sex == SEX_MAN ? $t('tree.男') : personInfo.sex == SEX_WOMAN ? $t('tree.女') : $t('间性') }}</span>
            <span>{{ personInfo.birth_day ? $moment(personInfo.birth_day).format('YYYY') : $t('tree.未知')}} - {{ personInfo.death_day ? $moment(personInfo.death_day).format('YYYY') : $t('tree.未知')}}</span>
            <span>{{ personInfo.death ? $t('tree.殁') : $t('tree.在世') }}</span>
            <el-button round><img class="w-4 h-4 mr-2" src="@/assets/images/family-tree/info_family_tree.svg"/>
              <a :href="'/family-tree?id=' + family_tree_id + '&person-id=' + id">{{ $t('tree.查看家谱树') }}</a>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <!--资料-->
    <div class="personal-data-detail mt-4 mx-auto flex gap-x-4">

      <!--重要资料和简史-->
      <div class="w-80">
        <!--重要资料-->
        <div class="important-information rounded-3xl py-10 px-6">
          <div class="flex items-center">
            <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
            <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('tree.重要资料') }}</span>
          </div>
          <el-divider class="mt-2 mb-5"/>
          <el-form class="information-form" label-position="top">
            <el-form-item :label="$t('tree.名称')" prop="name">
              <div>
                <div>{{ personInfo.surname + personInfo.name }}</div>
              </div>
              <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                   @click="openEditBaseInfoDialog('surname-name', 2)">
                <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
                <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
              </div>
            </el-form-item>
            <el-form-item class="mt-10" :label="$t('tree.性别')" prop="sex">
              <div>{{ personInfo.sex == SEX_MAN ? $t('tree.男') : personInfo.sex == SEX_WOMAN ? $t('tree.女') : $t('tree.间性') }}</div>
              <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                   @click="openEditBaseInfoDialog('sex', 2)">
                <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
                <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
              </div>
            </el-form-item>
            <el-form-item class="mt-10" :label="$t('tree.出生')" prop="birth">
              <template v-if="personInfo.birth_day || personInfo.birth_address">
                <div>
                  <div v-if="personInfo.birth_day">{{ $moment(personInfo.birth_day).format('YYYY-MM-DD') }}</div>
                  <div v-if="personInfo.birth_address">{{ personInfo.birth_address }}</div>
                </div>
                <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                     @click="openEditBaseInfoDialog('birth', 2)">
                  <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
                  <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
                </div>
              </template>
              <div v-else class="text-[var(--light-orange)] cursor-pointer" @click="openEditBaseInfoDialog('birth', 1)">
                +&nbsp;{{ $t('加入') }}
              </div>
            </el-form-item>
            <el-form-item class="mt-10" :label="$t('tree.死亡')" prop="birth">
              <template v-if="personInfo.death_day || personInfo.death_address">
                <div>
                  <div v-if="personInfo.death_day">{{ $moment(personInfo.death_day).format('YYYY-MM-DD') }}</div>
                  <div v-if="personInfo.death_address">{{ personInfo.death_address }}</div>
                </div>
                <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                     @click="openEditBaseInfoDialog('death', 2)">
                  <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
                  <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
                </div>
              </template>
              <div v-else class="text-[var(--light-orange)] cursor-pointer" @click="openEditBaseInfoDialog('death', 1)">
                +&nbsp;{{ $t('加入') }}
              </div>
            </el-form-item>
          </el-form>
        </div>

        <!--简史-->
        <div class="brief-history rounded-3xl mt-4 py-10 px-6">
          <div class="flex items-center">
            <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
            <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('tree.简史') }}</span>
          </div>
          <el-divider class="mt-2 mb-5"/>
          <div v-if="briefHistory" class="w-full relative">
            <div>
              <div>{{ briefHistory.e_date }}</div>
              <div>{{ briefHistory.e_address }}</div>
              <div>{{ briefHistory.e_memo }}</div>
            </div>
            <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                 @click="editBriefHistory(2)">
              <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
              <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
            </div>
          </div>
          <div v-else class="text-[var(--light-orange)] cursor-pointer" @click="editBriefHistory(1)">
            +&nbsp;{{ $t('tree.撰写简史') }}
          </div>
        </div>
      </div>

      <!--家庭成员和事项-->
      <div class="grow">
        <!--家庭成员-->
        <div class="family-member rounded-3xl py-10 px-6">
          <div class="flex items-center">
            <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
            <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('tree.家庭成员') }}</span>
          </div>
          <el-divider class="mt-2 mb-5"/>
          <div class="flex gap-x-12 mt-8">
            <div class="w-1/2">
              <div class="title">{{ $t('tree.配偶与子女') }}</div>
              <div class="couple-box w-full rounded-xl mt-4">
                <div class="couple-item p-4 rounded-xl">
                  <div class="flex items-center px-2">
                    <img v-if="personInfo.sex == SEX_MAN" class="w-12 h-12" src="@/assets/images/family-tree/self_active.svg">
                    <img v-else class="w-12 h-12" src="@/assets/images/family-tree/spouse_active.svg">
                    <div class="ml-4">
                      <div class="name">{{ personInfo.surname + personInfo.name }}</div>
                      <div class="birth">{{ personInfo.birth_day ? $moment(personInfo.birth_day).format('YYYY') : $t('tree.未知') }} - {{ personInfo.death_day ? $moment(personInfo.death_day).format('YYYY') : $t('tree.未知')}}</div>
                    </div>
                  </div>
                  <template v-for="spouseItem in personInfo.spouses" :key="spouseItem.id">
                    <el-divider class="mt-4 mb-4"/>
                    <div class="flex items-center px-2">
                      <img v-if="spouseItem.sex == SEX_MAN" class="w-12 h-12" src="@/assets/images/family-tree/self_active.svg">
                      <img v-else class="w-12 h-12" src="@/assets/images/family-tree/spouse_active.svg">
                      <div class="ml-4">
                        <div class="name">
                          <a :href="'/family-tree-person?id=' + spouseItem.id +'&family_tree_id=' + family_tree_id"
                             target="_blank">
                            {{ spouseItem.surname + spouseItem.name }}
                          </a>
                        </div>
                        <div class="birth">
                          {{ spouseItem.birth_day ? $moment(spouseItem.birth_day).format('YYYY') : $t('tree.未知') }}
                          &nbsp;-&nbsp;
                          {{ spouseItem.death_day ? $moment(spouseItem.death_day).format('YYYY') : $t('tree.未知')}}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="(!personInfo.spouses || personInfo.spouses.length <= 0)">
                    <el-divider class="mt-4 mb-4"/>
                    <div class="flex items-center px-2">
                      <img v-if="personInfo.sex == SEX_MAN" class="w-12 h-12" src="@/assets/images/family-tree/spouse_unactive.svg">
                      <img v-else class="w-12 h-12" src="@/assets/images/family-tree/self_unactive.svg">
                      <div class="ml-4">
                        <div class="add cursor-pointer" @click="editPersonInfo(NODE_TYPE_SPOUSE, personInfo)">
                          +{{ $t('tree.添加配偶') }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="w-full px-4">
                  <div class="text-[var(--dark-blue)] py-2 px-4">
                    {{ $t('tree.子女') }}&nbsp;({{ personInfo.children ? personInfo.children.length : 0 }})
                  </div>
                  <div v-for="childItem in personInfo.children" :key="childItem.id"
                       class="rounded-xl bg-[var(--grey-3)] w-full px-4 py-2 flex items-center mb-2">
                    <img v-if="childItem.sex == SEX_MAN" class="w-8 h-8" src="@/assets/images/family-tree/son_active.svg">
                    <img v-else class="w-8 h-8" src="@/assets/images/family-tree/daughter_active.svg">
                    <div class="ml-4">
                      <div class="name text-sm">
                        <a :href="'/family-tree-person?id=' + childItem.id +'&family_tree_id=' + family_tree_id"
                           target="_blank">
                          {{ childItem.surname + (childItem.name || '') }}
                        </a>
                      </div>
                      <div class="birth text-xs">
                        {{ childItem.birth_day ? $moment(childItem.birth_day).format('YYYY') : $t('tree.未知') }}
                        &nbsp;-&nbsp;
                        {{ childItem.death_day ? $moment(childItem.death_day).format('YYYY') : $t('tree.未知')}}
                      </div>
                    </div>
                  </div>
                  <div class="text-[var(--grey-1)] pt-2 pb-4 cursor-pointer" @click="editPersonInfo(NODE_TYPE_CHILD, personInfo)">+&nbsp;{{ $t('tree.添加子女') }}</div>
                </div>
              </div>

              <div class="add-person text-[var(--grey-1)] py-4 mt-2 cursor-pointer px-8" @click="editPersonInfo(NODE_TYPE_SPOUSE, personInfo)">+&nbsp;{{ $t('tree.添加配偶') }}</div>
            </div>
            <div class="w-1/2">
              <div class="title">{{ $t('tree.父母与兄弟姐妹') }}</div>
              <div class="couple-box w-full rounded-xl mt-4">
                <div class="couple-item p-4 rounded-xl">
                  <div class="flex items-center px-2">
                    <img v-if="personInfo.father" class="w-12 h-12" src="@/assets/images/family-tree/self_active.svg">
                    <img v-else class="w-12 h-12" src="@/assets/images/family-tree/self_unactive.svg">
                    <div class="ml-4">
                      <template v-if="personInfo.father">
                        <div class="name">
                          <a :href="'/family-tree-person?id=' + personInfo.father.id +'&family_tree_id=' + family_tree_id"
                             target="_blank">
                            {{ personInfo.father.surname + (personInfo.father.name || '') }}
                          </a>
                        </div>
                        <div class="birth">
                          {{ personInfo.father.birth_day ? $moment(personInfo.father.birth_day).format('YYYY') : $t('tree.未知') }}
                          &nbsp;-&nbsp;
                          {{ personInfo.father.death_day ? $moment(personInfo.father.death_day).format('YYYY') : $t('tree.未知')}}
                        </div>
                      </template>
                      <div v-else class="add cursor-pointer" @click="editPersonInfo(NODE_TYPE_FATHER, personInfo)">
                        +{{ $t('tree.添加父亲') }}
                      </div>
                    </div>
                  </div>
                  <el-divider class="mt-4 mb-4"/>
                  <div class="flex items-center px-2">
                    <img v-if="personInfo.mather" class="w-12 h-12" src="@/assets/images/family-tree/spouse_active.svg">
                    <img v-else class="w-12 h-12" src="@/assets/images/family-tree/spouse_unactive.svg">
                    <div class="ml-4">
                      <template v-if="personInfo.mather">
                        <div class="name">
                          <a :href="'/family-tree-person?id=' + personInfo.mather.id +'&family_tree_id=' + family_tree_id"
                             target="_blank">
                            {{ personInfo.mather.surname + (personInfo.mather.name || '') }}
                          </a>
                        </div>
                        <div class="birth">
                          {{ personInfo.mather.birth_day ? $moment(personInfo.mather.birth_day).format('YYYY') : $t('tree.未知') }}
                          &nbsp;-&nbsp;
                          {{ personInfo.mather.death_day ? $moment(personInfo.mather.death_day).format('YYYY') : $t('tree.未知')}}
                        </div>
                      </template>
                      <div v-else class="add cursor-pointer" @click="editPersonInfo(NODE_TYPE_MOTHER, personInfo)">
                        +{{ $t('tree.添加母亲') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="personInfo.father || personInfo.mather" class="w-full px-4">
                  <div class="text-[var(--dark-blue)] py-2 px-4">
                    {{ $t('tree.子女') }}&nbsp;({{personInfo.brother ? personInfo.brother.length : 0}})
                  </div>
                  <div v-for="brotherItem in personInfo.brother" :key="brotherItem.id"
                       class="rounded-xl bg-[var(--grey-3)] w-full px-4 py-2 flex items-center mb-2">
                    <img v-if="brotherItem.sex == SEX_MAN" class="w-8 h-8" src="@/assets/images/family-tree/son_active.svg">
                    <img v-else class="w-8 h-8" src="@/assets/images/family-tree/daughter_active.svg">
                    <div class="ml-4">
                      <div class="name text-sm">
                        <a :href="'/family-tree-person?id=' + brotherItem.id +'&family_tree_id=' + family_tree_id"
                           target="_blank">
                          {{ brotherItem.surname + brotherItem.name }}
                        </a>
                      </div>
                      <div class="birth text-xs">
                        {{ brotherItem.birth_day ? $moment(brotherItem.birth_day).format('YYYY') : $t('tree.未知') }}
                        &nbsp;-&nbsp;
                        {{ brotherItem.death_day ? $moment(brotherItem.death_day).format('YYYY') : $t('tree.未知')}}
                      </div>
                    </div>
                  </div>
                  <div class="text-[var(--grey-1)] pt-2 pb-4 cursor-pointer" @click="editPersonInfo(NODE_TYPE_CHILD, personInfo.father || personInfo.mather)">+&nbsp;{{ $t('tree.添加子女') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--事项-->
        <div class="matter rounded-3xl mt-4 py-10 px-6 relative">
          <div class="flex items-center">
            <img class="w-7 h-7" src="@/assets/images/common/title_icon.svg">
            <span class="ml-3 text-2xl text-[var(--dark-blue)]">{{ $t('tree.事项') }}</span>
          </div>
          <div v-if="filterEvents && filterEvents.length" class="text-[var(--light-orange)] cursor-pointer absolute right-8 top-10" @click="editEvents(1)">
            +&nbsp;{{ $t('tree.添加其他事项') }}
          </div>
          <el-divider class="mt-2 mb-5"/>
          <el-form class="information-form" label-position="top">
            <el-form-item v-for="eventItem in filterEvents" :key="eventItem.id" :label="eventItem.type_info.type_name">
              <div>
                <div>{{ eventItem.e_memo }}</div>
                <div>{{ eventItem.e_date }}</div>
                <div>{{ eventItem.e_address }}</div>
              </div>
              <div class="edit-icon rounded-full bg-[var(--lighter-grey)] w-11 h-11 hover:bg-[var(--light-orange)] flex justify-center items-center cursor-pointer absolute right-0 bottom-0"
                   @click="editEvents(2, eventItem)">
                <img class="block w-5 h-5" src="@/assets/images/family-tree/info_edit.svg">
                <img class="hidden w-5 h-5" src="@/assets/images/family-tree/info_edit_active.svg">
              </div>
            </el-form-item>
          </el-form>
          <div v-if="!filterEvents || filterEvents.length <= 0" class="text-[var(--light-orange)] cursor-pointer" @click="editEvents(1)">
            +&nbsp;{{ $t('tree.添加事项') }}
          </div>
        </div>
      </div>

    </div>

    <!--编辑姓名弹窗-->
    <el-dialog
        v-model="editBaseInfoDialogVisible"
        width="500">
      <template #header>
        <div class="flex items-center">
          <img class="w-5 h-5 mr-2" src="@/assets/images/common/title_icon.svg">
          <span>{{editBaseInfoType.editType == 1 ? $t('tree.添加') : $t('tree.编辑')}}</span>
        </div>
      </template>

      <el-form ref="baseInfoFormRef" :model="editBaseInfo" label-width="auto" label-position="top" :rules="baseInfoFormRule">
        <el-form-item  v-if="editBaseInfoType.type == 'sex'" :label="$t('tree.性别')" prop="sex">
          <el-radio-group class="sex custom-radio" v-model="editBaseInfo.sex">
            <el-radio-button :label="$t('tree.男')" :value="1" />
            <el-radio-button :label="$t('tree.女')" :value="0" />
            <el-radio-button :label="$t('tree.未知')" :value="2" />
          </el-radio-group>
        </el-form-item>
        <template v-if="editBaseInfoType.type == 'surname-name'">
          <el-form-item :label="$t('tree.姓氏')" prop="surname">
            <el-input v-model="editBaseInfo.surname"/>
          </el-form-item>
          <el-form-item :label="$t('tree.名字')" prop="name">
            <el-input  v-model="editBaseInfo.name"/>
          </el-form-item>
        </template>
        <template  v-if="editBaseInfoType.type == 'birth'">
          <el-form-item :label="$t('tree.出生年份')">
            <el-date-picker class="birth-day"
                            v-model="editBaseInfo.birth_day"
                            type="date" value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item :label="$t('tree.出生地')">
            <el-input v-model="editBaseInfo.birth_address"/>
          </el-form-item>
        </template>
        <template  v-if="editBaseInfoType.type == 'death'">
          <el-form-item :label="$t('tree.死亡日期')">
            <el-date-picker class="death-day"
                            v-model="editBaseInfo.death_day"
                            type="date" value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item :label="$t('tree.埋葬地')">
            <el-input v-model="editBaseInfo.death_address"/>
          </el-form-item>
        </template>
      </el-form>

      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button @click="editBaseInfoDialogVisible = false">{{ $t('tree.取消')}}</el-button>
          <el-button type="primary" @click="commitBaseInfo()">
            {{ $t('tree.确认')}}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--编辑简史-->
    <el-dialog
        v-model="editBriefHistoryDialogVisible"
        width="500">
      <template #header>
        <div class="flex items-center">
          <img class="w-5 h-5 mr-2" src="@/assets/images/common/title_icon.svg">
          <span>{{editBaseInfoType == 1 ? $t('tree.添加') : $t('tree.编辑')}}</span>
        </div>
      </template>

      <el-form ref="editBriefHistoryFormRef" :model="editBriefHistoryInfo" label-width="auto" label-position="top">
        <el-form-item :label="$t('tree.发生日期')" prop="e_date">
          <el-date-picker v-model="editBriefHistoryInfo.e_date"
                          type="date" value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item :label="$t('tree.发生地点')" prop="e_address">
          <el-input v-model="editBriefHistoryInfo.e_address"/>
        </el-form-item>
        <el-form-item :label="$t('tree.事件描述')" prop="e_memo">
          <el-input
              v-model="editBriefHistoryInfo.e_memo"
              :autosize="{ minRows: 8, maxRows: 15 }"
              type="textarea"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button @click="editBriefHistoryDialogVisible = false">{{ $t('tree.取消')}}</el-button>
          <el-button type="primary" @click="commitBriefHistoryInfo()">
            {{ $t('tree.确认')}}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--编辑事项-->
    <el-dialog
        v-model="editEventsDialogVisible"
        width="500">
      <template #header>
        <div class="flex items-center">
          <img class="w-5 h-5 mr-2" src="@/assets/images/common/title_icon.svg">
          <span>{{editEventsType == 1 ? $t('tree.添加') : $t('tree.编辑')}}</span>
        </div>
      </template>

      <el-form ref="editEventsFormRef" :model="editEventsInfo" label-width="auto" label-position="top" :rules="editEventsFormRules">
        <el-form-item :label="$t('事件类型')" prop="e_type">
          <el-select v-model="editEventsInfo.e_type" :placeholder="$t('tree.请选择事件类型')">
            <el-option-group
                v-for="group in eventTypes"
                :key="group.id"
                :label="group.type_name"
            >
              <el-option
                  v-for="eventTypeItem in group.children"
                  :key="eventTypeItem.id"
                  :label="eventTypeItem.type_name"
                  :value="eventTypeItem.id"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tree.发生日期')" prop="e_date">
          <el-date-picker v-model="editEventsInfo.e_date"
                          type="date" value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item :label="$t('tree.发生地点')" prop="e_address">
          <el-input v-model="editEventsInfo.e_address"/>
        </el-form-item>
        <el-form-item :label="$t('tree.事件描述')" prop="e_memo">
          <el-input
              v-model="editEventsInfo.e_memo"
              :autosize="{ minRows: 8, maxRows: 15 }"
              type="textarea"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button @click="editEventsDialogVisible = false">{{ $t('tree.取消')}}</el-button>
          <el-button type="primary" @click="commitEventsInfo()">
            {{ $t('tree.确认')}}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--人物信息编辑弹窗-->
    <el-dialog
        v-model="editPersonInfoDialogVisible"
        width="500">
      <template #header>
        <div class="flex items-center">
          <img class="w-5 h-5 mr-2" src="@/assets/images/common/title_icon.svg">
          <div>{{editPersonInfoType == 1 ? $t('tree.添加父亲') : editPersonInfoType == 2 ? $t('tree.添加母亲') : editPersonInfoType == 3 ? $t('tree.添加子女') : editPersonInfoType == 4 ? $t('tree.添加配偶') : $t('tree.添加')}}</div>
        </div>
      </template>

      <el-form ref="personInfoFormRef" :model="personInfoForm" label-width="auto"
               label-position="top" :rules="personInfoFormRule">
        <el-form-item v-if="editPersonInfoType == NODE_TYPE_CHILD && relatedPersonInfo.spouses && relatedPersonInfo.spouses.length" :label="$t('tree.母亲')" prop="spouse_id">
          <el-select v-model="personInfoForm.spouse_id" :placeholder="$t('tree.请选择母亲')">
            <el-option
                v-for="spouseItem in relatedPersonInfo.spouses"
                :key="spouseItem.id"
                :label="spouseItem.surname + spouseItem.name"
                :value="spouseItem.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tree.性别')" prop="sex">
          <el-radio-group class="sex custom-radio" v-model="personInfoForm.sex">
            <el-radio-button :label="$t('tree.男')" :value="1" />
            <el-radio-button :label="$t('tree.女')" :value="0" />
            <el-radio-button :label="$t('tree.未知')" :value="2" />
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('tree.姓氏')" prop="surname">
          <el-input v-model="personInfoForm.surname"/>
        </el-form-item>
        <el-form-item :label="$t('tree.名字')" prop="name">
          <el-input  v-model="personInfoForm.name"/>
        </el-form-item>
        <el-form-item :label="$t('tree.字')" class="zi w-1/2 inline-block">
          <el-input v-model="personInfoForm.zi" style="width: 15rem"/>
        </el-form-item>
        <el-form-item :label="$t('tree.号')" class="hao w-1/2 inline-block" :inline="true">
          <el-input  v-model="personInfoForm.hao" style="width: 15rem"/>
        </el-form-item>
        <el-form-item :label="$t('tree.谥号')">
          <el-input v-model="personInfoForm.shihao"/>
        </el-form-item>
        <el-form-item :label="$t('tree.生存状况')">
          <el-radio-group class="death custom-radio" v-model="personInfoForm.death">
            <el-radio-button :label="$t('tree.在世')" :value="0" />
            <el-radio-button :label="$t('tree.死亡')" :value="1" />
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('tree.出生日期')">
          <el-date-picker class="birth-day"
                          v-model="personInfoForm.birth_day"
                          type="date" value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item :label="$t('tree.出生地')">
          <el-input v-model="personInfoForm.birth_address"/>
        </el-form-item>
        <el-form-item v-if="personInfoForm.death == DEATH_STATUS_DEATH" :label="$t('tree.死亡日期')">
          <el-date-picker class="death-day"
                          v-model="personInfoForm.death_day"
                          type="date" value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item v-if="personInfoForm.death == DEATH_STATUS_DEATH" :label="$t('tree.埋葬地')">
          <el-input v-model="personInfoForm.death_address"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button @click="editPersonInfoDialogVisible = false">{{ $t('tree.取消')}}</el-button>
          <el-button type="primary" @click="commitPersonInfo()">
            {{ $t('tree.确认')}}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref} from 'vue';
import util from '@/utils/util';
import {
  EVENT_TYPE_BRIEF_HISTORY,
  MESSAGE_ICON_COMMON,
  MESSAGE_ICON_ERROR,
  MESSAGE_ICON_NODATA, NODE_TYPE_CHILD, NODE_TYPE_FATHER, NODE_TYPE_MOTHER, NODE_TYPE_SPOUSE,
  SEX_MAN,
  SEX_WOMAN,
  SUCCESS_CODE
} from "@/utils/constants";
import {addEvent, createTreeNode, getPersonInfoAll, updateEvent, updatePersonInfo} from "@/api/family-tree";
import {getEventTypes} from "@/api/common";
import {BasePersonInfo} from "@/entity/BasePersonInfo";

export default {
  name: "PersonalData",
  setup() {
    // 人物ID
    const id = ref(null);
    // 家谱树ID
    const family_tree_id = ref(null);

    // loading
    const personalDataLoading = ref(true);

    // 人物信息
    const personInfo = ref({});

    // 事件类型
    const eventTypes = ref([]);

    // 编辑基础信息弹窗
    const editBaseInfoDialogVisible = ref(false);
    // 编辑基础信息类型
    const editBaseInfoType = ref({
      type: '',
      editType: 1
    });
    // 编辑人物基础信息
    const editBaseInfo = ref({});

    // 编辑简史信息弹窗
    const editBriefHistoryDialogVisible = ref(false);
    // 编辑简史信息类型
    const editBriefHistoryType = ref(1);
    // 编辑简史基础信息
    const editBriefHistoryInfo = ref({});

    // 编辑事项信息弹窗
    const editEventsDialogVisible = ref(false);
    // 编辑事项信息类型
    const editEventsType = ref(1);
    // 编辑事项基础信息
    const editEventsInfo = ref({});

    //编辑人物信息弹窗
    const editPersonInfoDialogVisible = ref(false);
    // 编辑人物信息类型
    const editPersonInfoType = ref(1);
    // 编辑人物信息
    const personInfoForm = ref({});
    // 相关人物信息
    const relatedPersonInfo = ref({});

    return {
      SEX_MAN: SEX_MAN,
      SEX_WOMAN: SEX_WOMAN,
      NODE_TYPE_FATHER: NODE_TYPE_FATHER,
      NODE_TYPE_MOTHER: NODE_TYPE_MOTHER,
      NODE_TYPE_CHILD: NODE_TYPE_CHILD,
      NODE_TYPE_SPOUSE: NODE_TYPE_SPOUSE,
      id,
      family_tree_id,
      personalDataLoading,
      personInfo,
      eventTypes,
      editBaseInfoDialogVisible,
      editBaseInfoType,
      editBaseInfo,
      editBriefHistoryDialogVisible,
      editBriefHistoryType,
      editBriefHistoryInfo,
      editEventsDialogVisible,
      editEventsType,
      editEventsInfo,
      editPersonInfoDialogVisible,
      editPersonInfoType,
      personInfoForm,
      relatedPersonInfo
    }
  },
  computed: {
    // 简史
    briefHistory() {
      return this.personInfo.event ? this.personInfo.event.find(item => item.e_type == EVENT_TYPE_BRIEF_HISTORY) : null;
    },
    // 人物基础信息表单校验
    baseInfoFormRule() {
      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        name: {
          required: true,
          message: this.$t('user.请输入名字'),
          trigger: 'blur'
        }
      };
    },
    // 过滤掉简史的事件
    filterEvents() {
      return this.personInfo.event ? this.personInfo.event.filter(item => item.e_type != 14) : [];
    },
    // 编辑事项表单校验规则
    editEventsFormRules() {
      return {
        e_type: {
          required: true,
          message: this.$t('tree.请选择事件类型'),
          trigger: 'blur'
        }
      };
    },
    // 编辑人物信息表单校验
    personInfoFormRule() {
      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        name: {
          required: true,
          message: this.$t('tree.请输入名字'),
          trigger: 'blur'
        }
      };
    }
   },
  created() {
    if (this.$route.query['id'] && this.$route.query['family_tree_id']) {
      this.id = this.$route.query['id'];
      this.family_tree_id = this.$route.query['family_tree_id'];
      this.getPersonalInfo();
    } else {
      util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('tree.无对应个人信息')).then(res => {
        this.$router.push('/family-tree');
      })
      return;
    }
    // 获取事件类型
    this.getEventTypes();
  },
  methods: {
    /**
     * 获取人物全部信息
     */
    getPersonalInfo() {
      this.personalDataLoading = true;
      getPersonInfoAll({id: parseInt(this.id), family_tree_id: parseInt(this.family_tree_id)}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.personInfo = res.data;
          this.personalDataLoading = false;
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_NODATA,  this.$t('user.非常抱歉'), this.$t('tree.未查询到该人物信息')).then(res => {
            this.$router.push('/family-tree');
          })
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR,  this.$t('user.非常抱歉'), this.$t('tree.未查询到该人物信息')).then(res => {
          this.$router.push('/family-tree');
        })
      })
    },
    /**
     * 获取事件类型
     */
    getEventTypes() {
      getEventTypes().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.eventTypes = res.data;
        } else {
          this.eventTypes = [];
        }
      }).catch(error => {
        this.eventTypes = [];
      })
    },
    /**
     * 打开基础信息编辑弹窗
     * @param type
     * @param editType 编辑类型 1：加入 2：更新
     */
    openEditBaseInfoDialog(type, editType = 1) {
      this.editBaseInfoDialogVisible = true;
      this.editBaseInfoType.type = type;
      this.editBaseInfoType.editType = editType;
      this.editBaseInfo = {...this.personInfo}
    },
    /**
     * 更新基础信息
     */
    commitBaseInfo() {
      this.$refs.baseInfoFormRef.validate((valid, fields) => {
        if (valid) {
          let params = {
            ...new BasePersonInfo(this.editBaseInfo),
            family_tree_id: this.family_tree_id,
            id: this.editBaseInfo.id
          }
          updatePersonInfo(params).then(res => {
            if (res.code == SUCCESS_CODE) {
              this.$message({
                message: this.editBaseInfoType.editType == 1 ? this.$t('tree.操作成功') : this.$t('tree.更新成功'),
                type: 'success',
              });
              this.editBaseInfoDialogVisible = false;
              this.getPersonalInfo();
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.更新失败'));
          })
        }
      });
    },
    /**
     * 打开简史编辑弹窗
     * @param type
     */
    editBriefHistory(type) {
      this.editBriefHistoryDialogVisible = true;
      this.editBaseInfoType = type;
      this.editBriefHistoryInfo = {...this.briefHistory}
    },
    /**
     * 提交简史信息
     */
    commitBriefHistoryInfo() {
      if (!this.editBriefHistoryInfo.e_date && !this.editBriefHistoryInfo.e_address && !this.editBriefHistoryInfo.e_memo) {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('tree.简史信息不能全部为空'));
        return;
      }
      if (this.editBaseInfoType == 1) { // 添加
        let params = {
          person_id: this.id,
          e_type: 14,
          ...this.editBriefHistoryInfo
        }
        addEvent(params).then(res => {
          if (res.code == SUCCESS_CODE) {
            this.$message({
              message: this.$t('tree.操作成功'),
              type: 'success',
            });
            this.editBriefHistoryDialogVisible = false;
            this.getPersonalInfo();
          } else {
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
          }
        }).catch(error => {
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.操作失败'));
        })
      } else { // 更新
        let params = {
          id: this.editBriefHistoryInfo.id,
          person_id: this.id,
          e_type: 14,
          e_date: this.editBriefHistoryInfo.e_date,
          e_memo: this.editBriefHistoryInfo.e_memo,
          e_address: this.editBriefHistoryInfo.e_address
        }
        updateEvent(params).then(res => {
          if (res.code == SUCCESS_CODE) {
            this.$message({
              message: this.$t('tree.更新成功'),
              type: 'success',
            });
            this.editBriefHistoryDialogVisible = false;
            this.getPersonalInfo();
          } else {
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
          }
        }).catch(error => {
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.更新失败'));
        })
      }
    },
    /**
     * 打开编辑事件弹窗
     * @param type
     * @param info
     */
    editEvents(type, info) {
      this.editEventsDialogVisible = true;
      this.editEventsType = type;
      if (this.editEventsType == 2) { // 更新
        this.editEventsInfo = {...info};
      } else {
        this.editEventsInfo = {};
      }
    },
    /**
     * 提交事项信息
     */
    commitEventsInfo() {
      this.$refs.editEventsFormRef.validate((valid, fields) => {
        if (valid) {
          if (!this.editEventsInfo.e_date && !this.editEventsInfo.e_address && !this.editEventsInfo.e_memo) {
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('tree.事项信息不能全部为空'));
            return;
          }
          if (this.editEventsType == 1) { // 添加
            let params = {
              person_id: this.id,
              ...this.editEventsInfo
            }
            addEvent(params).then(res => {
              if (res.code == SUCCESS_CODE) {
                this.$message({
                  message: this.$t('tree.添加成功'),
                  type: 'success',
                });
                this.editEventsDialogVisible = false;
                this.getPersonalInfo();
              } else {
                util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
              }
            }).catch(error => {
              util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.添加失败'));
            })
          } else { // 更新
            let params = {
              id: this.editEventsInfo.id,
              person_id: this.id,
              e_type: this.editEventsInfo.e_type,
              e_date: this.editEventsInfo.e_date,
              e_memo: this.editEventsInfo.e_memo,
              e_address: this.editEventsInfo.e_address
            }
            updateEvent(params).then(res => {
              if (res.code == SUCCESS_CODE) {
                this.$message({
                  message: this.$t('tree.更新成功'),
                  type: 'success',
                });
                this.editEventsDialogVisible = false;
                this.getPersonalInfo();
              } else {
                util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
              }
            }).catch(error => {
              util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.更新失败'));
            })
          }
        }
      })
    },
    /**
     * 加入人物信息
     * @param nodeType 1加入父亲 2 加入母亲 3 加入子女 4 加入配偶
     * @param relatedPersonInfo 相关节点
     */
    editPersonInfo(nodeType, relatedPersonInfo) {
      this.editPersonInfoDialogVisible = true;
      this.editPersonInfoType = nodeType;
      this.relatedPersonInfo = relatedPersonInfo;
      this.personInfoForm = new BasePersonInfo();
    },
    /**
     * 提交人物信息
     */
    commitPersonInfo() {
      this.$refs.personInfoFormRef.validate((valid, fields) => {
        if (valid) {
          let params = {
            ...new BasePersonInfo(this.personInfoForm),
            family_tree_id: this.family_tree_id,
            master_id: this.relatedPersonInfo.id,
            node_type: this.editPersonInfoType
          }
          if (this.editPersonInfoType == NODE_TYPE_CHILD && this.relatedPersonInfo.spouses && this.relatedPersonInfo.spouses.length && !this.personInfoForm.spouse_id) {
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('tree.请选择母亲'));
            return;
          } else if (this.editPersonInfoType == NODE_TYPE_CHILD && this.relatedPersonInfo.spouses && this.relatedPersonInfo.spouses.length) {
            params.spouse_id = this.personInfoForm.spouse_id;
          }
          createTreeNode(params).then(res => {
            if (res.code == SUCCESS_CODE) {
              this.$message({
                message: this.$t('tree.添加成功'),
                type: 'success',
              });
              this.editPersonInfoDialogVisible = false;
              this.getPersonalInfo();
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.添加失败'));
          })
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.personal-data-content {
  background-color: var(--pale-blue);
  padding-bottom: 3rem;

  .personal-top-banner {
    background-image: url("@/assets/images/family-tree/personal_data_top_bg.jpg");
    background-size: 100% 100%;
    padding-top: calc(2rem + var(--header-height));
    padding-bottom: 2rem;

    .personal-top-wrapper {
      width: 70rem;

      .personal-basic {

        .name {
          font-size: 1.5rem;
          color: var(--dark-blue);
          font-weight: bold;
        }

        .intro {

          span {
            margin: 0 1rem 0 0;
            position: relative;
            color: var(--dark-blue);

            &:not(:nth-child(4))::after {
              content: '';
              width: 0.01rem;
              height: 1rem;
              background-color: var(--dark-blue);
              position: absolute;
              right: -0.5rem;
              top: 45%;
              transform: translateY(-50%);
            }
          }

          ::v-deep .el-button {
            background-color: var(--light-orange);
            color: white;
            padding: 0.5rem 1.5rem;
          }
        }
      }
    }
  }

  .personal-data-detail {
    width: var(--common-content-width);
    max-width: 100%;

    .important-information {
      background-color: white;

    }

    .brief-history {
      background-color: white;

      .edit-icon {

        &:hover {
          .hidden {
            display: block;
          }
          .block {
            display: none;
          }
        }
      }
    }

    .family-member {
      background-color: white;

      .title {
        font-size: 1.4rem;
        color: var(--dark-blue);
      }

      .couple-box {
        border: 0.06rem solid white;
        background-color: var(--pale-blue);
        box-shadow: 0 0 0 0.06rem var(--pale-blue);
        position: relative;

        .couple-item {
          background-color: white;

          .name {
            color: var(--dark-blue);
            font-weight: 500;
          }

          .birth {
            color: var(--dark-grey);
            font-size: 0.9rem;
          }

          .add {
            color: var(--dark-grey);
          }
        }

        &::before {
          content: '';
          width: 0.25rem;
          height: 2.5rem;
          background-image: url("@/assets/images/family-tree/decorate_blue.svg");
          background-size: 100% 100%;
          position: absolute;
          left: -0.25rem;
          top: 1.25rem;
        }

        &::after {
          content: '';
          width: 0.25rem;
          height: 2.5rem;
          background-image: url("@/assets/images/family-tree/decorate_red.svg");
          background-size: 100% 100%;
          position: absolute;
          left: -0.25rem;
          top: 6.25rem;
        }
      }

      .add-person {
        border-bottom: 0.1rem solid var(--grey-2);
      }
    }

    .matter {
      background-color: white;

    }
  }
}

::v-deep .el-form {

  .el-form-item {
    margin-top: 1.5rem;

    .el-form-item__label {
      color: var(--dark-blue);
      font-weight: 600;
      font-size: 1.1rem;
      margin-bottom: 0.3rem;
    }

    .el-form-item__content {
      line-height: 1.3rem;

      .edit-icon {

        &:hover {
          .hidden {
            display: block;
          }
          .block {
            display: none;
          }
        }
      }
    }
  }
}
</style>
