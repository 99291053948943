import request from '@/utils/request'

/**
 * 获取城市名称
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPubCity(data) {
    return request({
        url: 'api/web/pub/city',
        method: 'post',
        data
    })
}

/**
 * 获取姓氏 用于用户输入姓氏的时候提示 筛选选择
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPubSurname() {
    return request({
        url: 'api/web/pub/surname',
        method: 'post'
    })
}

/**
 * 获取事件类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getEventTypes() {
    return request({
        url: 'api/web/pub/person/event/type',
        method: 'post'
    })
}