import {createStore} from 'vuex'
import {
    getLangCookie,
    getToken,
    getUserInfo,
    langSet,
    removeToken,
    setLangCookie,
    setToken,
    userLogout
} from "@/api/auth";
import {SUCCESS_CODE} from "@/utils/constants";
import {router} from '@/router/router'

export default createStore({
    state() {
        return {
            token: getToken(),
            uid: 0,
            userInfo: null,
            lang: getLangCookie() || 'zh-CN'
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        CLEAR_TOKEN(state) {
            state.token = null;
        },
        SET_UID(state, uid) {
            state.uid = uid;
        },
        SET_USERINFO(state, userInfo) {
            state.userInfo = userInfo;
        },
        SET_LANG(state, lang) {
            state.lang = lang;
        }
    },
    actions: {
        login({ commit }, token) {
            setToken(token);
            commit('SET_TOKEN', token);
        },
        setUID({ commit }, uid) {
            commit('SET_UID', uid);
        },
        removeTokenStore({ commit }) {
            removeToken();
            commit('CLEAR_TOKEN');
        },
        /**
         * 获取用户信息
         * @param commit
         * @returns {Promise<unknown>}
         */
        fetchUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo({silence: true}).then(res => {
                    if (res.code == SUCCESS_CODE && res.data) {
                        commit('SET_USERINFO', res.data);
                        resolve(res)
                    } else {
                        commit('SET_USERINFO', {});
                        reject(res);
                    }
                }).catch(error => {
                    commit('SET_USERINFO', {});
                    reject(error);
                })
            });
        },
        /**
         * 用户退出登录
         * @param commit
         * @returns {Promise<unknown>}
         */
        userLogout({ commit }) {
            return new Promise((resolve, reject) => {
                userLogout().then(res => {
                    if (res.code == SUCCESS_CODE) {
                        removeToken();
                        commit('CLEAR_TOKEN');
                        resolve(res)
                    } else {
                        reject(res);
                    }
                }).catch(error => {
                    reject(error);
                })
            });
        },
        /**
         * 设置语言环境
         * @param commit
         * @param lang
         */
        langSet({ state, commit }, lang) {
            return new Promise((resolve, reject) => {
                if (!state.token) {
                    setLangCookie(lang);
                    commit('SET_LANG', lang);
                    resolve();
                    return;
                }
                langSet({lang: lang}).then(res => {
                    if (res.code == SUCCESS_CODE) {
                        setLangCookie(lang);
                        commit('SET_LANG', lang);
                        // 重新加载页面
                        location.reload();
                        resolve(res)
                    } else {
                        reject(res);
                    }
                }).catch(error => {
                    reject(error);
                })
            });
        }
    },
    getters: {
        getToken: state => state.token,
        isAuthenticated: state => !!state.token,
        uid: state => state.uid,
        userInfo: state => state.userInfo,
        lang: state => state.lang
    },
})
