<template>
  <div class="login-content">
    <!--登录注册标题-->
    <div class="login-register-title flex justify-center">
      <div>
        <el-link class="login" href="/auth/login" :underline="false">{{$t('user.登录')}}</el-link>
      </div>
      <div>
        <el-link class="register" href="/auth/register" :underline="false">{{$t('user.注册')}}</el-link>
      </div>
    </div>

    <!--登录内容项-->
    <div class="login-input mt-8 mb-8">
      <el-form ref="registerFormRef"
          :model="registerForm" class="register-form flex flex-wrap"
          :rules="rules"
          label-width="auto">
        <el-form-item :label="$t('user.姓氏')" prop="surname" class="surname lg:w-1/2 max-lg:w-full" :class="{active: registerForm.surname, 'mobile': isMobileDevice}">
          <el-input :placeholder="$t('user.姓氏')" v-model="registerForm.surname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.名字')" prop="last_name" class="name lg:w-1/2 max-lg:w-full" :class="{active: registerForm.last_name, 'mobile': isMobileDevice}">
          <el-input :placeholder="$t('user.名字')" v-model="registerForm.last_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.登录账号')" prop="login_name" class="w-full" :class="{active: registerForm.login_name}">
          <el-input :placeholder="$t('user.登录账号')" v-model="registerForm.login_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.电子邮箱')" prop="email" class="w-full" :class="{active: registerForm.email}">
          <el-input :placeholder="$t('user.电子邮箱')" v-model="registerForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.登录密码')" prop="passwd" class="w-full" :class="{active: registerForm.passwd}">
          <el-input :placeholder="$t('user.登录密码')" v-model="registerForm.passwd" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.确认密码')" prop="passwd_confirmation" class="w-full" :class="{active: registerForm.passwd_confirmation}">
          <el-input :placeholder="$t('user.确认密码')" v-model="registerForm.passwd_confirmation" type="password"></el-input>
        </el-form-item>
<!--        <el-form-item :label="$t('user.手机号码')" prop="mobile" class="mobile w-full" :class="{active: registerForm.mobile}">-->
<!--          <div class="absolute h-10 left-14 top-0 leading-10 text-[var(&#45;&#45;dark-grey)]">+{{registerForm.country}}</div>-->
<!--          <vue-tel-input class="w-full h-10 mb-2.5"-->
<!--                         v-model="registerForm.mobile" mode="national" :input-options="{placeholder: $t('user.手机号码')}" :auto-format="false"-->
<!--                         :default-country="defaultCountry" @country-changed="changeCountry" ></vue-tel-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('user.短信验证码')" prop="sms_code" class="sms w-full" :class="{active: registerForm.sms_code}">-->
<!--          <el-input :placeholder="$t('user.短信验证码')" v-model="registerForm.sms_code">-->

<!--            <template #append>-->
<!--              <el-button @click="getSMS" :disabled="smsCountdown > 0">{{ smsCountdown > 0 ? smsCountdown : $t('user.发送验证码') }}</el-button>-->
<!--            </template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
      </el-form>
    </div>

    <el-button round @click="userRegister()">{{$t('user.立即注册')}}</el-button>

    <div class="login-privacy">{{$t("user.注册即代表同意")}}<a href="/user-service-agreement" target="_blank">《{{$t('user.用户服务协议')}}》</a> <a href="/privacy-policy" target="_blank">《{{$t('user.隐私政策')}}》</a></div>
  </div>
</template>

<script>
import {ref, reactive} from 'vue';
import {getSMS, userRegister} from "@/api/auth";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import { useI18n } from 'vue-i18n'
import util from '@/utils/util';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "Register",
  components: {
    VueTelInput
  },
  setup() {
    const { t } = useI18n();

    // 注册表单
    const registerForm = reactive({
      surname: '',
      last_name: '',
      login_name: '',
      email: '',
      passwd: '',
      passwd_confirmation: '',
      country: '86',
      mobile: '',
      sms_code: ''
    });

    // 短信倒计时秒数
    const smsCountdown = ref(0);

    const defaultCountry = ref('cn');

    return {
      registerForm,
      smsCountdown,
      defaultCountry,
    }
  },
  computed: {
    rules() {

      // 验证密码
      const validatePasswd = (rule, value, callback) => {
        if (this.registerForm.passwd !== '') {
          this.$refs.registerFormRef.validateField('passwd_confirmation', () => null)
        }
        callback()
      }

      // 验证确认密码
      const validatePasswdConfirmation = (rule, value, callback) => {
        if (value !== this.registerForm.passwd) {
          callback(new Error('密码与确认密码不一致'))
        } else {
          callback()
        }
      }

      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        last_name: {
          required: true,
          message: this.$t('user.请输入名字'),
          trigger: 'blur'
        },
        login_name: {
          required: true,
          message: this.$t('user.请输入登录账号'),
          trigger: 'blur'
        },
        email: {
          required: true,
          type: 'email',
          message: this.$t('user.请输入正确格式的邮箱'),
          trigger: 'blur'
        },
        passwd: [{
          required: true,
          message: this.$t('user.请输入密码'),
          trigger: 'blur'
        }, {
          min: 5, message: '至少为5个字符', trigger: 'blur'
        },{
          validator: validatePasswd, trigger: 'blur'
        }],
        passwd_confirmation: [{
          required: true,
          message: this.$t('user.请输入确认密码'),
          trigger: 'blur'
        }, {
          min: 5, message: '至少为5个字符', trigger: 'blur'
        },{
          validator: validatePasswdConfirmation, trigger: 'blur'
        }],
        // mobile: [{
        //   required: true,
        //   message: this.$t('user.请输入手机号码'),
        //   trigger: 'blur'
        // }],
        // sms_code: {
        //   required: true,
        //   message: this.$t('user.请输入短信验证码'),
        //   trigger: 'blur'
        // }
      }
    },
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  methods: {
    /**
     * 获取验证码
     */
    getSMS() {
      if (!this.registerForm.mobile) {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('user.请输入手机号码'));
        return;
      }
      getSMS({"mobile": this.registerForm.mobile, country: this.registerForm.country}).then(res => {
        if (res.code == SUCCESS_CODE) {
          this.smsCountdown = 60;
          const timer = setInterval(() => {
            this.smsCountdown--;
            if (this.smsCountdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
          // TODO 临时弹窗显示密码
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, '注意', res.msg);
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('获取短信验证码失败'));
      });
    },
    /**
     * 用户注册
     */
    userRegister() {
      this.$refs.registerFormRef.validate((valid, fields) => {
        if (valid) {
          userRegister(this.registerForm).then(res => {
            if (res.code == SUCCESS_CODE && res.data) {
              this.$message({
                message: this.$t('user.注册成功'),
                type: 'success',
                duration: '500',
                onClose: () => {
                  // 跳转至登录页
                  this.$router.push('/auth/login');
                }
              })
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('注册失败'));
          })
        }
      })
    },
    changeCountry(value) {
      this.registerForm.country = value.dialCode;
    }
  }
}
</script>

<style scoped lang="scss">
.login-content {
  position: relative;
  width: 100%;
  height: 100%;



  .login-register-title {

    &>div {
      padding: 0 1.5rem;
      position: relative;
      cursor: pointer;

      ::v-deep .el-link {
        font-size: 1.8rem;
        font-weight: normal;

        &.login {
          color: var(--dark-grey);
        }

        &.register {
          color: var(--dark-blue);
        }
      }

      &:first-child::after {
        content: '';
        width: 0.06rem;
        height: 1.8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: var(--middle-grey);
      }
    }
  }

  .login-input {

    ::v-deep .register-form {

      .el-form-item__error {
        font-size: 1rem;
        right: 0.25rem;
        padding-top: 0.125rem;
        bottom: 0.7rem;
        left: unset;
        top: unset;
      }

      .el-form-item.sms .el-form-item__error {
        right: 7.65rem;
      }

      .el-form-item {
        position: relative;
        margin-bottom: 0;

        .el-form-item__label-wrap {
          position: absolute;
          top: 0;
          left: 1rem;
          z-index: 1;
          display: none;
          transform: translateY(-50%);
          background-image: linear-gradient(to bottom, white 0%, white 51%, var(--blue-2) 52%, var(--blue-2) 100%);
          height: 0.875rem;
          line-height: 1;

          .el-form-item__label {
            color: var(--middle-grey);
            height: 0.875rem;
            font-size: 0.875rem;
            line-height: 1;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
          }
        }

        &.active {

          .el-form-item__label-wrap {
            display: block;
            animation: from-bottom-up 0.2s forwards;
          }

          @keyframes from-bottom-up {
            0% {
              transform: translateY(0) scale(1.2);
            }
            100% {
              transform: translateY(-50%) scale(1);
            }
          }

          .el-input__wrapper {
            background-color: var(--blue-2);
          }

          .vue-tel-input {
            background-color: var(--blue-2);

            .vti__input {
              background-color: var(--blue-2);
            }

          }
        }
      }

      .el-form-item.surname:not(.mobile) {

        .el-input__wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .el-form-item.name:not(.mobile) {

        .el-input__wrapper {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: 0 0.06rem 0 0 var(--el-input-border-color) inset, 0 -0.06rem 0 0 var(--el-input-border-color) inset, -0.06rem 0 0 0 var(--el-input-border-color) inset;
        }

        &.is-error {
          .el-input__wrapper {
            box-shadow: 0 0.06rem 0 0 var(--el-color-danger) inset, 0 -0.06rem 0 0 var(--el-color-danger) inset, -0.06rem 0 0 0 var(--el-color-danger) inset;
          }
        }
      }

      .el-input-group__append {
        padding: 0;
        background-color: var(--light-grey);
        width: 7.4rem;

        .el-button {
          width: 100%;
          height: 100%;
          background-color: transparent;
          color: var(--dark-grey);
          border-radius: 0;
          margin-top: 0;
          padding: 0;
          line-height: 100%;
        }
      }

      .vue-tel-input {
        border: 0.06rem solid var(--el-border-color);

        .vti__dropdown {
          padding: 0.5rem;

          .vti__selection {

            .vti__flag {
              width: 1.25rem;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }

        }

        .vti__dropdown-list {
          width: 24rem;
        }

        .vti__input {
          padding-left: 2.5rem;
        }
      }

      .vue-tel-input:focus-within {
        box-shadow: unset;
        border-color: var(--light-orange);
      }
    }

    ::v-deep .el-input {
      height: 2.5rem;
      margin-bottom: 0.6rem;
      font-size: 1.3rem;

      .el-input__wrapper {
        padding: 0 1rem;
      }
    }

    .forget-pwd {
      font-size: 1rem;
      color: var(--dark-blue);
      text-align: end;
      margin-top: 0.4rem;
      cursor: pointer;
    }
  }

  ::v-deep .el-button {
    width: 100%;
    height: 3.4rem;
    margin-top: 1rem;
    background-color: var(--blue-1);
    color: white;
    border-radius: 9999px;
    line-height: 3.4rem;
  }

  .login-privacy {
    margin-top: 1rem;
    text-align: center;
    color: var(--dark-grey);
    font-size: 0.9rem;
    white-space: nowrap;

    a {
      color: var(--dark-blue);
      text-decoration: underline;
    }
  }
}

</style>
