import {DEATH_STATUS_DEATH, DEATH_STATUS_LIVE, SEX_MAN, SEX_UNKNOWN, SEX_WOMAN} from "@/utils/constants";

export class BasePersonInfo {

    constructor(obj) {
        this.family_tree_id = obj && obj.family_tree_id || '';
        this.surname = obj && obj.surname || '';
        this.name = obj && obj.name || '';
        this.death = obj && (obj.death == DEATH_STATUS_LIVE || obj.death == DEATH_STATUS_DEATH) ? parseInt(obj.death) : DEATH_STATUS_LIVE;
        this.sex = obj && (obj.sex == SEX_MAN || obj.sex == SEX_WOMAN || obj.sex == SEX_UNKNOWN) ? parseInt(obj.sex) : SEX_MAN;
        this.zi = obj && obj.zi || '';
        this.hao = obj && obj.hao || '';
        this.shihao = obj && obj.shihao || '';
        this.birth_day = obj && obj.birth_day || '';
        this.birth_address = obj && obj.birth_address ? obj.birth_address : '';
        this.death_day = obj && obj.death_day ? obj.death_day : '';
        this.death_address = obj && obj.death_address ? obj.death_address : '';
    }
}
