import request from '@/utils/request'

/**
 * 寻根第一步
 * @param data
 * @returns {*}
 */
export function rootsSearchStep1(data) {
    return request({
        url: 'api/web/roots/search/step-1',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 寻根第二步
 * @param data
 * @returns {*}
 */
export function rootsSearchStep2(data) {
    return request({
        url: 'api/web/roots/search/step-2',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 寻根第三步
 * @param data
 * @returns {*}
 */
export function rootsSearchStep3(data) {
    return request({
        url: 'api/web/roots/search/step-3',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 寻根第四步
 * @param data
 * @returns {*}
 */
export function rootsSearchStep4(data) {
    return request({
        url: 'api/web/roots/search/step-4',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 直接寻根
 * @param data
 * @returns {*}
 */
export function rootsSearch(data) {
    return request({
        url: 'api/web/roots/search',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 提交寻根需求
 * @param data
 * @returns {*}
 */
export function rootsApply(data) {
    return request({
        url: 'api/web/roots/apply',
        method: 'post',
        data,
        needAccessToken: true
    })
}
