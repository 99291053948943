import store from "@/store";
import md5 from "js-md5";
import { ElMessageBox } from 'element-plus'
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, MESSAGE_ICON_NODATA} from "@/utils/constants";

let util = {};

util.jsonObjectContainsKey = function (target, key) {
    if (typeof target !== 'object' || target == null || typeof key !== 'string') return false;
    return Object.keys(target)
        .some(k => (k === key) || util.jsonObjectContainsKey(target[k], key));
};

util.propertiesValueSet = function (source, target) {
    for (let key in source) {
        if (util.jsonObjectContainsKey(target, key)) {
            target[key] = source[key];
        }
    }
};

/**
 * 获取签名
 * @param params
 * @returns {*}
 */
util.getSign = function(params, needAccessToken = false) {
    // 获取对象的所有键，并按照 ASCII 码值递增排序
    const sortedKeys = Object.keys(params).sort();
    let signString = '';

    // 遍历排序后的键
    // sortedKeys.filter(key => params[key] != '' && params[key] != null).forEach(key => {
    //     signString += key + '=' + params[key] + '&';
    // });
    sortedKeys.forEach(key => {
        signString += key + '=' + params[key] + '&';
    });
    signString = signString.substring(0, signString.length - 1) + (needAccessToken ? store.getters.getToken : process.env.VUE_APP_BASE_TOKEN)
    // console.log('签名字符串', signString)
    return md5(signString);
}

/**
 * 通用弹窗
 * @param t 多语言
 * @param type 用那个类型图片, 1: 通用，2 加载失败 3 没有数据
 * @param text1
 * @param text2
 */
util.commonAlert = function (t, type = MESSAGE_ICON_COMMON, text1, text2) {
    return new Promise((resolve, reject) => {
        // 消息弹窗确认
        const messageIconUrl = type == MESSAGE_ICON_ERROR ? require('@/assets/images/common/message_icon_error_load.svg') : type == MESSAGE_ICON_NODATA ? require('@/assets/images/common/message_icon_nodata.svg') : require('@/assets/images/common/message_icon_common.svg');
        ElMessageBox.alert(`<div class="message-info"><div>${text1}</div><div>${text2}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: t('我知道了'),
            callback: (action) => {
                resolve()
            }
        })
    })
}

export default util
