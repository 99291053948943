<template>
  <div class="genealogy-detail-content pb-12">
    <div class="bread-crumbs-bg max-lg:px-4">
      <el-breadcrumb :separator-icon="ArrowRight" class="bread-crumbs mx-auto text-base">
        <el-breadcrumb-item :to="{ path: '/genealogy' }">{{$t('www.谱牒档案')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/genealogy-list', query: {surname_id: bookInfo.book.surname_id} }">{{bookInfo.book.surname}}{{ $t('氏家谱列表') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('家谱简介') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail-content px-4 lg:px-0 mx-auto mt-10">
      <!--家谱概览-->
      <div class="genealogy-overview flex gap-x-8 max-lg:flex-wrap">
        <div class="lg:flex-none w-56 relative max-lg:mx-auto">
          <img class="w-full h-auto" src="@/assets/images/common/book.jpg">
          <div class="book-name"><div>{{ bookInfo.book.book_name }}</div></div>
        </div>
        <div class="lg:flex-1 pt-6">
          <div class="lg:flex justify-between items-end">
            <div class="text-[var(--dark-blue)] text-3xl">{{ bookInfo.book.book_name }}</div>
            <div class="flex items-center gap-x-2">
              <el-icon class="text-[var(--middle-grey)] w-5 h-5"><Location /></el-icon>
              {{ bookInfo.book.province + bookInfo.book.city + bookInfo.book.area }}
            </div>
          </div>
          <el-divider class="mt-2 mb-8"/>
          <el-descriptions class="basic-info "
                           :column="1">
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">[{{ $t('正题名') }}]</div>
              </template>
              <span>{{ bookInfo.book.book_name }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">[{{ $t('姓氏') }}]</div>
              </template>
              <span>{{ bookInfo.book.surname }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">[{{ $t('修撰者') }}]</div>
              </template>
              <span>{{ bookInfo.book.author }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">[{{ $t('www.摘要') }}]</div>
              </template>
              <span v-html="bookInfo.book.remark"></span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <!--先祖名人-->
      <div class="w-full mt-10 px-4">
        <div class="flex gap-x-2 justify-start items-center text-[var(--dark-blue)] text-xl">
          <img src="@/assets/images/common/title_icon.svg" class="w-6 h-6">
          <span>{{ $t('www.先祖名人') }}</span>
        </div>

        <div class="rounded-2xl bg-white px-4 py-8 mt-4">
          <el-table class="table" :data="bookInfo.celebrities" :header-cell-style="{background: 'var(--grey-2)', color: 'black'}" style="width: 100%">
            <el-table-column :label="$t('姓名')" align="center" width="120">
              <template #default="scope">
                {{scope.row.surname + scope.row.name}}
              </template>
            </el-table-column>
            <el-table-column :label="$t('姓氏')" prop="surname" align="left" width="100"/>
            <el-table-column :label="$t('描述')" prop="detail" align="left">
              <template #default="scope">
                <div class="truncate-3-lines" :title="scope.row.detail">{{scope.row.detail}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!--相关家谱-->
      <div class="w-full mt-8 px-4">
        <div class="flex gap-x-2 justify-start items-center text-[var(--dark-blue)] text-xl">
          <img src="@/assets/images/common/title_icon.svg" class="w-6 h-6">
          <span>{{ $t('相关家谱') }}</span>
        </div>

        <div class="rounded-2xl bg-white px-4 py-8 mt-4">
          <el-table class="table" :data="bookInfo.like" :header-cell-style="{background: 'var(--grey-2)', color: 'black'}" style="width: 100%">
            <el-table-column :label="$t('谱名')" prop="book_name" align="left" :min-width="isMobileDevice ? '70' : '200'"/>
            <el-table-column :label="$t('姓氏')" prop="surname" align="left" :min-width="isMobileDevice ? '50' : '100'"/>
            <el-table-column :label="$t('谱籍地')" align="left"  :min-width="isMobileDevice ? '70' : '200'">
              <template #default="scope">
                <div>{{scope.row.province + scope.row.city + scope.row.area}}</div>
              </template>
            </el-table-column>
            <el-table-column v-if="!isMobileDevice" :label="$t('修撰者')" prop="author" align="left" min-width="150"/>
            <el-table-column :label="$t('操作')" align="center"  :min-width="isMobileDevice ? '70' : '120'">
              <template #default="scope">
                <el-button type="text"><a :href="'/genealogy-detail?book_id=' + scope.row.id" target="_blank">{{$t('查看详情')}}</a></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {reactive} from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
import util from '@/utils/util';
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, MESSAGE_ICON_NODATA, SUCCESS_CODE} from "@/utils/constants";
import {getBookInfo} from "@/api/genealogy";

export default {
  name: "GenealogyDetail",
  setup() {
    // 家谱详情
    const bookInfo = reactive({
      book: {},
      celebrities: [],
      like: []
    })
    return {
      ArrowRight: ArrowRight,
      bookInfo
    }
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        let book_id = parseInt(this.$route.query['book_id']);
        if (!book_id) {
          util.commonAlert(this.$t, MESSAGE_ICON_NODATA, this.$t('user.非常抱歉'), this.$t('未查询到对应家谱')).then(() => {
            // 跳转至谱牒档案页
            this.$router.push('/genealogy');
          })
          return;
        }
        this.getBookInfo(book_id);
      }
    },
  },
  methods: {
    getBookInfo(bookId) {
      getBookInfo({id: bookId}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.bookInfo.book = res.data.book;
          this.bookInfo.celebrities = res.data.celebrities;
          this.bookInfo.like = res.data.like;
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('获取家谱详情失败'));
      })
    }
  }
}
</script>

<style scoped lang="scss">
.genealogy-detail-content {
  .bread-crumbs-bg {
    padding-top: calc(var(--header-height) + 1rem);
    background-color: var(--dark-grey);
    padding-bottom: 1rem;

    ::v-deep .bread-crumbs {
      width: var(--common-content-width);
      max-width: 100%;

      .el-breadcrumb__inner {
        color: white;
      }

      .el-breadcrumb__separator.el-icon {
        color: white;
      }
    }
  }

  .detail-content {
    width: var(--common-content-width);
    max-width: 100%;

    .genealogy-overview {

      .book-name {
        position: absolute;
        top: 1rem;
        left: 1rem;
        padding: 0.15rem;
        background-color: white;

        &>div {
          border: 0.14rem solid var(--darker-blue);
          text-align: center;
          padding: 0.875rem 0.6rem;
          font-size: 1.25rem;
          line-height: 1.2;
          width: 2.8rem;
          height: 100%;
          overflow: hidden;
        }
      }

      .el-icon svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      .el-divider--horizontal {
        border-top: 0.125rem var(--grey-2) var(--el-border-style);
      }


      ::v-deep .basic-info {

        .el-descriptions__body {
          background-color: transparent;
        }

        .basic-info-label {
          display: inline-block;
          width: 5rem;
          text-align: justify;
          text-align-last: justify;
          position: relative;
          color: black;
          font-weight: 500;
        }

        .basic-info-label::after {
          content:'：';
          position: absolute;
          top: 0;
          right: -1rem;
        }

        tr {
          display: flex;
          gap: 1rem;

          .el-descriptions__cell {
            width: 100%;
            vertical-align: top;
            display: flex;

            .el-descriptions__content {
              color: black;
            }
          }
        }

        .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis; /* 显示省略号 */
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

  }
}

::v-deep .el-table {
  /* 适配移动端的表格样式 */
  @media screen and (max-width: 959.98px) {
    & {
      overflow-x: auto;
    }
  }

  .el-table__cell {
    border-bottom: 0.06rem dotted var(--light-grey);
  }
}
</style>