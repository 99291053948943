<template>
  <router-view v-slot="{ Component }" :class="{en: currentLang == 'en'}">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  computed: {
    currentLang() {
      return this.$store.getters.lang;
    }
  },
  created() {
    this.$i18n.locale = this.currentLang == 'zh-CN' ? 'zh' : this.currentLang == 'zh-TW' ? 'tw' : 'en';
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
