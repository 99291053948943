import request from '@/utils/request'

/**
 * 创建家谱树
 * @param data
 * @returns {*}
 */
export function treeCreate(data) {
    return request({
        url: 'api/web/tree/create',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 更新家谱树信息
 * @param data
 * @returns {*}
 */
export function treeUpdate(data) {
    return request({
        url: 'api/web/tree/update',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 删除家谱树
 * @param params
 * @returns {*}
 */
export function treeDel(params) {
    return request({
        url: 'api/web/tree/del',
        method: 'get',
        params: params,
        needAccessToken: true
    })
}

/**
 * 获得家谱树信息
 * @param params
 * @returns {*}
 */
export function treeInfo(params) {
    return request({
        url: 'api/web/tree/info',
        method: 'get',
        params: params,
        needAccessToken: true
    })
}

/**
 * 初始化家谱树节点
 * @param data
 * @returns {*}
 */
export function treeNodeInit(data) {
    return request({
        url: 'api/web/tree/node/init',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 根据用户ID 获得用户的所有家谱列表
 * @returns {*}
 */
export function getSelfTreeList() {
    return request({
        url: 'api/web/tree/list',
        method: 'post',
        needAccessToken: true
    })
}

/**
 * 获得家谱树子节点(默认/点击加载)
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTreeNodeList(params) {
    return request({
        url: 'api/web/tree/node/list',
        method: 'get',
        params: params,
        needAccessToken: true
    })
}

/**
 * 创建树节点
 * @param data
 * @returns {*}
 */
export function createTreeNode(data) {
    return request({
        url: 'api/web/tree/node/create',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 获得家谱树父节点(点击加载)
 * @param params
 * @returns {*}
 */
export function getTreeNodeParentList(params) {
    return request({
        url: 'api/web/tree/node/parent/list',
        method: 'get',
        params: params,
        needAccessToken: true
    })
}

/**
 * 获取人物基础信息
 * @param data
 * @returns {*}
 */
export function getPersonInfo(data) {
    return request({
        url: 'api/web/person/info',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 更新人物基础信息
 * @param data
 * @returns {*}
 */
export function updatePersonInfo(data) {
    return request({
        url: 'api/web/person/update',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 人物个人页面
 * @param data
 * @returns {*}
 */
export function getPersonInfoAll(data) {
    return request({
        url: 'api/web/person/info/all',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 添加人物事件
 * @param data
 * @returns {*}
 */
export function addEvent(data) {
    return request({
        url: 'api/web/person/event/add',
        method: 'post',
        data,
        needAccessToken: true
    })
}

/**
 * 更新人物事件
 * @param data
 * @returns {*}
 */
export function updateEvent(data) {
    return request({
        url: 'api/web/person/event/update',
        method: 'post',
        data,
        needAccessToken: true
    })
}