<template>
  <div class="article-container mx-auto flex flex-nowrap pb-10 gap-x-8 px-4">
    <div class="article-now grow">
      <div class="title">{{ articleInfo.title }}</div>
      <div class="subTitle mt-2">{{ articleInfo.introduction }}</div>
      <div class="time mt-6">{{ articleInfo.updated_at }}</div>
      <el-divider class="mb-1 mt-4"></el-divider>
      <img class="my-6" :src="articleInfo.image_url">
      <div class="contain-content" v-html="articleInfo.content">
      </div>
      <div class="pagination my-8 flex justify-center items-center gap-x-6">
        <img class="w-10 h-10 cursor-pointer" src="@/assets/images/news/back.svg" @click="prevNewsDetail()">
        <el-button class="next-btn h-12 w-28" @click="nextNewsDetail()">{{$t('下一篇')}}<el-icon><right/></el-icon></el-button>
      </div>
    </div>

    <!--右侧热门文章-->
    <div v-if="articleInterest" class="hot-article hidden lg:block">
      <div class="article-title">
        <img src="@/assets/images/common/title_icon.svg" alt="" class="inline-block">
        <span>{{$t('热门文章')}}</span>
      </div>
      <div class="article-list mt-4">
        <div class="article-item" v-for="(item,index) in articleList" :key="index">
          <div class="article-item-img">
            <img :src="item.image_square_url" alt="">
          </div>
          <div class="article-item-description mt-4">
            <div class="title">{{item.title}}</div>
            <div class="roots-text mt-2 mb-8 truncate-3-lines"><span>{{item.introduction}}</span></div>
            <div class="time">{{ $moment(item.created_at).format('YYYY-MM-DD') }}</div>
            <el-button round class="search-button" @click="goToNewsDetail(item.id)">{{$t('了解详情')}} ></el-button>
          </div>
        </div>
      </div>
      <div class="hot-pagination flex flex-nowrap">
        <div class="flex items-center justify-center cursor-pointer" @click="articleInterest = false;"><img class="w-6 h-6 mr-2" src="@/assets/images/news/no_interest.svg">{{$t('不感兴趣')}}</div>
        <div class="flex items-center justify-center cursor-pointer" @click="changeNews"><img class="w-6 h-6 mr-2" src="@/assets/images/news/change.svg">{{$t('换一批')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {getArticleInfo, getArticleList} from "@/api/news";
import {SUCCESS_CODE} from "@/utils/constants";

export default {
  name: "NewsDetail",
  setup(){
    // 当前文章ID
    const activeArticleId = ref(0);
    // 文章详情
    const articleInfo = ref({});

    // 对热门文章是否感兴趣
    const articleInterest = ref(true);

    // 请求文章列表参数
    const queryParams = ref({
      page: 1,
      limit: 3,
    });

    // 热门文章列表
    const articleList = ref([]);

    // 文章总数
    const totalCount = ref(0);
    // 文章总页数
    const totalPage = ref(0);

    // 文章访问历史
    const viewArticleList = ref([]);

    return {
      articleInfo,
      articleInterest,
      queryParams,
      articleList,
      totalCount,
      totalPage,
      viewArticleList,
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        let newsId = parseInt(this.$route.query['news-id'])
        if (newsId) {
          this.activeArticleId = newsId;
          this.viewArticleList.push(newsId);
          // 获取文章详情
          this.getArticleInfo(newsId);
        }
      }
    }
  },
  created() {
    // 获取热门文章列表
    this.getArticleList(this.queryParams);
  },
  methods: {
    /**
     * 获取新闻详情
     * @param newsId
     */
    getArticleInfo(newsId) {
      getArticleInfo({id: newsId}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.articleInfo = res.data;
        }
      }).catch(error => {

      })
    },
    /**
     * 获取文章列表
     * @param page
     */
    getArticleList(queryParams) {
      let params = {
        id: 4,
        ...queryParams
      }
      getArticleList(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.articleList = res.data.list.map(item => {
            item.text = '4月10日，2024“中国寻根之旅”春令营-遇见河南.根亲营开营仪式在河南郑州举行，来自澳大利亚、新西兰、泰国、印尼的45名年根亲营开营仪式在河南郑州举行，来自澳大利亚、新西兰、泰国、印尼的45名年';
            return item;
          });
          this.totalCount = res.data.total_count;
          this.totalPage = res.data.total_page;

          if (!this.activeArticleId) {
            this.updateRouteParams(this.articleList[0].id);
          }
        }
      }).catch(error => {

      })
    },
    /**
     * 跳转至新闻详情页
     * @param newsId
     */
    goToNewsDetail(newsId) {
      const route = this.$router.resolve({
        path: '/news-detail',
        query: {'news-id': newsId}
      });
      window.open(route.href, '_blank');
    },
    /**
     * 换一批文章
     */
    changeNews() {
      this.queryParams.page + 1 <= this.totalPage ? this.queryParams.page += 1 : this.queryParams.page = 1;
      this.getArticleList(this.queryParams);
    },
    /**
     * 上一篇文章
     */
    prevNewsDetail() {
      let index = this.viewArticleList.indexOf(this.activeArticleId);
      if (this.viewArticleList.length <= 1 || index < 1) {
        this.$message({
          message: this.$t('无上一篇文章'),
          type: 'warning',
        })
        return;
      }

      // 更新路由
      this.updateRouteParams(this.viewArticleList[index - 1]);
    },
    /**
     * 下一篇文章
     */
    nextNewsDetail() {
      let article = this.articleList.find(item => !this.viewArticleList.includes(item.id));
      if (!article) {
        this.$message({
          message: this.$t('暂无下一篇文章'),
          type: 'warning',
        })
        return;
      }
      // 更新路由
      this.updateRouteParams(article.id);
    },
    /**
     * 更新路由参数
     * @param newsId
     */
    updateRouteParams(newsId) {
      this.$router.push({
        path: this.$route.path, // 保持当前路径不变
        query: {'news-id': newsId} // 更新路由参数
      });
    }
  }
}
</script>

<style scoped lang="scss">
.article-container{
  width: var(--common-content-width);
  max-width: 100%;
  padding-top: calc(2rem + var(--header-height));

  .article-now{
    width: calc(100% - 22rem);

    .time{
      font-size: 1rem;
      color: var(--dark-grey);
    }
    .title{
      font-size: 3rem;
      color: var(--dark-blue);
    }
    .subTitle{
      font-size: 1.5rem;
      color: var(--dark-blue);
    }
    .contain-content{
      ::v-deep p{
        line-height: 2;
        margin-top: 2rem;
      }
      ::v-deep img{
        width: 100%;
        margin-top: 30px;
      }
    }
    .pagination{
      .el-button{
        border-radius: 8px;
      }
      .next-btn{
        background-color: var(--dark-blue);
        border-color: var(--dark-blue);
        color: var(--light-orange);
      }
    }
  }

  .hot-article{
    margin-top: 12rem;
    width: 20rem;

    .article-title{
      font-size: 1.5rem;
      color: var(--dark-blue);

      img{
        vertical-align: sub;
        height: 30px;
        margin-right: 15px;
      }
    }
    .article-list{

      .article-item{
        overflow: hidden;
        width: 100%;
        background-color: var(--grey-3);
        border-radius: 1.2rem;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 1rem 1rem 2rem;

        .article-item-description{
          position: relative;
          .title{
            font-size: 1.2rem;
          }
          .time{
            font-size: 0.875rem;
            color: var(--dark-grey);
          }
          .roots-text{
            font-size: 1rem;
            letter-spacing: 0.125rem;
            color: var(--dark-grey);
          }
          .search-button{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100px;
            height: 32px;
            background-color: transparent;
            border-color: var(--light-orange);
            color: var(--light-orange);
          }
        }
        .article-item-img{
          width: 100%;
          img{
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .hot-pagination{
      height: 4rem;
      width: 100%;
      border-radius: 35px;
      padding: 0 1.2rem;
      background-color: #f0f0f0;

      & > div{
        flex: 1;
        line-height: 4rem;
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
        &:first-child{
          color: var(--dark-grey);
        }
        &:last-child{
          color: var(--dark-blue);
        }
      }
    }
  }
}
.truncate-3-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>