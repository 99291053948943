import request from "@/utils/request";

/**
 * 家谱数据库搜索
 * @param data
 * @returns {*}
 */
export function getGenealogyBook(data) {
    return request({
        url: 'api/web/books/search',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 根据省份获得市级家谱数量
 * @param data
 * @returns {*}
 */
export function getCityBooks(data) {
    return request({
        url: 'api/web/books/city',
        method: 'post',
        data,
        // needAccessToken: true
    })
}

/**
 * 家谱详情页
 * @param data
 * @returns {*}
 */
export function getBookInfo(data) {
    return request({
        url: 'api/web/books/info',
        method: 'post',
        data,
        // needAccessToken: true
    })
}
