<template>
  <div class="pagination-content mx-auto flex justify-between items-center h-20">
    <el-button @click="goOne" type="text" class="first-box flex items-center">
      <el-icon>
        <arrow-left/>
      </el-icon>
      {{ $t('www.转到第X页',{'num':1}) }}
    </el-button>
    <div class="page-btn">
      <el-button class="prev-btn h-10 w-10 p-0" @click="goPre">
        <el-icon>
          <back/>
        </el-icon>
      </el-button>
      <el-button class="next-btn h-12 w-28" @click="goNext">{{ $t('www.下一页') }}
        <el-icon>
          <right/>
        </el-icon>
      </el-button>
    </div>
    <div class="jump">
      <div>{{ $t('www.页') }}</div>
      <div class="px-5 py-0.5 rounded-md mx-2">{{pageNum}}</div>
      <div>{{ $t('www.共X个', {'num':totalCount}) }}</div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: "Pagination",
  props: {
    pageNum: Number,
    pageSize: Number,
    totalCount: Number,
  },
  methods: {
    /**
     * 跳转至第一页
     */
    goOne() {
      this.$emit('go-one', 1);
    },
    /**
     * 跳转至前一页
     */
    goPre() {
      this.$emit('go-pre', this.pageNum - 1);
    },
    /**
     * 跳转至下一页
     */
    goNext() {
      this.$emit('go-next', this.pageNum + 1);
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-content{

  .first-box{
    color: var(--dark-blue);
    font-size: 1rem;
    line-height: 1;
    .el-icon{
      font-size: 1rem;
    }
  }
  .page-btn{
    .prev-btn{
      background-color: transparent;
      border-color: var(--dark-blue);
      color: var(--dark-blue);
      border-radius: 0.4rem;
      border-width: 0.06rem;
    }
    .next-btn{
      border-radius: 0.4rem;
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
      color: var(--light-orange);
      padding: 0.06rem 2rem;
    }

    ::v-deep .el-button+.el-button {
      margin-left: 1.2rem;
    }
  }

  .jump{
    flex-wrap: nowrap;
    align-items: center;
    display:flex;
    color:var(--dark-grey);

    &>div:nth-child(2) {
      border: 0.06rem solid var(--dark-grey);
    }
  }
}
</style>
