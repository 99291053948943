// 请求返回状态
export const SUCCESS_CODE = '10000';
export const ERROR_CODE = '20000';
export const ERROR_SIGN = '20001';
export const ERROR_LOG_IN_SIGN = '20004';

// 性别
export const SEX_MAN = 1;
export const SEX_WOMAN = 0;
export const SEX_UNKNOWN = 2;

// 数据状态
export const STATUS_DELETE = 0;
export const STATUS_NORMAL = 1;

// 生存状态
export const DEATH_STATUS_LIVE = 0;
export const DEATH_STATUS_DEATH = 1;

// 加载失败消息类型
export const MESSAGE_ICON_COMMON = 1;
export const MESSAGE_ICON_ERROR = 2;
export const MESSAGE_ICON_NODATA = 3;

// 事件类型
export const EVENT_TYPE_BRIEF_HISTORY = 14;

// 加入节点类型
export const NODE_TYPE_FATHER = 1;
export const NODE_TYPE_MOTHER = 2;
export const NODE_TYPE_CHILD = 3;
export const NODE_TYPE_SPOUSE = 4;