import request from '@/utils/request';
import Cookies from 'js-cookie';

const TOKEN_KEY = 'token'

export function getToken() {
    return Cookies.get(TOKEN_KEY) || null;
}

export function setToken(token) {
    return Cookies.set(TOKEN_KEY, token, {path: '/'})
}

export function removeToken() {
    return Cookies.remove(TOKEN_KEY, {path: '/'})
}

const LANG_KEY = 'lang';

export function getLangCookie() {
    return Cookies.get(LANG_KEY) || null;
}

export function setLangCookie(lang) {
    return Cookies.set(LANG_KEY, lang, {path: '/'})
}

/**
 * 用户注册
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function userRegister(data) {
    return request({
        url: 'api/web/user/register',
        method: 'post',
        data
    })
}

/**
 * 获取验证码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSMS(data) {
    return request({
        url: 'api/web/user/register/getSms',
        method: 'post',
        data
    })
}

/**
 * 账号密码登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function loginPwd(data) {
    return request({
        url: 'api/web/user/login/pwd',
        method: 'post',
        data
    })
}

/**
 * 获取用户信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserInfo(config) {
    return request({
        url: 'api/web/user/info',
        method: 'GET',
        needAccessToken: true,
        ...config
    })
}

/**
 * 退出登录
 * @returns {Promise<AxiosResponse<any>>}
 */
export function userLogout() {
    return request({
        url: 'api/web/user/logout',
        method: 'POST',
        needAccessToken: true
    })
}

/**
 * 设置语言环境
 * @param data
 * @returns {*}
 */
export function langSet(data) {
    return request({
        url: 'api/web/user/lang/set',
        method: 'POST',
        data,
        needAccessToken: true
    })
}

/**
 * 更新用户信息
 * @param data
 * @returns {*}
 */
export function updateUserInfo(data) {
    return request({
        url: 'api/web/user/info/save',
        method: 'POST',
        data,
        needAccessToken: true
    })
}

/**
 * 获取登录验证码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLoginSMS(data) {
    return request({
        url: 'api/web/user/login/getSms',
        method: 'POST',
        data,
    })
}

/**
 * 短信验证码登录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function loginSMS(data) {
    return request({
        url: 'api/web/user/login/sms',
        method: 'POST',
        data,
    })
}

/**
 * 重置密码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function userResetPwd(data) {
    return request({
        url: 'api/web/user/pwd/reset',
        method: 'POST',
        data,
    })
}

