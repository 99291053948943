import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout/index"
import Index from "@/views/home/Index";
import Ancestry from "@/views/ancestry/Ancestry";
import Genealogy from "@/views/genealogy/Genealogy";
import Surname from "@/views/surname/Surname";
import Login from "@/views/auth/Login";
import Register from "@/views/auth/Register";
import Profile from "@/views/profile/Profile";
import FamilyTree from "@/views/familyTree/FamilyTree";
import AncestryDetail from "@/views/ancestry/AncestryDetail";
import News from "@/views/news/News";
import NewsDetail from "@/views/news/NewsDetail";
import Auth from "@/views/auth/Auth";
import ResetPwd from "@/views/auth/ResetPwd";
import PersonalData from "@/views/familyTree/PersonalData";
import store from '../store'
import UserServiceAgreement from "@/views/auth/UserServiceAgreement";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy";
import GenealogyList from "@/views/genealogy/GenealogyList";
import GenealogyDetail from "@/views/genealogy/GenealogyDetail";

const routes = [
    {
        path: '/',
        component: Layout,
        // redirect: isMobileDevice() ? 'ancestry' : 'index',
        redirect: 'index',
        children: [
            {
                path: 'index',
                component: Index
            },
            {
                path: 'ancestry',
                component: Ancestry,
                meta: {
                    backgroundColor: 'var(--grey-3)'
                }
            },
            {
                path: 'ancestry-detail',
                component: AncestryDetail
            },
            {
                path: 'genealogy',
                component: Genealogy
            },
            {
                path: 'genealogy-list',
                component: GenealogyList,
                meta: {
                    backgroundColor: 'var(--lighter-grey)'
                }
            },
            {
                path: 'genealogy-detail',
                component: GenealogyDetail,
                meta: {
                    backgroundColor: 'var(--lighter-grey)'
                }
            },
            {
                path: 'surname',
                component: Surname
            },
            {
                path: 'family-tree',
                component: FamilyTree
            },
            {
                path: 'family-tree-person',
                component: PersonalData
            },
            {
                path: 'profile',
                component: Profile,
                meta: {
                    backgroundColor: 'var(--pale-blue)'
                }
            },
            {
                path: 'news',
                component: News
            },
            {
                path: 'news-detail',
                component: NewsDetail
            },
            {
                path: 'auth',
                component: Auth,
                redirect: '/auth/login',
                children: [
                    {
                        path: 'login',
                        component: Login
                    },
                    {
                        path: 'register',
                        component: Register
                    },
                    {
                        path: 'reset-pwd',
                        component: ResetPwd
                    }
                ]
            },
            // 用户服务政策
            {
                path: 'user-service-agreement',
                component: UserServiceAgreement,
                meta: {
                    backgroundColor: 'var(--grey-3)'
                }
            },
            // 隐私协议
            {
                path: 'privacy-policy',
                component: PrivacyPolicy,
                meta: {
                    backgroundColor: 'var(--grey-3)'
                }
            }
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // If there's a saved position, return to it
        if (savedPosition) {
            return savedPosition;
        }
        // Otherwise, scroll to top
        return { top: 0 };
    },
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;
    // 如果当前已经登录，则不能跳转登录注册页,默认跳转回原页面或者首页
    if (to.fullPath.startsWith('/auth') && isAuthenticated) {
        next('/');
    } else if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        // 跳转到登录页面
        next('/auth/login');
    } else {
        // 检查是否需要重新获取用户信息
        if (isAuthenticated && !store.state.userInfo) {
            // 调用获取用户信息的方法
            store.dispatch('fetchUserInfo');
        }
        next();
    }
});

function isMobileDevice() {
    return window.innerWidth < 960;
}

export default router
