<template>
  <div class="common-search bg-white" :style="{width: commonSearchWidth}">
    <el-select
        v-model="chosenSurnameInfo.surname_id"
        filterable
        :placeholder="placeHolder" class="grow" @change="changeSearchValue"
    >
      <template #prefix>
        <el-icon>
          <Search/>
        </el-icon>
      </template>
      <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
        <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
      </template>
    </el-select>
    <el-button class="primary-search-button min-w-28" round @click="search">{{ buttonValue }}</el-button>
  </div>
</template>

<script>
import {ref} from 'vue'
import {getPubSurname} from "@/api/common";
import {SUCCESS_CODE} from "@/utils/constants";

export default {
  name: "CommonSearch",
  props: {
    // 搜索值
    searchValue: String,
    // 搜索值对应的id
    searchId: String,
    // 占位值
    placeHolder: String,
    // 按钮显示的内容，默认是搜索
    buttonValue: String,
    // 组件宽度
    commonSearchWidth: String
  },
  setup() {
    const chosenSurnameInfo = ref({});
    const surnameOptions = ref([]);

    return {
      chosenSurnameInfo,
      surnameOptions
    }
  },
  created() {
    // 获取姓氏筛选列表
    this.getRemoteSurname();
  },
  mounted() {
    this.chosenSurnameInfo = {
      id: this.searchId,
      surname: this.searchValue
    };
  },
  methods: {
    changeSearchValue(value) {
      this.chosenSurnameInfo.surname = this.surnameOptions.find(item => item.id == value).surname;
      this.$emit("update:searchValue", this.chosenSurnameInfo.surname)
      this.$emit("update:searchId", value)
    },
    /**
     * 获取姓氏筛选列表
     */
    getRemoteSurname() {
      getPubSurname().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.surnameOptions = res.data;
        } else {
          this.surnameOptions = [];
        }
      }).catch(error => {
        this.surnameOptions = [];
      })
    },
    surnameSearch(queryString) {
      return this.surnameOptions.filter(item => item.alias.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
    },
    search() {
      let findSurnameInfo = this.surnameOptions.find(item => item.surname == this.chosenSurnameInfo.surname);
      this.chosenSurnameInfo.id = findSurnameInfo ? findSurnameInfo.id : null;
      this.$emit('click-button', this.chosenSurnameInfo)
    }
  }
}
</script>

<style scoped lang="scss">
.common-search {
  border: 0.06rem solid var(--grey-5);
  height: 3.5rem;
  display: flex;
  border-radius: 9999px;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  &:hover {
    border: 0.06rem solid var(--light-orange);
  }

  ::v-deep .el-autocomplete {
    border: none;
    height: 100%;

    .el-input {
      height: 100%;
      width: 100%;

      .el-input__wrapper {
        box-shadow: none;
        border-radius: 9999px;
        background-color: transparent;
        padding: 0.06rem 1rem;
        height: 100%;
        font-size: 1.125rem;

        .el-input__prefix-inner>:last-child {
          margin-right: 1rem;
        }
      }
    }
  }

  ::v-deep .el-select {
    .el-select__wrapper {
      height: 100%;
      border-radius: 9999px;
      box-shadow: none;
      background-color: transparent;
      font-size: 1.125rem;

      .el-select__suffix {
        display: none;
      }
    }
  }
}
</style>
