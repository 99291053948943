import G6 from "@antv/g6";
import decorate_blue from '@/assets/images/family-tree/decorate_blue.svg';
import decorate_red from '@/assets/images/family-tree/decorate_red.svg';
import self_active from '@/assets/images/family-tree/self_active.svg';
import self_unactive from '@/assets/images/family-tree/self_unactive.svg';
import spouse_active from '@/assets/images/family-tree/spouse_active.svg';
import spouse_unactive from '@/assets/images/family-tree/spouse_unactive.svg';
import son_active from '@/assets/images/family-tree/son_active.svg';
import son_unactive from '@/assets/images/family-tree/son_unactive.svg';
import daughter_active from '@/assets/images/family-tree/daughter_active.svg';
import daughter_unactive from '@/assets/images/family-tree/daughter_unactive.svg';
import children_expand from '@/assets/images/family-tree/children_expand.svg';
import children_collapse from '@/assets/images/family-tree/children_collapse.svg';
import family_spouses from '@/assets/images/family-tree/family_spouses.svg';
import {SEX_MAN, SEX_WOMAN} from "@/utils/constants";

export const NODE_WIDTH = 240;
export const CHILD_SHAPE_HEIGHT = 55;
export const NODE_SHAPE_HEIGHT = 70;
export const MIDDLE_OPERATE_BAR_HEIGHT = 30;
export const LAST_OPERATE_BAR_HEIGHT = 40;

export const registerG6CustomNode = (t) => {

    G6.registerNode('tree-node', {
        options: {
            size: [NODE_WIDTH, 170]
        },
        draw: (cfg, group) => {

            // 节点高度
            let nodeHeight = NODE_SHAPE_HEIGHT * 2 + MIDDLE_OPERATE_BAR_HEIGHT;
            if (cfg.dataType == 'newAdd') { // 加入父亲节点高度
                nodeHeight = NODE_SHAPE_HEIGHT;
            } else if (cfg.children && cfg.children.length > 0 && cfg.childrenExpand) { // 有子女且已展开的节点
                nodeHeight = NODE_SHAPE_HEIGHT * 2 + MIDDLE_OPERATE_BAR_HEIGHT + cfg.children.length * CHILD_SHAPE_HEIGHT + LAST_OPERATE_BAR_HEIGHT;
            }

            // 节点的主背景
            const shape = group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: NODE_WIDTH,
                    height: nodeHeight,
                    radius: 10,
                    fill: cfg.childrenExpand ? '#d2d5df' : '#ffffff',
                    lineWidth: 1.5,
                    stroke: '#ffffff'
                },
                name: 'bg'
            });

            let shapeBBox = shape.getBBox();

            // cp节点背景
            group.addShape('rect', {
                attrs: {
                    x: 0,
                    y: 0,
                    width: NODE_WIDTH,
                    height: cfg.dataType != 'newAdd' ? 140 : NODE_SHAPE_HEIGHT,
                    radius: 10,
                    fill: '#ffffff',
                },
                name: 'couple-bg'
            });

            // 男性左侧蓝色装饰
            group.addShape('image', {
                attrs: {
                    x: -4,
                    y: 14,
                    width: 4,
                    height: 42,
                    img: decorate_blue
                },
                name: 'decorate-blue'
            })

            // 节点头像
            group.addShape('image', {
                attrs: {
                    x: 20,
                    y: 15,
                    width: 40,
                    height: 40,
                    img: cfg.dataType != 'newAdd' && cfg.sex == SEX_MAN ? self_active : cfg.dataType != 'newAdd' ? spouse_active : self_unactive,
                },
                name: 'male-avatar',
                capture: true
            });


            // 如果男性数据存在，正常显示；否则显示加入父亲
            if (cfg.dataType != 'newAdd') {
                // 女性左侧蓝色装饰
                cfg.dataType != 'newAdd' ? group.addShape('image', {
                    attrs: {
                        x: -4,
                        y: 84,
                        width: 4,
                        height: 42,
                        img: decorate_red
                    },
                    name: 'decorate-red'
                }) : null;

                // 男女分割线
                cfg.dataType != 'newAdd' ? group.addShape('rect', {
                    attrs: {
                        x: 10,
                        y: 70,
                        width: 220,
                        height: 2,
                        fill: '#ebecf1',
                    },
                    name: 'couple-devider'
                }) : null;

                // 子女展示分割线
                cfg.dataType != 'newAdd' && !cfg.childrenExpand ? group.addShape('rect', {
                    attrs: {
                        x: 10,
                        y: 140,
                        width: 220,
                        height: 2,
                        fill: '#ebecf1',
                    },
                    name: 'children-divider'
                }) : null;

                // 无子女时加入子女
                cfg.dataType != 'newAdd' && (!cfg.children || cfg.children.length <= 0) ? group.addShape('text', {
                    attrs: {
                        x: 230,
                        y: 155,
                        text: '+加入子女',
                        textAlign: "right",
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 14,
                        cursor: 'pointer'
                    },
                    name: 'add-children'
                }) : null;

                // 设置名称省略显示
                let maleNameText = cfg.surname + cfg.name;
                let maxLength = /[a-zA-Z]/.test(maleNameText.substring(0, 4)) ? 8 : 4; // 设置最大显示长度
                let truncatedMaleNameText = maleNameText.length > maxLength ? maleNameText.slice(0, maxLength) + '...' : maleNameText;

                // 姓名
                const maleName = group.addShape('text', {
                    attrs: {
                        x: 75,
                        y: 28,
                        text: truncatedMaleNameText,
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#141f56',
                        fontSize: 16,
                        cursor: 'pointer',
                    },
                    name: 'male-name'
                });

                // 出生年
                const maleBirthDay = group.addShape('text', {
                    attrs: {
                        x: 75,
                        y: 46,
                        text: cfg.birth_day ? cfg.birth_day.substring(0, 4) + '年' : '未知',
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 12
                    },
                    name: 'male-birth-year'
                });

                // 出生年盒子
                const maleBirthDayBBox = maleBirthDay.getBBox();

                // 是否在世
                group.addShape('text', {
                    attrs: {
                        x: maleBirthDayBBox.maxX + 10,
                        y: 46,
                        text: cfg.death == '1' ? '死亡' : '在世',
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 12
                    },
                    name: 'male-live-status'
                });

                // 有子女时子女文字+展开图标
                cfg.dataType != 'newAdd' && (cfg.children && cfg.children.length > 0) ? group.addShape('text', {
                    attrs: {
                        x: 25,
                        y: 155,
                        text: '子女',
                        textAlign: "left",
                        textBaseline: 'middle',
                        fill: cfg.childrenExpand ? '#141f56' : '#797575',
                        fontSize: 14,
                    },
                    name: 'children'
                }) : null;
                cfg.dataType != 'newAdd' && (cfg.children && cfg.children.length > 0) && !cfg.childrenExpand ? group.addShape('image', {
                    attrs: {
                        x: 210,
                        y: 145,
                        width: 20,
                        height: 20,
                        img: children_expand,
                        cursor: 'pointer'
                    },
                    name: 'children-expand-icon'
                }) : null;
                cfg.dataType != 'newAdd' && (cfg.children && cfg.children.length > 0) && cfg.childrenExpand ? group.addShape('image', {
                    attrs: {
                        x: 210,
                        y: 145,
                        width: 20,
                        height: 20,
                        img: children_collapse,
                        cursor: 'pointer'
                    },
                    name: 'children-collapse-icon'
                }) : null;

                // 处理节点中子女的展示
                cfg.childrenExpand ? handleChildrenShape(cfg, group) : null;

            } else {

                group.addShape('text', {
                    attrs: {
                        x: 80,
                        y: 35,
                        text: t('tree.添加父亲'),
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 16,
                        cursor: 'pointer',
                    },
                    name: 'male-add'
                });

            }

            /** 配偶信息 start */
            // 空白且非父节点，直接返回
            if (cfg.dataType == 'newAdd') {
                return shape;
            }

            // 配偶头像
            group.addShape('image', {
                attrs: {
                    x: 20,
                    y: 84,
                    width: 40,
                    height: 40,
                    img: cfg.spouses && cfg.spouses[0] && cfg.spouses[0].sex == SEX_WOMAN ? spouse_active : cfg.spouses && cfg.spouses[0] ? self_active : spouse_unactive,
                },
                name: 'female-avatar'
            });

            // 如果有配偶信息，展示配偶信息；如果没有配偶信息，展示加入配偶
            if (cfg.spouses && cfg.spouses[0]) {

                // 设置名称省略显示
                let femaleNameText = cfg.spouses[0].surname + cfg.spouses[0].name;
                let maxLength = /[a-zA-Z]/.test(femaleNameText.substring(0, 4)) ? 8 : 4; // 设置最大显示长度
                let truncatedFemaleNameText = femaleNameText.length > maxLength ? femaleNameText.slice(0, maxLength) + '...' : femaleNameText;

                // 配偶姓名
                const femaleName = group.addShape('text', {
                    attrs: {
                        x: 75,
                        y: 96,
                        text: truncatedFemaleNameText,
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#141f56',
                        fontSize: 16,
                        cursor: 'pointer',
                    },
                    name: 'female-name'
                });

                // 配偶姓名盒子
                const femaleNameBBox = femaleName.getBBox();

                // 配偶出生年
                const femaleBirthDay = group.addShape('text', {
                    attrs: {
                        x: 75,
                        y: 117,
                        text: cfg.spouses[0].birth_day ? cfg.spouses[0].birth_day.substring(0, 4) + '年' : '未知',
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 12
                    },
                    name: 'female-birth-year'
                });

                // 配偶出生年盒子
                const femaleBirthDayBBox = femaleBirthDay.getBBox();

                // 是否在世
                group.addShape('text', {
                    attrs: {
                        x: femaleBirthDayBBox.maxX + 10,
                        y: 117,
                        text: cfg.spouses[0].death == '1' ? '死亡' : '在世',
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 12
                    },
                    name: 'female-live-status'
                });

                // 如果有多个配偶，显示多配偶可点击图标
                if (cfg.spouses && cfg.spouses.length > 1) {
                    group.addShape('image', {
                        attrs: {
                            x: femaleNameBBox.maxX + 10,
                            y: 86,
                            width: 18,
                            height: 18,
                            img: family_spouses,
                            cursor: 'pointer'
                        },
                        name: 'female-spouses'
                    });
                }

            } else {

                // 添加配偶
                const femaleName = group.addShape('text', {
                    attrs: {
                        x: 75,
                        y: 105,
                        text: '+加入配偶',
                        textAlign: 'left',
                        textBaseline: 'middle',
                        fill: '#797575',
                        fontSize: 16,
                        cursor: 'pointer',
                    },
                    name: 'female-add'
                });

            }
            /** 配偶信息 end */

            // 是否有children
            const hasChildren = (cfg.children && cfg.children.length > 0) || (cfg.children_ids && JSON.parse(cfg.children_ids).length);
            // 如果有子级节点，设置展开收起图标
            if (hasChildren) {
                group.addShape('marker', {
                    attrs: {
                        x: -15,
                        y: shapeBBox.maxY / 2,
                        r: 8,
                        symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
                        stroke: '#a6a6aa',
                        fill: '#d0d6df',
                        lineWidth: 2,
                        cursor: 'pointer'
                    },
                    name: 'collapse-icon',
                });
            }

            if (cfg.father_id) {
                group.addShape('marker', {
                    attrs: {
                        x: NODE_WIDTH + 20,
                        y: shapeBBox.maxY / 2,
                        r: 8,
                        symbol: cfg.fatherCollapsed ? G6.Marker.expand : G6.Marker.collapse,
                        stroke: '#a6a6aa',
                        fill: '#d0d6df',
                        lineWidth: 2,
                        cursor: 'pointer'
                    },
                    name: 'father-collapse-icon',
                });
            }

            return shape;
        },
        setState(name, value, item) {
            if (name === 'collapse') {
                const group = item.getContainer();
                const collapseIcon = group.find((e) => e.get('name') === 'collapse-icon');
                if (collapseIcon) {
                    if (!value) {
                        collapseIcon.attr({
                            symbol: G6.Marker.collapse,
                        });
                    } else {
                        collapseIcon.attr({
                            symbol: G6.Marker.expand,
                        });
                    }
                }
            } else if (name === 'father-collapse') {
                const group = item.getContainer();
                const collapseIcon = group.find((e) => e.get('name') === 'father-collapse-icon');
                if (collapseIcon) {
                    if (!value) {
                        collapseIcon.attr({
                            symbol: G6.Marker.collapse,
                        });
                    } else {
                        collapseIcon.attr({
                            symbol: G6.Marker.expand,
                        });
                    }
                }
            }
        },
    });
}

/**
 * 处理节点中子女展开时的图形
 * @param group
 */
function handleChildrenShape(cfg, group) {
    // 背景
    const childrenBgShape = group.addShape('rect', {
        attrs: {
            x: 2,
            y: NODE_SHAPE_HEIGHT * 2 + MIDDLE_OPERATE_BAR_HEIGHT,
            width: NODE_WIDTH - 4,
            height:  cfg.children.length * CHILD_SHAPE_HEIGHT + LAST_OPERATE_BAR_HEIGHT - 2,
            radius: 10,
            fill: '#f2f1f6',
        },
        name: 'children-bg'
    });

    let childrenBgShapeBBox = childrenBgShape.getBBox();

    // 遍历子女信息，处理每个子女展示要用的图形
    for(let i = 0; i < cfg.children.length; i++) {

        // 头像
        let childrenAvatarShape = group.addShape('image', {
            attrs: {
                x: 22,
                y: childrenBgShapeBBox.minY + 10 + (i * CHILD_SHAPE_HEIGHT),
                width: 35,
                height: 35,
                img: cfg.children[i].sex == SEX_MAN ? son_active : daughter_active,
            },
            name: 'children-avatar-' + i,
            capture: true
        });

        let childrenAvatarShapeBBox = childrenAvatarShape.getBBox();

        // 姓名
        group.addShape('text', {
            attrs: {
                x: 75,
                y: childrenAvatarShapeBBox.minY + 10,
                text: getEllipsisName(cfg.children[i].surname, cfg.children[i].name),
                textAlign: 'left',
                textBaseline: 'middle',
                fill: '#141f56',
                fontSize: 14,
                cursor: 'pointer',
            },
            name: 'children-name-' + i
        });

        // 出生年
        let childrenBirthDayShape = group.addShape('text', {
            attrs: {
                x: 75,
                y: childrenAvatarShapeBBox.minY + 26,
                text: cfg.children[i].birth_day ? cfg.children[i].birth_day.substring(0, 4) + '年' : '未知',
                textAlign: 'left',
                textBaseline: 'middle',
                fill: '#797575',
                fontSize: 11
            },
            name: 'children-birth-year-' + i
        });

        // 出生年盒子
        const childrenBirthDayShapeBBox = childrenBirthDayShape.getBBox();

        // 是否在世
        group.addShape('text', {
            attrs: {
                x: childrenBirthDayShapeBBox.maxX + 10,
                y: childrenAvatarShapeBBox.minY + 26,
                text: cfg.children[i].death == '1' ? '死亡' : '在世',
                textAlign: 'left',
                textBaseline: 'middle',
                fill: '#797575',
                fontSize: 11
            },
            name: 'children-live-status' + i
        });

        // 分割线
        group.addShape('rect', {
            attrs: {
                x: 10,
                y: childrenBgShapeBBox.minY + CHILD_SHAPE_HEIGHT * (i+1),
                width: 220,
                height: 2,
                fill: '#ebecf1',
            },
            name: 'children-devider-' + i
        });

        // 加入子女
        group.addShape('text', {
            attrs: {
                x: 230,
                y: childrenBgShapeBBox.maxY - 20,
                text: '+加入子女',
                textAlign: "right",
                textBaseline: 'middle',
                fill: '#797575',
                fontSize: 14,
                cursor: 'pointer'
            },
            name: 'add-children'
        });

    }
}

/**
 * 获取简化显示省略号的名字
 * @param surname
 * @param name
 */
function getEllipsisName(surname, name) {
    let NameText = surname + name;
    let maxLength = /[a-zA-Z]/.test(NameText.substring(0, 4)) ? 8 : 4; // 设置最大显示长度
    let truncatedNameText = NameText.length > maxLength ? NameText.slice(0, maxLength) + '...' : NameText;
    return truncatedNameText;
}