<template>
  <div class="ancestry-content h-screen lg:h-auto bg-[var(--pale-blue)] lg:bg-[var(--grey-3)]">
    <!--PC端顶部-->
    <div class="Roots-top relative hidden lg:block">
      <video :autoplay="!isMobileDevice" muted loop class="w-full">
        <source src="@/assets/images/ancestry/bg.mp4" type="video/mp4">
        您的浏览器不支持HTML5视频。
      </video>
      <div class="absolute w-full h-full top-0 left-0 bg-black/50">
        <div class="top-info text-center">
          <div class="Roots-content font-bold leading-tight text-white text-center">
            {{$t('root.祖辈足迹绘千秋')}}<br>
            {{$t('root.家族脉络续华章')}}
          </div>

          <div class="info-detail max-w-full px-4 relative mt-16">

            <!--已输入内容-->
            <div class="input-info absolute flex gap-x-2 pl-8">
              <div v-if="rootsSearchParams.surname && currentStep > 1">
                <span>{{ rootsSearchParams.surname }}</span>
                <el-icon class="cursor-pointer" @click="clearChoice('surname')">
                  <CircleCloseFilled/>
                </el-icon>
              </div>
              <div v-if="rootsSearchParams.province && currentStep > 2">
                <span>{{ rootsSearchParams.province }}</span>
                <el-icon class="cursor-pointer" @click="clearChoice('province')">
                  <CircleCloseFilled/>
                </el-icon>
              </div>
              <div v-if="rootsSearchParams.city && currentStep > 3">
                <span>{{ rootsSearchParams.city }}</span>
                <el-icon class="cursor-pointer" @click="clearChoice('city')">
                  <CircleCloseFilled/>
                </el-icon>
              </div>
            </div>

            <!--第一步 姓氏-->
            <div v-if="currentStep == 1" class="hidden lg:flex gap-x-4">
              <div class="search-guide">
                <span>{{ $t('www.一键寻根') }}</span>
                <el-icon class="ml-2"><Right /></el-icon>
              </div>
              <CommonSearch v-model:search-value="rootsSearchParams.surname" v-model:search-id="rootsSearchParams.surname_id"
                            :place-holder="$t('root.请输入您的姓氏')"
                            :button-value="$t('root.下一步')" class="grow" @click-button="firstStep().catch(() => {})"></CommonSearch>
            </div>

            <!--移动端第一步输入姓氏-->
            <div v-if="currentStep == 1" class="mt-10 block lg:hidden p-4 rounded-3xl bg-[var(--dark-blue)]">
              <div class="search-guide justify-normal pl-4">
                <span>{{ $t('www.一键寻根') }}</span>
                <el-icon class="ml-2">
                  <Right/>
                </el-icon>
              </div>
              <CommonSearch v-model:search-value="rootsSearchParams.surname" v-model:search-id="rootsSearchParams.surname_id"
                            :place-holder="$t('root.请输入您的姓氏')"
                            :button-value="$t('root.下一步')" class="grow" @click-button="firstStep().catch(() => {})"></CommonSearch>
            </div>

            <!--第二步-->
            <div v-if="currentStep == 2" class="common-search search-root flex rounded-full items-center">
              <el-select class="h-full"
                  v-model="rootsSearchParams.province_id"
                  filterable
                  :placeholder="$t('root.请输入祖籍地省份')" @change="getCityOptions">
                <template #prefix>
                  <el-icon><Search /></el-icon>
                </template>
                <template v-for="province in provinceOptions" :key="province.id">
                  <el-option :label="province.aname" :value="province.id"></el-option>
                </template>
              </el-select>
              <el-button class="first w-20 border-0" @click="secondStep">{{ $t('忘记了') }}</el-button>
              <el-button class="last w-20 border-0" @click="secondStep">{{ $t('下一步') }}</el-button>
            </div>

            <!--第三步-->
            <div v-if="currentStep == 3" class="common-search search-root flex rounded-full items-center">
              <el-select class="h-full"
                         v-model="rootsSearchParams.city_id"
                         filterable
                         :placeholder="$t('root.请输入祖籍地城市')">
                <template #prefix>
                  <el-icon><Search /></el-icon>
                </template>
                <template v-for="city in cityOptions" :key="city.id">
                  <el-option :label="city.aname" :value="city.id"></el-option>
                </template>
              </el-select>
              <el-button class="first w-20 border-0" @click="thirdStep">{{ $t('root.忘记了') }}</el-button>
              <el-button class="last w-20 border-0" @click="thirdStep">{{ $t('root.下一步') }}</el-button>
            </div>

            <!--第四步 始祖-->
            <div v-if="currentStep == 4" class="common-search search-root flex rounded-full items-center">
              <el-input
                  v-model="rootsSearchParams.ancestors"
                  :placeholder="$t('root.请输入始祖祖辈族人名字')">
                <template #prefix>
                  <el-icon><Search /></el-icon>
                </template>
              </el-input>
              <el-button class="first w-20 border-0" @click="fourthStep()">{{ $t('root.忘记了') }}</el-button>
              <el-button class="last w-20 border-0" @click="fourthStep()">{{ $t('www.搜索') }}</el-button>
            </div>

            <div v-if="currentStep == 2" class="result-tip text-left mt-2">
              {{ $t('亲爱的用户，我们已经为您匹配了') }}
              <span>{{ book_cnt + celebrity_cnt + source_cnt + article_cnt }}</span>
              {{ $t('个结果') }}
            </div>

            <div v-if="currentStep == 3" class="result-tip text-left mt-2">{{ $t('亲爱的用户，离目标越来越近了，我们已为您匹配了') }}<span>{{ book_cnt + celebrity_cnt + source_cnt + article_cnt }}</span>{{ $t('个结果') }}</div>

            <div v-if="currentStep == 4" class="result-tip text-left mt-2">{{ $t('亲爱的用户，离目标只差最后一步了，我们已为您匹配了') }}<span>{{ book_cnt + celebrity_cnt + source_cnt + article_cnt }}</span>{{ $t('个结果') }}</div>

            <div v-if="currentStep > 1 && percentage > 0" class="result mt-10">
              <!--进度条-->
              <div class="result-progress flex rounded-full items-center px-2">
                <el-progress :text-inside="true" :percentage="percentage"/>
                <div class="result-percent text-center">{{$t('root.匹配度')}} 100%</div>
              </div>
              <!--匹配信息-->
              <div class="result-info grid grid-cols-4 mt-4">
                <div>
                  <el-statistic :value="book_cnt_outputValue"/>
                  <div class="result-info-name text-lg">{{ $t('root.匹配家谱') }}</div>
                </div>
                <div>
                  <el-statistic :value="celebrity_cnt_outputValue"/>
                  <div class="result-info-name text-lg">{{ $t('root.匹配名人') }}</div>
                </div>
                <div>
                  <el-statistic :value="source_cnt_outputValue"/>
                  <div class="result-info-name text-lg">{{ $t('root.匹配源流') }}</div>
                </div>
                <div>
                  <el-statistic :value="article_cnt_outputValue"/>
                  <div class="result-info-name text-lg">{{ $t('root.匹配文章') }}</div>
                </div>
              </div>
            </div>

            <!--问题询问-->
            <div class="question px-4">
              <el-divider class="mt-8 mb-2"/>
              <div class="flex justify-between items-center">
                <span class="question-tip">&nbsp;*&nbsp;&nbsp;&nbsp;{{$t('root.有问题找我们')}}</span>
                <el-button round class="Roots-search-button" @click="rootApplicationDialogVisible = true;">{{ $t('root.寻根申请') }}</el-button>
              </div>
              <el-divider class="mb-8 mt-2"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--移动端顶部-->
    <div class="mobile-top block lg:hidden bg-[var(--grey-3)] w-full">
      <div class="mx-auto flex justify-center items-center text-xl text-[var(--grey-1)]">
        <div class="px-8" :class="{active: moblieChooseTab == 1}" @click="moblieChooseTab = 1;">{{ $t('www.一键寻根') }}</div>
        <div class="px-8" :class="{active: moblieChooseTab == 2}" @click="moblieChooseTab = 2;">{{ $t('root.寻根文化') }}</div>
      </div>
    </div>

    <!--移动端寻根-->
    <template v-if="moblieChooseTab == 1">
      <!--移动端进度条-->
      <el-progress class="mobile-top-progress block lg:hidden w-full" :text-inside="true"  :percentage="percentage"
                   :stroke-width="20" stroke-linecap="square"/>

      <!--移动端对话框-->
      <div id="chatBox" class="mobile-chat block lg:hidden relative w-full px-4 pt-2 overflow-y-auto">
        <div class="mt-6" v-for="(chatItem,chatIndex) in mobileChatList" :key="chatIndex">
          <div v-if="chatItem.type == 'robot' || chatItem.type == 'card'" class="flex gap-x-2 items-end">
            <img class="w-8 h-8" src="@/assets/images/ancestry/mobile_robot.svg">
            <span class="text-[#1872d5] font-medium">{{ $t('root.寻根小助手') }}</span>
          </div>
          <div v-if="chatItem.type == 'robot'" class="w-11/12 p-4 bg-white text-lg mt-2 rounded-tr-2xl rounded-bl-2xl rounded-br-2xl">
            {{ chatItem.content }}
          </div>
          <div v-if="chatItem.type == 'card'" class="w-11/12 p-4 bg-white text-lg mt-2 rounded-tr-2xl rounded-bl-2xl rounded-br-2xl"
               @click="handleCardClick">
            <div v-html="chatItem.content"></div>
          </div>
          <div v-if="chatItem.type == 'user'" class="flex justify-end">
            <div class="p-4 pr-10 bg-[var(--dark-blue)] text-white text-lg mt-2 rounded-tl-2xl rounded-bl-2xl rounded-br-2xl">
              {{ chatItem.content }}
            </div>
          </div>
        </div>

        <!--输入框-->
        <div class="mobile-input fixed bottom-6 flex items-center h-16 ">
          <el-select v-if="currentStep == 1" class="h-full"
              v-model="rootsSearchParams.surname_id" placement="top" popper-class="mobile-bottom-popper"
                     filterable
              :placeholder="$t('root.请输入您的姓氏')" @change="changeSearchValue">
            <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
              <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
            </template>
          </el-select>

          <el-select v-else-if="currentStep == 2" class="h-full" placement="top" popper-class="mobile-bottom-popper"
                     v-model="rootsSearchParams.province_id"
                     filterable
                     :placeholder="$t('root.请输入祖籍地省份')" @change="getCityOptions">
            <template v-for="province in provinceOptions" :key="province.id">
              <el-option :label="province.aname" :value="province.id"></el-option>
            </template>
          </el-select>

          <el-select v-else-if="currentStep == 3" class="h-full"
                     v-model="rootsSearchParams.city_id" placement="top" popper-class="mobile-bottom-popper"
                     filterable
                     :placeholder="$t('root.请输入祖籍地城市')" @change="changeCity">
            <template v-for="city in cityOptions" :key="city.id">
              <el-option :label="city.aname" :value="city.id"></el-option>
            </template>
          </el-select>

          <el-input v-else-if="currentStep == 4"
              v-model="rootsSearchParams.ancestors"
              :placeholder="$t('root.请输入始祖祖辈族人名字')">
          </el-input>

          <el-input v-else v-model="mobileChatInput" :placeholder="currentStep <= questionList.length ? mobileChatList[mobileChatList.length - 1].content : $t('已为您匹配好最佳结果')"></el-input>

          <el-button class="primary-search-button min-w-28" round @click="mobileSearch" :disabled="currentStep > questionList.length">{{ $t('root.发送') }}</el-button>
        </div>
      </div>
    </template>

    <!--列表-->
    <div class="news-list-content mt-20 mx-auto px-4 lg:block" :class="{hidden: moblieChooseTab == 1}">
      <div class="text">{{ $t('root.寻根之旅') }}<br/>{{ $t('root.心灵的归宿与文化的传承') }}</div>
      <div class="roots-list grid gap-x-4 gap-y-8 mt-16">
        <div class="roots-item" v-for="(item,index) in articleList" :key="index">
          <div class="top">
            <div class="time">{{ $moment(item.created_at).format('YYYY-MM-DD')}}</div>
            <div class="roots-text mt-4"><span>{{item.title}}</span></div>
            <div class="introduction truncate-3-lines mt-2 text-[var(--dark-grey)]">{{item.introduction}}</div>
            <el-divider class="my-6"></el-divider>
            <el-button round class="Roots-search-button mt-2"><a :href="'/news-detail?news-id=' + item.id" target="_blank">{{$t('root.了解详情')}} ></a></el-button>
          </div>
          <div class="bottom-img h-64">
            <img :src="item.image_square_url" alt="">
          </div>
        </div>
      </div>
      <div class=""></div>
    </div>
    <div class="w-full mt-6 lg:block" :class="{hidden: moblieChooseTab}">
      <div class="w-[var(--common-content-width)] mx-auto max-w-full px-4">
        <pagination :page-num="queryParams.page" :page-size="queryParams.pageSize" :total-count="totalCount"
                    @go-one="goOne" @go-pre="goPre" @go-next="goNext"></pagination>
      </div>
    </div>

    <el-dialog v-model="rootApplicationDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.故土路遥')}}，</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('home.让我们帮您找回血脉亲情')}}</div>
      <el-form ref="fullClueFormRef" :model="fullClueForm" class="clue-form mt-8" label-position="top" :rules="fullRules">
        <el-form-item :label="$t('姓氏')" prop="surname">
          <el-input :class="{active:fullClueForm.surname}" clearable :placeholder="$t('user.请输入姓氏')" v-model="fullClueForm.surname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.联系方式')" prop="mobile">
          <el-input :class="{active:fullClueForm.mobile}" clearable :placeholder="$t('root.请输入联系方式')" v-model="fullClueForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('root.留言')" prop="describe">
          <el-input :class="{active:fullClueForm.describe}" rows="5" type="textarea" clearable :placeholder="$t('root.有什么想说的都可以留言给我们')" v-model="fullClueForm.describe"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button round @click="rootApplicationDialogVisible = false" class="bg-[var(--middle-grey)]">{{ $t('取消') }}</el-button>
          <el-button round type="primary" @click="rootsApply">
            {{ $t('一键提交') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="rootApplySuccessDialogVisible" :show-close="false" class="root-application-dialog bg-[var(--grey-6)]">
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('故土路遥，')}}</div>
      <div class="text-3xl text-[var(--dark-blue)]">{{$t('让我们帮您找回血脉亲情。')}}</div>
      <div class="flex flex-col justify-center items-center">
        <img class="w-32 mt-12 translate-x-4" src="@/assets/images/ancestry/root_apply_success.svg">
        <div class="text-xl text-[var(--dark-blue)]">{{$t('资料提交成功!')}}</div>
        <div class="text-base text-[var(--dark-grey)] mt-2">{{$t('请您稍后，我们的工作人员将与您联系！')}}</div>
      </div>
      <template #footer>
        <div class="dialog-footer w-full flex flex-col justify-center items-center mt-12">
          <el-button round type="primary"  @click="rootApplySuccessDialogVisible = false" >
            {{ $t('退出') }}
          </el-button>
          <el-button round @click="rootApplySuccessDialogVisible = false" class="bg-[var(--middle-grey)] mt-4" style="margin-left: 0;">{{ $t('返回') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ArrowLeft, Back, Right} from "@element-plus/icons-vue";
import {ref, reactive} from 'vue'
import {getArticleList} from "@/api/news";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import Pagination from "@/components/Pagination";
import CommonSearch from "@/components/CommonSearch";
import {rootsApply, rootsSearchStep1, rootsSearchStep2, rootsSearchStep3, rootsSearchStep4} from "@/api/ancestry";
import {getPubCity, getPubSurname} from "@/api/common";
import {useTransition} from '@vueuse/core';
import util from '@/utils/util';

export default {
  name: "Ancestry",
  components: {CommonSearch, Pagination, Right, Back, ArrowLeft},
  setup() {
    // 姓氏
    const rootsSearchSurname = ref('');
    // 始祖
    const rootsSearchRoot = ref('');
    // 省份
    const rootsSearchProvince = ref('');
    // 姓氏
    const rootsSearchCity = ref('');


    // 省市数据
    const provinceOptions = reactive([]);
    const cityOptions = reactive([]);
    const surnameOptions = ref([]);

    // 当前步骤
    const currentStep = ref(1);

    // 匹配进度
    const percentage = ref(0);
    const finalPercentage = ref(0);

    // 寻根请求参数
    const rootsSearchParams = reactive({
      surname_id: '',
      surname: '',
      province_id: '',
      province: '',
      city_id: '',
      city: '',
      ancestors: ''
    })

    // 匹配数据
    const article_cnt = ref(0);
    const book_cnt = ref(0);
    const celebrity_cnt = ref(0);
    const source_cnt = ref(0);
    const article_cnt_outputValue = useTransition(article_cnt, {
      duration: 1500,
    })
    const book_cnt_outputValue = useTransition(book_cnt, {
      duration: 1500,
    })
    const celebrity_cnt_outputValue = useTransition(celebrity_cnt, {
      duration: 1500,
    })
    const source_cnt_outputValue = useTransition(source_cnt, {
      duration: 1500,
    })

    // 文章列表
    const articleList = ref([]);

    // 请求文章列表参数
    const queryParams = ref({
      page: 1,
      limit: 20,
    });

    // 文章总数
    const totalCount = ref(0);
    // 文章总页数
    const totalPage = ref(0);

    // 寻根申请
    const rootApplicationDialogVisible = ref(false);
    // 寻根需求
    const fullClueForm = reactive({});
    // 寻根申请成功
    const rootApplySuccessDialogVisible = ref(false);

    // 移动端
    // 移动端选择tab
    const moblieChooseTab = ref(1);
    // 移动端对话内容
    const mobileChatList = ref([]);
    // 聊天其他步骤输入内容
    const mobileChatInput = ref('');


    return {
      rootsSearchSurname,
      rootsSearchRoot,
      rootsSearchProvince,
      rootsSearchCity,
      currentStep,
      percentage,
      finalPercentage,
      queryParams, // 请求参数
      totalCount, // 文章总数
      totalPage, // 文章总页数
      articleList, // 文章列表
      provinceOptions,
      cityOptions,
      surnameOptions,
      rootsSearchParams,
      article_cnt_outputValue,
      book_cnt_outputValue,
      celebrity_cnt_outputValue,
      source_cnt_outputValue,
      article_cnt,
      book_cnt,
      celebrity_cnt,
      source_cnt,
      rootApplicationDialogVisible,
      fullClueForm,
      rootApplySuccessDialogVisible,
      moblieChooseTab,
      mobileChatList,
      mobileChatInput
    }
  },
  computed: {
    fullRules() {
      return {
        surname: {
          required: true,
          message: this.$t('请输入姓氏'),
          trigger: 'blur'
        },
        mobile: {
          required: true,
          message: this.$t('请输入联系方式'),
          trigger: 'blur'
        },
      }
    },

      questionList(){
        return [
            this.$t('root.请输入您的姓氏'),
            this.$t('root.请输入祖籍地省份'),
            this.$t('root.请输入祖籍地城市'),
            this.$t('root.请输入始祖/祖辈/族人名字'),
            this.$t('root.请输入始祖祖辈族人曾用名'),
            this.$t('root.还记得其他族人名字或曾用名么'),
            this.$t('root.您的父亲在祖籍地生活么'),
            this.$t('root.您是在祖籍地出生的么'),
            this.$t('root.您现在是生活在祖籍地么'),
            this.$t('root.家族里还有其他族人生活在祖籍地么')
          ]
      },
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  created() {
    // 获取省份可选列表
    this.getProvinceOptions();
    // 获取新闻列表
    this.getArticleList(this.queryParams);
    // 获取姓氏筛选列表
    this.getRemoteSurname();
    // 初始化移动端对话内容
    this.mobileChatList = [
      {
        type: 'robot',
        content: this.$t('root.祖辈足迹绘千秋') + this.$t('root.家族脉络续华章')
      },
      {
        type: 'robot',
        content: this.$t('root.让我们一起来寻根吧')
      },
      {
        type: 'robot',
        content: this.$t('root.请输入您的姓氏')
      },
    ]

  },
  mounted() {
    if (this.$route.query['surname_id'] || this.$route.query['surname']) {
      this.rootsSearchParams.surname_id = this.$route.query['surname_id'];
      this.rootsSearchParams.surname = this.$route.query['surname'];
      this.firstStep().catch(() => {});
    }
  },
  methods:{
    handleCurrentChange(){

    },
    /**
     * 跳转至第一页
     */
    goOne(){
      if (this.queryParams.page == 1) {
        this.$message({
          message: this.$t('www.已经在第一页了'),
          type: 'info'
        });
        return;
      }
      this.queryParams.page = 1;
      this.getArticleList(this.queryParams);
    },
    /**
     * 跳转至前一页
     */
    goPre(page){
      if (this.queryParams.page == 1) {
        this.$message({
          message: this.$t('www.已经在第一页了'),
          type: 'info',
        });
        return;
      }
      this.queryParams.page = page || 1;
      this.getArticleList(this.queryParams);
    },
    /**
     * 跳转至后一页
     */
    goNext(page){
      if (this.queryParams.page == this.totalPage) {
        this.$message({
          message: this.$t('www.已经在最后一页了'),
          type: 'info',
        });
        return;
      }
      this.queryParams.page = page > this.totalPage ? this.totalPage : page;
      this.getArticleList(this.queryParams);
    },
    /**
     * 寻根第一步
     */
    firstStep() {
      return new Promise((resolve, reject) => {
        if (!this.rootsSearchParams.surname) {
          this.$message({
            message: this.$t('user.请输入姓氏'),
            type: 'warning'
          });
          reject();
          return;
        }
        let params = {
          surname: this.rootsSearchParams.surname,
          surname_id: this.rootsSearchParams.surname_id || '0',
        }
        rootsSearchStep1(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.currentStep += 1;
            this.rootsSearchParams.surname_id = res.data.surname_id;
            this.article_cnt = parseInt(res.data.article_cnt);
            this.book_cnt = parseInt(res.data.book_cnt);
            this.celebrity_cnt = parseInt(res.data.celebrity_cnt);
            this.source_cnt = parseInt(res.data.source_cnt);
            this.finalPercentage = 25;
            this.percentageChange();
            resolve();
          } else {
            this.article_cnt = 0;
            this.book_cnt = 0;
            this.celebrity_cnt = 0;
            this.source_cnt = 0;
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            reject();
          }
        }).catch(error => {
          this.article_cnt = 0;
          this.book_cnt = 0;
          this.celebrity_cnt = 0;
          this.source_cnt = 0;
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.未查询到数据'));
          reject();
        })
      })

    },
    /**
     * 寻根第二步
     */
    secondStep() {
      return new Promise((resolve, reject) => {
        if (!this.rootsSearchParams.province_id) {
          this.currentStep = 4;
          reject();
          return;
        }
        let params = {
          surname_id: this.rootsSearchParams.surname_id || '0',
          province_id: this.rootsSearchParams.province_id,
          province: this.rootsSearchParams.province
        }
        rootsSearchStep2(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.currentStep += 1;
            this.article_cnt = parseInt(res.data.article_cnt);
            this.book_cnt = parseInt(res.data.book_cnt);
            this.celebrity_cnt = parseInt(res.data.celebrity_cnt);
            this.source_cnt = parseInt(res.data.source_cnt);
            this.finalPercentage = 50;
            this.percentageChange();
            resolve();
          } else {
            this.article_cnt = 0;
            this.book_cnt = 0;
            this.celebrity_cnt = 0;
            this.source_cnt = 0;
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            reject();
          }
        }).catch(error => {
          this.article_cnt = 0;
          this.book_cnt = 0;
          this.celebrity_cnt = 0;
          this.source_cnt = 0;
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.未查询到数据'));
          reject();
        })
      }).catch(() => {

      })
    },
    /**
     * 寻根第三步
     */
    thirdStep() {
      return new Promise((resolve, reject) => {
        if (!this.rootsSearchParams.city_id) {
          this.currentStep = 4;
          reject();
          return;
        }
        this.rootsSearchParams.city = this.cityOptions.find(item => item.id == this.rootsSearchParams.city_id).aname;
        let params = {
          surname_id: this.rootsSearchParams.surname_id,
          surname: this.rootsSearchParams.surname,
          province_id: this.rootsSearchParams.province_id,
          city_id: this.rootsSearchParams.city_id,
        }
        rootsSearchStep3(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.currentStep += 1;
            this.article_cnt = parseInt(res.data.article_cnt);
            this.book_cnt = parseInt(res.data.book_cnt);
            this.celebrity_cnt = parseInt(res.data.celebrity_cnt);
            this.source_cnt = parseInt(res.data.source_cnt);
            this.finalPercentage = 75;
            this.percentageChange();
            resolve();
          } else {
            this.article_cnt = 0;
            this.book_cnt = 0;
            this.celebrity_cnt = 0;
            this.source_cnt = 0;
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            reject();
          }
        }).catch(error => {
          this.article_cnt = 0;
          this.book_cnt = 0;
          this.celebrity_cnt = 0;
          this.source_cnt = 0;
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.未查询到数据'));
          reject();
        })
      }).catch(() => {

      })
    },
    /**
     * 寻根第四步
     */
    fourthStep(notJump) {
      return new Promise((resolve, reject) => {
        let params = {
          surname_id: this.rootsSearchParams.surname_id,
          province_id: this.rootsSearchParams.province_id || '0',
          city_id: this.rootsSearchParams.city_id || '0',
          ancestors: this.rootsSearchParams.ancestors,
        }
        rootsSearchStep4(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            if (notJump) {
              this.currentStep += 1;
              this.article_cnt = parseInt(res.data.article_cnt);
              this.book_cnt = parseInt(res.data.book_cnt);
              this.celebrity_cnt = parseInt(res.data.celebrity_cnt);
              this.source_cnt = parseInt(res.data.source_cnt);
              this.finalPercentage = 100;
              this.percentageChange();
            } else {
              // 跳转至寻根结果页
              this.goToResultPage();
            }
            resolve();
          } else {
            this.article_cnt = 0;
            this.book_cnt = 0;
            this.celebrity_cnt = 0;
            this.source_cnt = 0;
            util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            reject();
          }
        }).catch(error => {
          this.article_cnt = 0;
          this.book_cnt = 0;
          this.celebrity_cnt = 0;
          this.source_cnt = 0;
          util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.未查询到数据'));
          reject();
        })
      }).catch(() => {

      })
    },
    percentageChange() {
      let interval = setInterval(() => {
        if (this.percentage + 5 < this.finalPercentage) {
          this.percentage = this.percentage + 5;
        } else if (this.percentage < this.finalPercentage) {
          this.percentage = this.finalPercentage;
          clearInterval(interval);
        }
      }, 200);
    },
    /**
     * 获取文章列表
     * @param page
     */
    getArticleList(queryParams) {
      let params = {
        id: 4,
        ...queryParams
      }
      getArticleList(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.articleList = res.data.list;
          this.totalCount = res.data.total_count;
          this.totalPage = res.data.total_page;
        }
      }).catch(error => {

      })
    },
    /**
     * 获取省份可选列表
     */
    getProvinceOptions() {
      getPubCity({parent_id: 1}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.provinceOptions, res.data);
        } else {
          this.provinceOptions = [];
        }
      }).catch(error => {
        this.provinceOptions = [];
      })
    },
    /**
     * 获取市区可选列表
     */
    getCityOptions(value) {
      this.rootsSearchParams.province = this.provinceOptions.find(item => item.id == value).aname;
      getPubCity({parent_id: value}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.cityOptions, res.data);
        } else {
          this.cityOptions = [];
        }
      }).catch(() => {
        this.cityOptions = [];
      })
    },
    /**
     * 城市改变
     */
    changeCity(value) {
      this.rootsSearchParams.city = this.cityOptions.find(item => item.id == value).aname;
    },
    /**
     * 清空已选选项
     * @param key
     */
    clearChoice(key) {
      if (key == 'surname') {
        this.currentStep = 1;
        this.rootsSearchParams = {};
        this.percentage = 0;
      } else if (key == 'province') {
        this.currentStep = 1;
        this.rootsSearchParams = {
          surname_id: this.rootsSearchParams.surname_id,
          surname: this.rootsSearchParams.surname,
          province_id: '',
          province: '',
          city_id: '',
          city: '',
          ancestors: ''
        };
        this.firstStep();
      } else if (key == 'city') {
        this.currentStep = 2;
        this.rootsSearchParams = {
          surname_id: this.rootsSearchParams.surname_id,
          surname: this.rootsSearchParams.surname,
          province_id: this.rootsSearchParams.province_id,
          province: this.rootsSearchParams.province,
          city_id: '',
          city: '',
          ancestors: ''
        };
        this.secondStep();
      }
    },
    /**
     * 寻根需求
     */
    rootsApply() {
      this.$refs.fullClueFormRef.validate((valid, fields) => {
        if (valid) {
          rootsApply(this.fullClueForm).then(res => {
            if (res.code == SUCCESS_CODE && res.msg) {
              this.rootApplicationDialogVisible = false;
              this.rootApplySuccessDialogVisible = true;
            } else {
              util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
            }
          }).catch(error => {
            util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('寻根申请失败'));
          })
        }
      });
    },
    surnameSearch(queryString) {
      return this.surnameOptions.filter(item => item.alias.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
    },
    /**
     * 获取姓氏筛选列表
     */
    getRemoteSurname() {
      getPubSurname().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.surnameOptions = res.data;
        } else {
          this.surnameOptions = [];
        }
      }).catch(error => {
        this.surnameOptions = [];
      })
    },
    changeSearchValue(value) {
      this.rootsSearchParams.surname_id = value;
      this.rootsSearchParams.surname = this.surnameOptions.find(item => item.id == value).surname;
    },
    /**
     * 移动端检索
     */
    mobileSearch() {
      if (this.currentStep == 1) {
        this.firstStep().then(() => {
          this.mobileChatList.push({
            type: 'user',
            content: this.rootsSearchParams.surname
          });
          let imgSrc = require('@/assets/images/ancestry/mobile_edit.svg');
          this.mobileChatList.push({
            type: 'card',
            content:`<div>${this.$t('root.我们已经为您匹配了')} ` +
                `<span class="text-[var(--light-orange)]">${this.book_cnt + this.celebrity_cnt + this.source_cnt + this.article_cnt}</span> ${this.$t('root.个结果')}，` +
                `${this.$t('root.匹配家谱')} <span class="text-[var(--light-orange)]">${this.book_cnt}</span>${this.$t('root.份')}，` +
                `${this.$t('root.匹配名人')} <span class="text-[var(--light-orange)]">${this.celebrity_cnt}</span>${this.$t('root.位')}，` +
                `${this.$t('root.匹配源流')} <span class="text-[var(--light-orange)]">${this.source_cnt}</span>${this.$t('root.条')}，` +
                `${this.$t('root.匹配文章')} <span class="text-[var(--light-orange)]">${this.article_cnt}</span>${this.$t('root.篇')}。<br/>` +
                `${this.$t('root.寻根进程已达')}${this.finalPercentage}%，${this.$t('root.那我们继续吧')}</div>` +
                `<div id="editSurname" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.修改姓氏')}</div>`
          });
          this.mobileChatList.push({
            type: 'robot',
            content: this.$t('root.请输入祖籍地省份')
          });
          this.scrollToBottom();
        }).catch(error => {
        })
      } else if (this.currentStep == 2) {
        if (!this.rootsSearchParams.province_id) {
          this.currentStep = 4;
          this.mobileChatList.push({
            type: 'robot',
            content: this.$t('请输入始祖/祖辈/族人名字')
          });
          this.scrollToBottom();
          return;
        }
        this.secondStep().then(() => {
          this.mobileChatList.push({
            type: 'user',
            content: this.rootsSearchParams.province
          });
          let imgSrc = require('@/assets/images/ancestry/mobile_edit.svg');
          this.mobileChatList.push({
            type: 'card',
            content:`<div>${this.$t('root.我们已经为您匹配了')} ` +
                `<span class="text-[var(--light-orange)]">${this.book_cnt + this.celebrity_cnt + this.source_cnt + this.article_cnt}</span> ${this.$t('root.个结果')}，` +
                `${this.$t('root.匹配家谱')} <span class="text-[var(--light-orange)]">${this.book_cnt}</span>${this.$t('root.份')}，` +
                `${this.$t('root.匹配名人')} <span class="text-[var(--light-orange)]">${this.celebrity_cnt}</span>${this.$t('root.位')}，` +
                `${this.$t('root.匹配源流')} <span class="text-[var(--light-orange)]">${this.source_cnt}</span>${this.$t('root.条')}，` +
                `${this.$t('root.匹配文章')} <span class="text-[var(--light-orange)]">${this.article_cnt}</span>${this.$t('root.篇')}。<br/>` +
                `${this.$t('root.寻根进程已达')}${this.finalPercentage}%，${this.$t('root.那我们继续吧')}</div>` +
                `<div id="editProvince" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('修改省份')}</div>`
          });
          this.mobileChatList.push({
            type: 'robot',
            content: this.$t('root.请输入祖籍地城市')
          });
          this.scrollToBottom();
        }).catch(error => {
        })
      } else if (this.currentStep == 3) {
        if (!this.rootsSearchParams.city_id) {
          this.currentStep = 4;
          this.mobileChatList.push({
            type: 'robot',
            content: this.$t('请输入始祖/祖辈/族人名字')
          });
          this.scrollToBottom();
          return;
        }
        this.thirdStep().then(() => {
          this.mobileChatList.push({
            type: 'user',
            content: this.rootsSearchParams.city
          });
          let imgSrc = require('@/assets/images/ancestry/mobile_edit.svg');
          this.mobileChatList.push({
            type: 'card',
            content:`<div>${this.$t('root.我们已经为您匹配了')} ` +
                `<span class="text-[var(--light-orange)]">${this.book_cnt + this.celebrity_cnt + this.source_cnt + this.article_cnt}</span> ${this.$t('root.个结果')}，` +
                `${this.$t('root.匹配家谱')} <span class="text-[var(--light-orange)]">${this.book_cnt}</span>${this.$t('root.份')}，` +
                `${this.$t('root.匹配名人')} <span class="text-[var(--light-orange)]">${this.celebrity_cnt}</span>${this.$t('root.位')}，` +
                `${this.$t('root.匹配源流')} <span class="text-[var(--light-orange)]">${this.source_cnt}</span>${this.$t('root.条')}，` +
                `${this.$t('root.匹配文章')} <span class="text-[var(--light-orange)]">${this.article_cnt}</span>${this.$t('root.篇')}。<br/>` +
                `${this.$t('root.寻根进程已达')}${this.finalPercentage}%，${this.$t('root.那我们继续吧')}</div>` +
                `<div id="editCity" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.修改城市')}</div>`
          });
          this.mobileChatList.push({
            type: 'robot',
            content: this.$t('root.请输入始祖祖辈族人名字')
          });
          this.scrollToBottom();
        }).catch(error => {
        })
      } else if (this.currentStep == 4) {
        if (!this.rootsSearchParams.ancestors) {
          let imgSrc = require('@/assets/images/ancestry/mobile_jump.svg');
          this.mobileChatList.push({
            type: 'card',
            content: `<div>${this.questionList[this.currentStep++]}</div>` +
                `<div id="jump" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.跳过')}</div>`
          });
          this.scrollToBottom();
          return;
        }
        this.fourthStep(true).then(() => {
          this.mobileChatList.push({
            type: 'user',
            content: this.rootsSearchParams.ancestors
          });
          let imgSrc = require('@/assets/images/ancestry/mobile_edit.svg');
          this.mobileChatList.push({
            type: 'card',
            content:`<div>${this.$t('root.我们已经为您匹配了')} ` +
                `<span class="text-[var(--light-orange)]">${this.book_cnt + this.celebrity_cnt + this.source_cnt + this.article_cnt}</span> ${this.$t('root.个结果')}，` +
                `${this.$t('root.匹配家谱')} <span class="text-[var(--light-orange)]">${this.book_cnt}</span>${this.$t('root.份')}，` +
                `${this.$t('root.匹配名人')} <span class="text-[var(--light-orange)]">${this.celebrity_cnt}</span>${this.$t('root.位')}，` +
                `${this.$t('root.匹配源流')} <span class="text-[var(--light-orange)]">${this.source_cnt}</span>${this.$t('root.条')}，` +
                `${this.$t('root.匹配文章')} <span class="text-[var(--light-orange)]">${this.article_cnt}</span>${this.$t('root.篇')}。<br/>` +
                `${this.$t('root.寻根进程已达')}${this.finalPercentage}%，${this.$t('root.那我们继续吧')}</div>` +
                `<div id="editAncestor" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.修改始祖')}</div>`
          });
          imgSrc = require('@/assets/images/ancestry/mobile_jump.svg');
          this.mobileChatList.push({
            type: 'card',
            content: `<div>${this.questionList[this.currentStep++]}</div>` +
                `<div id="jump" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.跳过')}</div>`
          });
          this.scrollToBottom();
        }).catch(error => {
        })
      } else if (this.currentStep < this.questionList.length) {
        if (!this.mobileChatInput) {
          let imgSrc = require('@/assets/images/ancestry/mobile_jump.svg');
          this.mobileChatList.push({
            type: 'card',
            content: `<div>${this.questionList[this.currentStep++]}</div>` +
                `<div id="jump" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.跳过')}</div>`
          });
          this.scrollToBottom();
          return;
        }
        this.mobileChatList.push({
          type: 'user',
          content: this.mobileChatInput
        });
        this.mobileChatInput = '';
        let imgSrc = require('@/assets/images/ancestry/mobile_jump.svg');
        this.mobileChatList.push({
          type: 'card',
          content:`<div>${this.$t('root.我们正在为您努力匹配')}， ${this.$t('root.寻根进程已达')} ` +
              `<span class="text-[var(--light-orange)]">${this.finalPercentage}%</span>` +
              `${this.$t('root.那我们继续吧')}</div>`
        });
        this.mobileChatList.push({
          type: 'card',
          content: `<div>${this.questionList[this.currentStep++]}</div>` +
              `<div id="jump" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.跳过')}</div>`
        });
        this.scrollToBottom();

      } else {
        this.finalPercentage = 100;
        this.percentageChange();
        this.currentStep++;
        this.mobileChatInput ? this.mobileChatList.push({
          type: 'user',
          content: this.mobileChatInput
        }) : null;
        this.mobileChatInput = '';
        let imgSrc = require('@/assets/images/ancestry/mobile_view.svg');
        this.mobileChatList.push({
          type: 'card',
          content:`<div>${this.rootsSearchParams.surname}${this.$t('root.氏宗亲')}， ${this.$t('root.已为您在族谱数据库匹配出最佳结果')}！` +
              `<div id="detail" class="flex justify-end items-center gap-x-3 text-[var(--light-orange)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.查看详情')}</div>`
        });
        this.scrollToBottom();
      }
    },
    /**
     * 滚动到最底部
     */
    scrollToBottom() {
      this.$nextTick(()=>{
        const box = document.getElementById('chatBox');
        // box.scrollTo(0, box.scrollHeight - box.clientHeight);  //最底部
        box.scrollTo({ top: box.scrollHeight - box.clientHeight, behavior: 'smooth' })
      })
    },
    /**
     * 跳转至寻根结果页
     */
    goToResultPage() {
      let queryString = Object.keys(this.rootsSearchParams).reduce((pre,key) => {
        return this.rootsSearchParams[key] ? pre + key + '=' + this.rootsSearchParams[key] + '&' : pre;
      }, '')
      this.$router.push('/ancestry-detail?' + queryString);
    },
    /**
     * 处理点击
     * @param event
     */
    handleCardClick(event) {
      console.log('点击', event.target, event.target.id);
      let clickTargetId = event.target.id;
      if(clickTargetId == 'editSurname') {
        this.currentStep = 1;
        this.mobileChatList.push({
          type: 'robot',
          content: this.questionList[this.currentStep - 1]
        });
        this.scrollToBottom();
      } else if(clickTargetId == 'editProvince') {
        this.currentStep = 2;
        this.mobileChatList.push({
          type: 'robot',
          content: this.questionList[this.currentStep - 1]
        });
        this.scrollToBottom();
      } else if(clickTargetId == 'editCity') {
        this.currentStep = 3;
        this.mobileChatList.push({
          type: 'robot',
          content: this.questionList[this.currentStep - 1]
        });
        this.scrollToBottom();
      } else if(clickTargetId == 'editAncestor') {
        this.currentStep = 4;
        this.mobileChatList.push({
          type: 'robot',
          content: this.questionList[this.currentStep - 1]
        });
        this.scrollToBottom();
      } else if(clickTargetId == 'jump' && this.currentStep < this.questionList.length) {
        let imgSrc = require('@/assets/images/ancestry/mobile_jump.svg');
        this.mobileChatList.push({
          type: 'card',
          content: `<div>${this.questionList[this.currentStep++]}</div>` +
              `<div id="jump" class="flex justify-end items-center gap-x-3 text-[var(--grey-1)] mt-4 pt-4" style="border-top: 0.06rem solid var(--blue-2)"><img class="w-4 h-4" src="` + imgSrc +`">${this.$t('root.跳过')}</div>`
        });
        this.scrollToBottom();
      } else if(clickTargetId == 'jump') {
        this.mobileSearch();
      } else if(clickTargetId == 'detail') {
        this.goToResultPage();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ancestry-content{

  // PC端顶部
  .Roots-top{
    width: 100%;
    height: 64rem;

    @media screen and (max-width: 959.98px) {
      & {
        height: 56rem;
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top-info {
      width: 100%;
      padding-top: calc(var(--header-height) + 8rem);

      .Roots-content {
        font-size: var(--index-top-title-font-size);
      }

      .info-detail {
        width: 48rem;
        margin: 4rem auto 0;
      }

      .input-info {
        color: white;
        font-size: 1rem;
        top: -2.5rem;
        left: 0;

        div {
          padding: 0.5rem 1.2rem;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 9999px;
          position: relative;
          display: flex;
          align-items: center;
          line-height: 1;

          .el-icon {
            margin-left: 0.5rem;
            color: rgba(255,255,255,0.5);
          }
        }
      }

      .search-root {
        width: 100%;

        ::v-deep .el-button {
          color: white;
          background-color: var(--light-orange);

          &:hover {
            background-color: var(--middle-orange);
          }

          &.first {
            border-radius: 9999px 0 0 9999px;
            background-color: var(--middle-grey);

            &:hover {
              background-color: var(--grey-1);
            }
          }

          &.last {
            border-radius: 0 9999px 9999px 0;
          }
        }
        ::v-deep .el-button+.el-button {
          margin-left: 0.06rem;
        }
      }

      .result {
        .result-progress {
          background-color: rgba(255, 255, 255, 0.2);
          width: 100%;
          height: 1.4rem;

          ::v-deep .el-progress {
            width: calc(100% - 5rem);

            .el-progress-bar__outer {
              background-color: rgba(255, 255, 255, 0.5);
              overflow: visible;
              height: 0.8rem!important;

              .el-progress-bar__inner {
                background: linear-gradient(to right, var(--light-orange), var(--darker-orange));

                .el-progress-bar__innerText {
                  font-size: 0.8rem;
                  margin-top: -0.4rem;
                  margin-right: 0.5rem;
                }

                &::after {
                  content: '';
                  width: 1rem;
                  height: 1rem;
                  background-color: white;
                  border: 0.2rem solid var(--light-orange);
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  right: -0.7rem;
                  transform: translateY(-50%);
                  box-shadow: 0 0 0.2rem 0.2rem rgba(255, 255, 255, 0.8); /* 白色的光晕效果 */
                  animation: pulse 2s infinite;
                }

                @keyframes pulse {
                  0% {
                    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8); /* 开始时的光晕效果 */
                  }
                  100% {
                    box-shadow: 0 0 0.6rem 0.6rem rgba(255, 255, 255, 0); /* 结束时的光晕效果 */
                  }
                }
              }
            }
          }

          .result-percent {
            width: 6rem;
            color: white;
            font-size: 0.9rem;
            line-height: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .result-info {
          color: var(--light-orange);

          & > div {
            position: relative;
          }

          & > div:not(:last-child)::after {
            content: "";
            width: 0.01rem;
            height: 80%;
            background-color: var(--light-orange);
            position: absolute;
            right: 0;
            top: 0.4rem;
          }

          ::v-deep .el-statistic {

            .el-statistic__content {
              font-size: unset;
            }

            .el-statistic__number {
              font-size: 3.6rem;
              line-height: 3.6rem;
              font-weight: 600;
              color: var(--light-orange);
            }
          }
        }
      }

      .question {
        font-size: 1rem;

        ::v-deep .el-divider {
          border-color: var(--middle-grey);
        }

        .question-tip {
          color: var(--middle-grey);
        }
      }

      .result-tip {
        font-size: 1rem;
        color: white;
        padding-left: 2rem;

        span {
          font-weight: 600;
          font-size: 1.125rem;
          color: var(--light-orange);
        }
      }
    }
  }

  // 移动端顶部
  .mobile-top {
    height: calc(var(--header-height) + 4rem);
    padding-top: calc(var(--header-height) + 1.5rem);

    .active {
      color: var(--dark-blue);
    }
  }

  ::v-deep .mobile-top-progress.el-progress {
    .el-progress-bar__outer {
      border-radius: 0;
      background-color: var(--dark-grey);

      .el-progress-bar__inner {
        border-radius: 0 100px 100px 0;
        background-image: linear-gradient(to right, var(--light-orange) 0%, var(--darker-orange) 100%);
      }
    }
  }

  .mobile-chat {
    height: calc(100vh - var(--header-height) - 4rem - 20px - 7rem);

    .mobile-input {
      width: calc(100% - 2rem);

      ::v-deep .el-autocomplete {
        border: none;
        height: 100%;

        .el-input {
          height: 100%;
          width: 100%;

          .el-input__wrapper {
            box-shadow: none;
            border-radius: 9999px 0 0 9999px;
            padding: 0.06rem 1rem;
            height: 100%;
            font-size: 1.125rem;
            padding-left: 2rem;

            .el-input__prefix-inner>:last-child {
              margin-right: 1rem;
            }
          }
        }
      }

      ::v-deep .el-select {

        .el-select__wrapper {
          height: 100%;
          border-radius: 9999px 0 0 9999px;
          padding-left: 2rem;
        }

      }

      ::v-deep .el-input {
        height: 100%;

        .el-input__wrapper {
          border-radius: 9999px 0 0 9999px;
          padding-left: 2rem;
        }
      }

      ::v-deep .el-button {
        height: 100%;
        border-radius: 0 9999px 9999px 0;
      }
    }
  }

  .Roots-search-button{
    background-color: transparent;
    border: 0.06rem solid var(--light-orange);
    color: var(--light-orange);
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1rem;
    min-width: 7rem;
    padding: 0 1.5rem;

    &:hover {
      background-color: var(--light-orange);
      color: white;
    }
  }

  .text{
    text-align: center;
    color: var(--dark-blue);
    font-size: 2rem;
    line-height: 1.75;
  }

  .news-list-content {
    width: var(--common-content-width);
    max-width: 100%;
    padding-bottom: 1rem;

    .roots-list{
      flex-wrap: wrap;
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));

      .roots-item{
        overflow: hidden;
        width: 100%;
        background-color: #ffffff;
        border-radius: 1.5rem;
        box-sizing: border-box;

        &:nth-child(4n){
          margin-right: 0;
        }
        .top{
          padding: 2rem;
          .time{
            font-size: 0.9rem;
            color: #7f7d7e;
          }
          .roots-text{
            font-size: 1.2rem;
            letter-spacing: 0.06rem;
            //height: 5.4rem;

            overflow: hidden;
            text-overflow: ellipsis; /* 显示省略号 */
            white-space: nowrap;
          }

          .introduction {
            height: 4.5rem;
          }
        }
        .bottom-img{
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.common-search {
  border: 0.06rem solid var(--grey-5);
  height: 3.5rem;
  background-color: white;
  display: flex;
  border-radius: 9999px;
  align-items: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  &:hover {
    border: 0.06rem solid var(--light-orange);
  }

  ::v-deep .el-input {
    border: none;

    .el-input__wrapper {
      box-shadow: none;
      border-radius: 9999px;
      background-color: transparent;
      padding: 0.06rem 1rem;
      font-size: 1.125rem;

      .el-input__prefix-inner>:last-child {
        margin-right: 1rem;
      }
    }
  }

  ::v-deep .el-select {
    .el-select__wrapper {
      height: 100%;
      border-radius: 9999px;
      box-shadow: none;
      background-color: transparent;
      font-size: 1.125rem;

      .el-select__suffix {
        display: none;
      }
    }
  }

  ::v-deep .el-button {
    color: white;
    background-color: var(--light-orange);
    padding: 1.4rem 2rem;
    min-width: 7rem;
    height: 3rem;
    border: 0;
  }
}
</style>
