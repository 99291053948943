<template>
  <div class="family-tree-content">
    <!-- 图表区域 -->
    <div id="familyChart" v-loading="chartLoading" element-loading-background="rgba(210, 213, 223, 0.5)" class="w-full h-full"></div>

    <!--操作栏-->
    <transition name="operateBarDrawer">
      <div v-show="showOperateBar" ref="operateBar" v-loading="operateBarLoading" class="operate-bar absolute top-0 left-0 h-full">
      <!--背景色占位-->
      <div class="place-holder w-1/2"></div>

      <!--标题-->
      <div class="operate-title w-full rounded-tr-2xl pt-8 pb-10 px-10 text-center">
        <span v-if="isInit">{{ $t('tree.初始化节点') }}</span>
        <span v-else-if="personInfoForm.node_type">{{ $t(personInfoForm.node_type == 1 ? 'tree.添加XX的父亲' : personInfoForm.node_type == 4 ? 'tree.添加XX的配偶' : 'tree.添加XX的子女', {name: personInfoForm.relatedPersonInfo ? personInfoForm.relatedPersonInfo.surname + personInfoForm.relatedPersonInfo.name : null}) }}</span>
        <div v-else class="flex items-center gap-x-4">
          <img v-if="personInfoForm.sex == SEX_WOMAN" class="w-12 h-12" src="@/assets/images/family-tree/spouse_active.svg" alt="">
          <img v-else class="w-12 h-12" src="@/assets/images/family-tree/self_active.svg" alt="">
          <div class="text-left">
            <div class="text-lg">{{ personInfoForm.surname + personInfoForm.name }}</div>
            <div class="text-sm">
              <span>{{ personInfoForm.birth_day ? $moment(personInfoForm.birth_day).format('YYYY') : $t('tree.未知')}}</span> -
              <span>{{ personInfoForm.death_day ? $moment(personInfoForm.death_day).format('YYYY') : $t('tree.未知')}}</span>
              <span class="ml-2">{{ personInfoForm.death == DEATH_STATUS_LIVE ? $t('tree.在世') : personInfoForm.death == DEATH_STATUS_DEATH ? $t('tree.殁') : $t('tree.未知') }}</span>
            </div>
          </div>

        </div>
      </div>

      <!--头像-->
      <div v-if="isInit || personInfoForm.node_type" class="avatar" >
        <img v-if="personInfoForm.sex == SEX_WOMAN" class="w-12 h-12 mx-auto" src="@/assets/images/family-tree/spouse_active.svg" alt="">
        <img v-else class="w-12 h-12 mx-auto" src="@/assets/images/family-tree/self_active.svg" alt="">
      </div>

      <div v-else class="view-info-options w-full bg-[var(--dark-grey)] flex h-8 text-white text-sm">
        <div class="w-1/2 flex items-center gap-x-2 justify-center">
          <img class="w-4 h-4" src="@/assets/images/family-tree/info_info.svg">
          <span class="cursor-pointer"><a :href="'/family-tree-person?id=' + personInfoForm.id +'&family_tree_id=' + book.id">{{ $t('了解个人资料') }}</a></span>
        </div>
        <div class="w-1/2 flex items-center gap-x-2 justify-center relative cursor-pointer">
          <img class="w-4 h-4" src="@/assets/images/family-tree/info_family_tree.svg">
          <span>{{ $t('tree.查看家谱树') }}</span>
        </div>
      </div>

      <!--表单-->
      <div class="operate-form px-10 pb-12">
        <el-form ref="personInfoFormRef" :model="personInfoForm" label-width="auto" label-position="left" :rules="personInfoFormRule">
          <el-form-item prop="sex">
            <el-radio-group class="sex" v-model="personInfoForm.sex">
              <el-radio-button :label="$t('tree.男')" :value="1" />
              <el-radio-button :label="$t('tree.女')" :value="0" />
              <el-radio-button :label="$t('tree.未知')" :value="2" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="personInfoForm.node_type == NODE_TYPE_CHILD && personInfoForm.relatedPersonInfo.spouses && personInfoForm.relatedPersonInfo.spouses.length" :label="$t('tree.母亲')" prop="spouse_id">
            <el-select v-model="personInfoForm.spouse_id" :placeholder="$t('tree.请选择母亲')">
              <el-option
                  v-for="spouseItem in personInfoForm.relatedPersonInfo.spouses"
                  :key="spouseItem.id"
                  :label="spouseItem.surname + spouseItem.name"
                  :value="spouseItem.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('tree.姓氏')" prop="surname">
            <el-input v-model="personInfoForm.surname"/>
          </el-form-item>
          <el-form-item :label="$t('tree.名字')" prop="name">
            <el-input  v-model="personInfoForm.name"/>
          </el-form-item>
          <el-form-item :label="$t('tree.字')" class="zi">
            <el-input v-model="personInfoForm.zi" style="width: 15rem"/>
          </el-form-item>
          <el-form-item :label="$t('tree.号')" class="hao">
            <el-input  v-model="personInfoForm.hao" style="width: 15rem"/>
          </el-form-item>
          <el-form-item :label="$t('tree.谥号')">
            <el-input v-model="personInfoForm.shihao"/>
          </el-form-item>
          <el-form-item :label="$t('tree.生存状况')">
            <el-radio-group class="death" v-model="personInfoForm.death">
              <el-radio-button :label="$t('tree.在世')" :value="0" />
              <el-radio-button :label="$t('tree.死亡')" :value="1" />
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('tree.出生日期')">
            <el-date-picker class="birth-day"
                v-model="personInfoForm.birth_day"
                type="date" value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item :label="$t('tree.出生地')">
            <el-input v-model="personInfoForm.birth_address"/>
          </el-form-item>
          <el-form-item v-if="personInfoForm.death == DEATH_STATUS_DEATH" :label="$t('tree.死亡日期')">
            <el-date-picker class="death-day"
                v-model="personInfoForm.death_day"
                type="date" value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item v-if="personInfoForm.death == DEATH_STATUS_DEATH" :label="$t('tree.tree.埋葬地')">
            <el-input v-model="personInfoForm.death_address"/>
          </el-form-item>
        </el-form>
      </div>

      <!--操作-->
      <div class="operate-button px-8 pt-2 pb-12">
        <el-button @click="closeOperateBar">{{$t('tree.跳过')}}</el-button>
        <el-button @click="commit()">{{isInit || personInfoForm.node_type ? $t('tree.添加进入') : $t('tree.确认更新')}}</el-button>
      </div>
    </div>
    </transition>

    <!--选择家谱弹窗-->
    <el-dialog
        v-model="choseBookDialogVisible"
        :title="$t('tree.选择加载家谱')"
        width="500">

      <el-radio-group v-model="bookId" class="ml-4">
        <el-radio v-for="bookItem in bookList" :key="bookItem.id" :value="bookItem.id">{{ bookItem.title }}</el-radio>
      </el-radio-group>

      <template #footer>
        <div class="dialog-footer w-full flex justify-between">
          <el-button @click="choseBookDialogVisible = false">{{ $t('tree.取消')}}</el-button>
          <el-button type="primary" @click="chooseBook()">
            {{ $t('tree.确认')}}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref, reactive} from 'vue';
import {
  createTreeNode, getPersonInfo,
  getSelfTreeList,
  getTreeNodeList,
  getTreeNodeParentList,
  treeInfo,
  treeNodeInit, updatePersonInfo
} from "@/api/family-tree";
import {
  DEATH_STATUS_DEATH,
  DEATH_STATUS_LIVE, MESSAGE_ICON_COMMON,
  MESSAGE_ICON_ERROR, NODE_TYPE_CHILD,
  SEX_WOMAN,
  STATUS_NORMAL,
  SUCCESS_CODE
} from "@/utils/constants";
import G6 from '@antv/g6';
import {
  CHILD_SHAPE_HEIGHT, LAST_OPERATE_BAR_HEIGHT,
  MIDDLE_OPERATE_BAR_HEIGHT,
  NODE_SHAPE_HEIGHT,
  registerG6CustomNode
} from "@/utils/registerG6CustomNode.js";
import {TreePersonInfo} from '@/entity/TreePersonInfo'
import {BasePersonInfo} from '@/entity/BasePersonInfo'
import util from '@/utils/util';
import {ElMessage} from "element-plus";

export default {
  name: "FamilyTree",
  setup() {
    // 树图
    const graph = ref(null);
    // 树图loading
    const chartLoading = ref(false);
    // 选择家谱弹窗
    const choseBookDialogVisible = ref(false);
    // 家谱列表
    const bookList = ref([]);
    // 家谱
    const book = ref({});
    // 家谱ID
    const bookId = ref(null);

    // 树图数据
    const treeData = reactive({});

    // 是否展示操作栏
    const showOperateBar = ref(false);
    // 操作栏loading
    const operateBarLoading = ref(false);

    // 请求参数
    const queryParameters = ref({});

    // 左侧弹窗表单
    const personInfoForm = ref(new TreePersonInfo())

    // 是否是初始化节点
    const isInit = ref(false);

    // 当前操作的节点
    const currentItem = reactive({});
    const currentModel = reactive({});

    return {
      SEX_WOMAN: SEX_WOMAN,
      DEATH_STATUS_LIVE: DEATH_STATUS_LIVE,
      DEATH_STATUS_DEATH: DEATH_STATUS_DEATH,
      NODE_TYPE_CHILD: NODE_TYPE_CHILD,
      graph,
      chartLoading,
      choseBookDialogVisible,
      bookList,
      treeData,
      queryParameters,
      bookId,
      book,
      personInfoForm,
      showOperateBar,
      operateBarLoading,
      isInit,
      currentItem,
      currentModel
    }
  },
  computed: {
    personInfoFormRule() {
      return {
        surname: {
          required: true,
          message: this.$t('user.请输入姓氏'),
          trigger: 'blur'
        },
        name: {
          required: true,
          message: 'tree.请输入名字',
          trigger: 'blur'
        }
      };
    },
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  mounted() {
    // 初始化关系图
    this.initG6Graph();
  },
  methods: {
    /**
     * 初始化树图
     */
    initG6Graph() {
      const t = this.$t;
      // 树图容器
      const container = document.getElementById('familyChart');
      // 树图宽度
      const graphWidth = container.scrollWidth;
      // 树图高度
      const graphHeight = container.scrollHeight || 500;
      // 树图浮窗
      const tooltip = new G6.Tooltip({
        getContent(e) {
          let model = e.item.getModel();
          const outDiv = document.createElement('div');
          outDiv.style.width = '180px';
          let spousesListDom = ``;
          let imgSrc = require('@/assets/images/family-tree/spouse_active.svg');
          model.spouses && model.spouses.forEach(s => {
            spousesListDom += `<div style="display: flex; justify-content: left; align-items: center;margin-top: 10px;">
                <img src="` + imgSrc + `" style="width: 2rem; height: 2rem;">
                <div style="margin-left: 10px;">
                    <div style="font-size: 14px;">${s.surname + s.name}</div>
                    <div style="font-size: 12px;">${s.birth_day ? s.birth_day.substring(0, 4) + t('tree.年') : t('tree.未知')} ${s.death == '1' ? t('tree.死亡') : t('tree.在世')}</div>
                </div></div>`
          });

          let title_str = t('tree.XX的配偶',{name:model.surname + model.name});
          outDiv.innerHTML = `<div style="padding: 5px;"><div style="font-size: 16px; font-weight: 600;">  ${title_str}</div>` + spousesListDom + `</div>`;
          return outDiv
        },
        shouldBegin: (e) => {
          const target = e.target;
          if (target.get('name') == 'female-spouses') {
            return true;
          }
          return false;
        },
        offsetX: -10,
        offsetY: 0,
        itemTypes: ['node'],
        trigger: 'click'
      });

      // 创建树图
      this.graph = new G6.TreeGraph({
        container: 'familyChart',
        width: graphWidth,
        height: graphHeight,
        plugins: [tooltip], // 配置 Tooltip 插件
        modes: {
          default: [
            {
              type: 'drag-canvas',
              allowDragOnItem: true
            },
            'zoom-canvas'
          ],
        },
        defaultNode: {
          type: 'tree-node',
          size: [240, 140],
          anchorPoints: [
            [0, 0.5],
            [1, 0.5],
          ],
        },
        defaultEdge: {
          type: 'polyline',
          style: {
            stroke: '#a6a6aa',
            lineWidth: 2,
            radius: 5
          }
        },
        layout: {
          type: 'mindmap',
          direction: 'H',
          // 节点宽度
          getWidth: () => {
            return 240;
          },
          // 节点高度
          getHeight: (d) => {
            if (d.dataType == 'newAdd') {
              return NODE_SHAPE_HEIGHT;
            } else if (d.children && d.children.length > 0) {
              return NODE_SHAPE_HEIGHT * 2 + MIDDLE_OPERATE_BAR_HEIGHT + d.children.length * CHILD_SHAPE_HEIGHT + LAST_OPERATE_BAR_HEIGHT;
            } else {
              return NODE_SHAPE_HEIGHT * 2 + MIDDLE_OPERATE_BAR_HEIGHT;
            }
          },
          // 节点横向间距
          getHGap: () => {
            return 40;
          },
          // 节点纵向间距
          getVGap: () => {
            return 10;
          },
          // 节点分布位置，左侧/右侧
          getSide: (d) => {
            if (d.data.nodeRoleType == 'father') {
              return 'right';
            }
            return 'left';
          },
        },
      });

      // 注册自定义节点形状
      registerG6CustomNode(this.$t);

      // 屏幕大小改变时，调整树图区域大小
      if (typeof window !== 'undefined') {
        window.onresize = () => {
          if (!this.graph || this.graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          this.graph.changeSize(container.scrollWidth, container.scrollHeight);
        };
      }

      // 处理各类点击监听
      this.handleNodeClick();

      // 如果传入家谱id,则直接查询家谱信息；否则获得家谱树列表
      if (this.$route.query['id']) {
        this.getTreeInfo(this.$route.query['id']);
      } else {
        this.getBookList(); // 查询家谱树列表
      }
    },
    /**
     * 获取家谱树信息
     */
    getTreeInfo(treeId) {
      treeInfo({id: treeId}).then(res => {
        if (res.code != SUCCESS_CODE || !res.data) {
          const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
          let messageSorry = this.$t('user.非常抱歉');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
          return;
        }
        this.book = res.data;
        this.queryParameters = {
          family_tree_id: res.data.id,
          uid: res.data.uid,
          node_id: res.data.first_node_id
        }
        if (this.queryParameters.node_id) { // 存在树节点
          // 获取树节点
          this.getTreeNodeListByParameters();
        } else if (!this.queryParameters.node_id) {
          // 选择是否初始化节点
          this.chooseInitTreeNode();
        }
      }).catch(error => {
        const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
        let messageSorry = this.$t('user.非常抱歉');
        let message = error && error.msg ? error.msg : this.$t('未查询到家谱信息');
        this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('user.我知道了'),
          callback: (action) => {
          }
        })
      })
    },
    /**
     * 调用查询树结构列表的方法
     * @param queryParameters
     */
    getTreeNodeListByParameters() {
      this.chartLoading = true;
      getTreeNodeList(this.queryParameters).then(res => {
        if (res.code === SUCCESS_CODE && res.data) {

          this.isMobileDevice ? this.$message({
            message: this.$t('该功能仅支持PC端浏览器操作'),
            type: 'info'
          }) : null;

          // 如果存在父节点，则不添加虚拟父节点，只处理末端子节点
          if (res.data.father_id != "0") {
            this.treeData = {...this.recursionData([res.data])[0], fatherCollapsed: true};
          } else {
            // 将树结构增加到顶级虚拟父节点下
            this.treeData = {
              id: Math.random().toString(),
              dataType: 'newAdd',
              children: this.recursionData([res.data])
            };
          }

          this.chartLoading = false;

          // 渲染图表
          this.graph.data(this.treeData);
          this.graph.render();
          this.isMobileDevice ? this.graph.zoomTo(0.2) : null;
          this.graph.focusItem(this.queryParameters.node_id.toString());

        } else {
          this.$message({
            message: this.$t('tree.未查询到数据'),
            type: 'warning',
          })
        }
      }).catch(error => {

      })

    },
    /**
     * 初始化节点
     */
    chooseInitTreeNode() {
      this.$confirm(
          this.$t('tree.当前家谱没有节点是否确认要初始化家谱树节点'),
          this.$t('tree.初始化家谱树'),
          {
            confirmButtonText: this.$t('user.确认'),
            cancelButtonText: this.$t('user.取消'),
            type: 'warning',
            customClass: 'confirm',
          }).then(() => {
            this.isInit = true;
        // 显示操作栏
        this.openOperateBar();
            this.personInfoForm = new TreePersonInfo({family_tree_id: this.book.id, surname: this.book.surname});
      }).catch(error => {

      })
    },
    /**
     * 处理末级节点，增加空子节点显示
     * @param data
     * @param currentData
     * @returns {*}
     */
    recursionData(data, currentData = null) {
      return data.map(item => {
        // 如果当前节点为链接节点
        if (currentData && item.id == currentData.id) {
          return currentData;
        }

        if (item.children && item.children.length > 0) {
          item.children = this.recursionData(item.children, currentData);
        } else if (item.children_ids && JSON.parse(item.children_ids).length){
          item.collapsed = true;
        }
        return item;
      })
    },
    /**
     * 获取家谱书列表
     */
    getBookList() {
      getSelfTreeList().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.bookList = res.data.filter(item => item.status == STATUS_NORMAL);

          if (this.bookList.length <= 0) { // 无家谱，选择去个人信息页面创建
            this.$confirm(
                this.$t('tree.是否去创建家谱'),
                this.$t('tree.尚无家谱'),
                {
                  confirmButtonText: this.$t('tree.去创建'),
                  cancelButtonText: this.$t('user.取消'),
                  type: 'warning',
                  customClass: 'confirm',
                }).then(() => {
              this.$router.push('/profile?tab=2');
            }).catch(() => {
            })
          } else if (this.bookList.length == 1) { // 只有一个家谱时，加载这个家谱的节点，无节点时初始化节点
            Object.assign(this.book, this.bookList[0]);
            this.chooseBook();
          } else { // 多个家谱时，选择加载家谱
            this.choseBookDialogVisible = true;
            this.bookId = this.bookList[0].id;
          }
        }
      })
    },
    /**
     * 选择家谱之后
     */
    chooseBook() {
      this.choseBookDialogVisible = false;
      this.book = this.bookList.find(item => item.id == this.bookId);
      this.$router.push({
        path: this.$route.path, // 保持当前路径不变
        query: {'id': this.book.id} // 更新路由参数
      });
      this.queryParameters = {
        family_tree_id: this.book.id,
        uid: this.book.uid,
        node_id: this.book.first_node_id
      }
      if (this.queryParameters.node_id) { // 存在树节点
        // 获取树节点
        this.getTreeNodeListByParameters();
      } else {
        // 选择是否初始化节点
        this.chooseInitTreeNode();
      }
    },
    /**
     * 处理展开收起
     * @param e
     */
    handleCollapse(e) {
      const item = e.item;
      const nodeModel = item.getModel();
      nodeModel.collapsed = !nodeModel.collapsed;
      if (!nodeModel.collapsed && (!nodeModel.children || nodeModel.children.length <= 0)) {
        // 请求子节点数据
        let params = {
          "family_tree_id": "1",
          "uid": "1",
          "node_id": nodeModel.id,
        }
        getTreeNodeList(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {
            this.graph.updateItem(item, this.recursionData([res.data])[0])
            // 更新布局
            this.graph.layout();
          } else {
            const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
            let messageSorry = this.$t('user.非常抱歉');
            this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('user.我知道了'),
              callback: (action) => {
              }
            })
          }
        }).catch(error => {
          const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
          let messageSorry = this.$t('user.非常抱歉');
          let message = error && error.msg ? error.msg : this.$t('tree.未查询到数据');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        })

      } else {
        this.graph.layout();
        this.graph.setItemState(item, 'collapse', nodeModel.collapsed);
      }
    },
    /**
     * 处理父节点展开收起
     * @param e
     */
    handleFatherCollapse(e) {
      const item = e.item;
      const nodeModel = item.getModel();
      nodeModel.fatherCollapsed = !nodeModel.fatherCollapsed;
      if (nodeModel.fatherCollapsed) {
        this.graph.changeData(nodeModel);
        this.graph.updateLayout();
        this.graph.focusItem(item);
      } else {
        // 请求数据
        let params= {
          "family_tree_id": "1",
          "uid": "1",
          "node_id": nodeModel.id
        }
        getTreeNodeParentList(params).then(res => {
          if (res.code == SUCCESS_CODE && res.data) {

            // 如果存在父节点，则不添加虚拟父节点，只处理末端子节点
            let treeData = {};
            if (res.data.father_id != "0") {
              treeData = {...this.recursionData([res.data], nodeModel)[0], fatherCollapsed: true};
            } else {
              // 将树结构增加到顶级虚拟父节点下
              treeData = {
                id: Math.random().toString(),
                dataType: 'newAdd',
                children: this.recursionData([res.data], nodeModel)
              };
            }

            this.graph.changeData(treeData);
            this.graph.updateLayout();
            this.graph.focusItem(nodeModel.id);

          } else {
            const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
            let messageSorry = this.$t('user.非常抱歉');
            this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('user.我知道了'),
              callback: (action) => {
              }
            })
          }
        }).catch(error => {
          const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
          let messageSorry = this.$t('user.非常抱歉');
          let message = error && error.msg ? error.msg : this.$t('tree.未查询到数据');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        })
      }
      this.graph.setItemState(item, 'father-collapse', nodeModel.fatherCollapsed);
    },
    /**
     * 提交信息
     */
    commit() {
      this.$refs.personInfoFormRef.validate((valid, fields) => {
        if (valid) {
          // 初始化节点
          if (this.isInit) {
            this.initTreeNode();
          } else if (this.personInfoForm.node_type == 1) { // 添加父亲
            this.addFather();
          } else if (this.personInfoForm.node_type == 3) { // 添加子女
            this.addChild();
          } else if (this.personInfoForm.node_type == 4) { // 添加配偶
            this.addSpouse();
          } else { // 更新
            this.updatePersonInfo();
          }
        }
      });
    },
    /**
     * 初始化节点
     */
    initTreeNode() {
      let params = new BasePersonInfo({
        ...this.personInfoForm
      })
      treeNodeInit(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.$message({
            message: this.$t('初始化节点成功'),
            type: 'success',
          })
          // 关闭操作栏
          this.closeOperateBar();
          // 关闭初始化
          this.isInit = false;
          this.book.first_node_id = res.data.first_node_id;
          this.queryParameters = {
            family_tree_id: this.book.id,
            uid: this.book.uid,
            node_id: this.book.first_node_id
          }
          this.getTreeNodeListByParameters();
        } else {
          const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
          let messageSorry = this.$t('user.非常抱歉');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        }
      }).catch(error => {
        const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
        let messageSorry = this.$t('user.非常抱歉');
        let message = error && error.msg ? error.msg : this.$t('tree.初始化失败');
        this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('user.我知道了'),
          callback: (action) => {
          }
        })
      })
    },
    /**
     * 添加父亲
     */
    addFather() {
      let params = {
        ...new BasePersonInfo(this.personInfoForm),
        family_tree_id: this.personInfoForm.family_tree_id,
        master_id: this.personInfoForm.master_id,
        node_type:  this.personInfoForm.node_type,
      }
      createTreeNode(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.$message({
            message: this.$t('tree.操作成功'),
            type: 'success',
          });
          Object.assign(this.currentModel, res.data);
          this.currentModel.dataType = '';
          this.graph.updateItem(this.currentItem, this.currentModel);
          // 将树结构增加到顶级虚拟父节点下
          this.treeData = {
            id: Math.random().toString(),
            dataType: 'newAdd',
            children: [this.currentModel]
          };

          // 渲染图表
          this.graph.data(this.treeData);
          this.graph.render();
          this.graph.focusItem(this.currentModel.id);
        } else {
          const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
          let messageSorry = this.$t('user.非常抱歉');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        }
      }).catch(error => {
        const messageIconUrl = require('@/assets/images/common/message_icon_error_load.svg');
        let messageSorry = this.$t('user.非常抱歉');
        let message = error && error.msg ? error.msg : '加入父亲失败';
        this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('user.我知道了'),
          callback: (action) => {
          }
        })
      })
    },
    /**
     * 添加子女
     */
    addChild() {
      let params = {
        ...new BasePersonInfo(this.personInfoForm),
        family_tree_id: this.personInfoForm.family_tree_id,
        master_id: this.personInfoForm.master_id,
        node_type:  this.personInfoForm.node_type,
      }
      if (this.personInfoForm.node_type == NODE_TYPE_CHILD && this.personInfoForm.relatedPersonInfo.spouses
          && this.personInfoForm.relatedPersonInfo.spouses.length && !this.personInfoForm.spouse_id ) {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('请选择母亲'));
        return;
      } else if(this.personInfoForm.node_type == NODE_TYPE_CHILD && this.personInfoForm.relatedPersonInfo.spouses
          && this.personInfoForm.relatedPersonInfo.spouses.length) {
        params.spouse_id = this.personInfoForm.spouse_id;
      }
      createTreeNode(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.$message({
            message: this.$t('tree.操作成功'),
            type: 'success',
          });
          this.graph.addChild(res.data, this.currentItem.get('id'));
        } else {
          const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
          let messageSorry = this.$t('user.非常抱歉');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        }
      }).catch(error => {
        this.$message({
          message: error && error.msg ? error.msg : this.$t('tree.操作失败'),
          type: 'error',
        })
      })
    },
    /**
     * 添加配偶
     */
    addSpouse() {
      let params = {
        ...new BasePersonInfo(this.personInfoForm),
        family_tree_id: this.personInfoForm.family_tree_id,
        master_id: this.personInfoForm.master_id,
        node_type:  this.personInfoForm.node_type,
      }
      createTreeNode(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.$message({
            message: this.$t('tree.操作成功'),
            type: 'success',
          });
          this.currentModel.spouses = [res.data];
          this.graph.updateItem(this.currentItem, this.currentModel);
        } else {
          const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
          let messageSorry = this.$t('user.非常抱歉');
          this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${res.msg}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('user.我知道了'),
            callback: (action) => {
            }
          })
        }
      }).catch(error => {
        this.$message({
          message: error && error.msg ? error.msg : this.$t('tree.操作失败'),
          type: 'error',
        })
      })
    },
    /**
     * 处理各类点击事件
     */
    handleNodeClick() {

      // 监听节点点击事件
      this.graph.on('node:click', ev => {
        const { item, target } = ev;
        const targetType = target.get('type');
        const shapeName = target.get('name');
        let model = item.getModel();
        this.currentItem = item;
        this.currentModel = model;
        // 点击节点移至窗口中心
        setTimeout(() => {
          this.graph.focusItem(item);
        }, 100)

        // 监听"加入父亲"的点击事件
        if (shapeName == 'male-add') {
          // 显示操作栏
          this.openOperateBar();
          this.personInfoForm = new TreePersonInfo({
            ...model,
            surname: model.children[0].surname,
            family_tree_id: this.book.id,
            master_id: model.children[0].id,
            node_type: 1,
            relatedPersonInfo: model.children[0]
          });
          return;
        } else if (shapeName == 'female-add') { // 监听"加入配偶"的点击事件
          // 显示操作栏
          this.openOperateBar();
          this.personInfoForm = new TreePersonInfo({
            family_tree_id: this.book.id,
            master_id: model.id,
            node_type: 4,
            sex: SEX_WOMAN,
            relatedPersonInfo: model
          });
          return;
        } else if (shapeName == 'add-children') { // 监听加子女的点击事件
          // 显示操作栏
          this.openOperateBar();
          this.personInfoForm = new TreePersonInfo({
            family_tree_id: this.book.id,
            surname: model.surname,
            master_id: model.id,
            node_type: 3,
            relatedPersonInfo: model
          });
          return;
        } else if (shapeName == 'male-name') { // 监听男性详情页的点击事件
          // 显示操作栏
          this.openOperateBar();
          // 获取人物信息
          this.getPersonInfo(model.id, model.family_tree_id);

        } else if (shapeName == 'female-name') { // 点击女性名称
          // 显示操作栏
          this.openOperateBar();
          // 获取人物信息
          this.getPersonInfo(model.spouses[0].id, model.family_tree_id);
        } else if (shapeName.startsWith('children-name-')) { // 点击孩子名称
          // 显示操作栏
          this.openOperateBar();
          let index = parseInt(shapeName.substring(14, shapeName.length));
          // 获取人物信息
          this.getPersonInfo(model.children[index].id, model.family_tree_id);
        }
      });

      // 子女列表展开的点击事件
      this.graph.on('children-expand-icon:click',(ev) => {
        const { item } = ev;
        item.childrenExpand = true;
        this.graph.updateItem(item, item);
      })

      // 子女列表收起的点击事件
      this.graph.on('children-collapse-icon:click',(ev) => {
        const { item } = ev;
        item.childrenExpand = false;
        this.graph.updateItem(item, item);
      })

      // 监听子节点展开收起按钮的点击事件
      this.graph.on('collapse-icon:click', (e) => {
        this.handleCollapse(e);
      });

      // 监听父节点展开收起按钮的点击事件
      this.graph.on('father-collapse-icon:click', (e) => {
        this.handleFatherCollapse(e);
      });
    },
    /**
     * 显示操作栏
     */
    openOperateBar() {
      this.showOperateBar = true;
      this.$refs.operateBar.classList.remove('slideOutLeft');
      this.$refs.operateBar.classList.add('slideInLeft');
      this.$refs.operateBar.scrollTo({top: 0});
    },
    /**
     * 关闭操作栏
     */
    closeOperateBar() {
      this.$refs.operateBar.classList.remove('slideInLeft');
      this.$refs.operateBar.classList.add('slideOutLeft');
      this.showOperateBar = false;
    },
    /**
     * 获取人物基础信息
     * @param personId
     * @param family_tree_id
     */
    getPersonInfo(personId, family_tree_id) {
      let params = {
        id: personId,
        family_tree_id: family_tree_id || this.book.id
      }
      this.operateBarLoading = true;
      getPersonInfo(params).then(res => {
        this.operateBarLoading = false;
        if (res.code == SUCCESS_CODE && res.data) {
          this.personInfoForm = new TreePersonInfo(res.data);
        } else {
          // 关闭操作栏
          this.closeOperateBar();
          // 消息弹窗确认
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        // 关闭loading
        this.operateBarLoading = false;
        // 关闭操作栏
        this.closeOperateBar();
        // 消息弹窗确认
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('人物信息加载失败'));
      })
    },
    updatePersonInfo() {
      let params = {
        ...new BasePersonInfo({...this.personInfoForm, family_tree_id: this.personInfoForm.family_tree_id || this.book.id}),
        id: this.personInfoForm.id
      };
      this.operateBarLoading = true;
      updatePersonInfo(params).then(res => {
        this.operateBarLoading = false;
        if (res.code == SUCCESS_CODE && res.data) {
          this.$message({
            message: this.$t('tree.更新成功'),
            type: 'success',
          });
          Object.assign(this.currentModel, params);
          this.graph.updateItem(this.currentItem, this.currentModel);
        } else {
          // 消息弹窗确认
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        // 关闭loading
        this.operateBarLoading = false;
        // 消息弹窗确认
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('tree.更新失败'));
      })
    }
  }
}
</script>

<style scoped lang="scss">
.family-tree-content {
  height: 100vh;
  background-color: var(--pale-blue);

  #familyChart {
    ::v-deep .el-loading-mask {
      z-index: 0;
    }
  }

  .operate-bar {
    width: 22rem;
    height: 100vh;
    overflow-y: auto;

    .place-holder {
      background-color: var(--light-orange);
      height: var(--header-height);
    }

    .operate-title {
      background-color: var(--light-orange);
      font-size: 1.6rem;
    }

    .avatar {
      width: 100%;
      height: 1.5rem;
      background-color: white;
      position: relative;

      img {
        object-fit: contain;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1.5rem;
      }
    }

    .view-info-options {

      &>div:nth-child(2)::before {
        content: '';
        width: 0.06rem;
        height: 1rem;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .operate-form {
      background-color: white;
      box-shadow: 0 20px 25px -5px rgba(20, 31, 86, 0.1), 0 8px 10px -6px rgba(20, 31, 86, 0.1);
      padding-top: 0.01rem;

      ::v-deep .el-form-item {
        margin-bottom: 0.4rem;
        margin-top: 0.8rem;
        display: block;

        &.zi, &.hao {
          display: inline-block;
          width: 48%;
        }

        &.hao {
          margin-left: 4%;
        }

        .el-form-item__label {
          font-size: 0.8rem;
          color: var(--dark-blue);
          line-height: 1rem;
          height: 1rem;
        }

        .el-input__wrapper {
          background-color: var(--grey-3);
        }

        .el-radio-button__inner {
          background-color: var(--grey-3);
        }

        .el-date-editor.death-day {
          width: 100%;
        }

        .el-date-editor.birth-day {
          width: 100%;
        }

        .el-radio-group {
          width: 100%;

          &.sex .el-radio-button {
            width: 33.33333%;
          }

          &.death .el-radio-button {
            width: 50%;
          }

          .el-radio-button {

            .el-radio-button__inner {
              width: 100%;
            }

            &.is-active {

              .el-radio-button__inner {
                background-color: var(--light-orange);
                border-color: var(--dark-grey);
              }
            }

            &:first-child .el-radio-button__inner {
              border-radius: 9999px 0 0 9999px;
            }

            &:last-child .el-radio-button__inner {
              border-radius: 0 9999px 9999px 0;
            }
          }
        }
      }
    }

    .operate-button {
      background-color: white;

      ::v-deep .el-button {
        display: inline-block;
        width: 100%;
        color: var(--dark-grey);
        border-radius: 9999px;
        height: var(--el-button-common-height);

        &+.el-button {
          margin-left: 0;
          margin-top: 0.6rem;
          background-color: var(--dark-blue);
          color: var(--light-orange);
        }
      }
    }
  }

  .operate-bar.slideInLeft {
    animation: slideInLeft 0.5s forwards;
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .operate-bar.slideOutLeft {
    animation: slideOutLeft 0.5s forwards;
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

::v-deep el-radio-button.is-active {

  .el-radio-button__inner {
    background-color: var(--light-orange);
  }
}
</style>
