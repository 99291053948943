<template>
  <!-- PC端 -->
  <div v-if="!isMobileDevice" class="surname-content pb-16 max-lg:hidden">
    <div class="top-banner">
      <div class="top-info mx-auto text-center">
        <div class="top-info-title font-medium">{{ $t('www.中华姓氏的历史渊源') }}<br/>{{ $t('www.您了解多少呢') }}</div>
        <div class="info mx-auto">{{ $t('www.五千年的华夏文明') }}</div>
        <CommonSearch class="mx-auto mt-8" v-model:search-value="searchValue" :place-holder="$t('www.请输入您的姓氏')"
                      :button-value="$t('www.搜索')" :common-search-width="'100%'" @click-button="searchSurnameInfo"></CommonSearch>
      </div>
    </div>

    <!--首字母筛选+姓氏筛选-->
    <SurnameFilter class="surname-filter mt-12 mx-auto px-4" :chosen-letter="chosenLetter" :chosen-surname-id="chosenSurnameId"
                   :justify-between="true" surname-item-bg-color="white" @init-chosen-surname="getSurnameInfo"
                   @change-chosen-surname="getSurnameInfo" @dbl-change-chosen-surname="getSurnameInfoAndScrollToView"></SurnameFilter>

    <!--姓氏简介-->
    <div id="surnameIntro" class="surname-intro mx-auto mt-10 px-4">
      <div class="surname-intro-title flex gap-x-2 justify-start items-center">
        <img src="@/assets/images/common/title_icon.svg" class="w-8 h-8">
        <span>{{ $t('www.姓氏简介') }}</span>
      </div>

      <div class="surname-intro-info flex rounded-2xl mt-2">
        <div class="intro-info-left w-1/3 rounded-l-2xl px-12 py-20">
          <template v-for="index in surnameInfo.surname?.length" :key="index">
            <div class="surname-name mx-auto mt-4">{{ surnameInfo.surname.substring(index - 1, index)}}</div>
          </template>
          <div class="view-genealogy mt-1 text-center">{{ $t('www.查看家谱',{name:surnameInfo.surname}) }}></div>
<!--          <el-descriptions class="detail-info mt-12"-->
<!--              :column="1"-->
<!--              direction="vertical"-->
<!--          >-->
<!--            <el-descriptions-item :label="$t('www.先祖名人')">饶干  饶子华  饶士珊   饶均义</el-descriptions-item>-->
<!--            <el-descriptions-item :label="$t('www.家谱')">平陽堂饒氏族譜九卷</el-descriptions-item>-->
<!--            <el-descriptions-item :label="$t('www.始祖')">-->
<!--              <span><span class="font-semibold">大溪饒氏家譜不分卷</span>(福建省漳州市平和縣)</span>-->
<!--              <el-descriptions class="book-info ml-4 text-sm"-->
<!--                  :column="1"-->
<!--              >-->
<!--                <el-descriptions-item :label="$t('www.摘要')">始祖念三，系出江西南豐梅溪，清季自潮之大埔縣入遷漳州府平和縣。七世孫永福定居平和縣大溪鄉，是为始遷祖。譜系記至十六世。該譜有族人遷臺资料書名據譜序题。</el-descriptions-item>-->
<!--                <el-descriptions-item :label="$t('www.撰修时间')">清光緒三十二年(1906)</el-descriptions-item>-->
<!--                <el-descriptions-item :label="$t('www.版本')">平装</el-descriptions-item>-->
<!--                <el-descriptions-item :label="$t('www.数量')">一册</el-descriptions-item>-->
<!--              </el-descriptions>-->
<!--            </el-descriptions-item>-->
<!--          </el-descriptions>-->
        </div>

        <div class="intro-info-right w-2/3 rounded-r-2xl p-16 pb-20">

          <el-descriptions class="basic-info"
                           :column="2">
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.拼音') }}</div>
              </template>
              <span>{{ surnameInfo.pinyin }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.姓氏属性') }}</div>
              </template>
              <span>不详</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.郡望') }}</div>
              </template>
              <span>{{ surnameInfo.junwang }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.人口') }}</div>
              </template>
              <span>{{ surnameInfo.population }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.堂号') }}</div>
              </template>
              <span class="ellipsis" :title="surnameInfo.halls">{{ surnameInfo.halls }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.排名') }}</div>
              </template>
              <span>不详</span>
            </el-descriptions-item>
          </el-descriptions>

          <el-divider />

          <el-descriptions class="detail-info" :column="1">
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.分布地域') }}</div>
              </template>
              <span>{{ surnameInfo.place }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.分布民族') }}</div>
              </template>
              <span>{{ surnameInfo.ethnic }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.姓氏源流') }}</div>
              </template>
              <div v-for="sourceItem in surnameInfo.source" :key="sourceItem.id">
                {{sourceItem.source_type}} {{sourceItem.content}}<br/><br/>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.历史文献') }}</div>
              </template>
              <span v-html="surnameInfo.history"></span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
  </div>

  <!-- 移动端 -->
  <div v-if="isMobileDevice" class="surname-content-mobile lg:hidden">
    <div v-if="!mobileHasResult" class="top-banner h-screen px-8 flex justify-center items-center">
      <div class="top-info w-full mx-auto text-center">
        <div class="top-info-title font-medium px-4">{{ $t('www.中华姓氏的历史渊源') }}<br/>{{ $t('www.您了解多少呢') }}</div>
        <div class="info mx-auto px-4">{{ $t('www.五千年的华夏文明') }}</div>

        <el-select
            v-model="chosenSurnameInfo.id"
            filterable
            :placeholder="$t('root.请输入您的姓氏')" class="surname-select w-full rounded-2xl mt-32" @change="changeSearchValue"
        >
          <template #prefix>
            <el-icon>
              <Search/>
            </el-icon>
          </template>
          <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
            <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
          </template>
        </el-select>
        <el-button class="primary-search-button w-full mt-8" round @click="searchSurnameInfo(chosenSurnameInfo)">{{ $t('www.搜索') }}</el-button>
      </div>
    </div>

    <div v-else class="top-banner-has-result bg-[var(--dark-blue)] px-8 pb-8">
      <CommonSearch class="mx-auto" v-model:search-value="searchValue" :place-holder="$t('www.请输入您的姓氏')"
                    :button-value="$t('www.搜索')" :common-search-width="'100%'" @click-button="searchSurnameInfo"></CommonSearch>
    </div>

    <!--姓氏简介-->
    <div v-if="mobileHasResult" id="surnameIntro" class="surname-intro mx-auto mt-10 px-4 mb-4">
      <div class="surname-intro-title flex gap-x-2 justify-start items-center">
        <img src="@/assets/images/common/title_icon.svg" class="w-8 h-8">
        <span>{{ $t('www.姓氏简介') }}</span>
      </div>

      <div class="surname-intro-info rounded-2xl mt-2">
        <div class="intro-info-left w-full rounded-l-2xl px-12 pt-8 pb-12">
          <template v-for="index in surnameInfo.surname?.length" :key="index">
            <div class="surname-name mx-auto mt-4">{{ surnameInfo.surname.substring(index - 1, index)}}</div>
          </template>
          <div class="view-genealogy mt-1 text-center">{{ $t('www.查看家谱',{name:surnameInfo.surname}) }}></div>
        </div>

        <div class="intro-info-right w-full p-8">

          <el-descriptions class="basic-info"
                           :column="1">
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.拼音') }}</div>
              </template>
              <span>{{ surnameInfo.pinyin }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.姓氏属性') }}</div>
              </template>
              <span>{{ $t('不详') }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.郡望') }}</div>
              </template>
              <span>{{ surnameInfo.junwang }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.人口') }}</div>
              </template>
              <span>{{ surnameInfo.population }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.堂号') }}</div>
              </template>
              <span class="ellipsis" :title="surnameInfo.halls">{{ surnameInfo.halls }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="basic-info-label">{{ $t('www.排名') }}</div>
              </template>
              <span>{{ $t('不详') }}</span>
            </el-descriptions-item>
          </el-descriptions>

          <el-divider />

          <el-descriptions class="detail-info" :column="1">
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.分布地域') }}</div>
              </template>
              <span>{{ surnameInfo.place }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.分布民族') }}</div>
              </template>
              <span>{{ surnameInfo.ethnic }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.姓氏源流') }}</div>
              </template>
              <div v-for="sourceItem in surnameInfo.source" :key="sourceItem.id">
                 {{sourceItem.content}}<br/><br/>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="detail-info-label">{{ $t('www.历史文献') }}</div>
              </template>
              <span v-html="surnameInfo.history"></span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>

    <!--底部-->
    <Footer v-if="mobileHasResult"></Footer>
  </div>
</template>

<script>
import {ref} from 'vue'
import CommonSearch from "@/components/CommonSearch";
import SurnameFilter from "@/components/SurnameFilter";
import {getSurnameInfo} from "@/api/surname";
import {SUCCESS_CODE} from "@/utils/constants";
import {getPubSurname} from "@/api/common";
import Footer from "@/components/Footer.vue";

export default {
  name: "Surname",
  components: {Footer, SurnameFilter, CommonSearch},
  setup() {
    const searchValue = ref('');

    // 选中的字母
    const chosenLetter = ref('');

    // 选中的姓氏ID
    const chosenSurnameId = ref(0);

    // 所选姓氏的信息
    const surnameInfo = ref({});

    // 移动端是否有结果
    const mobileHasResult = ref(false);

    const chosenSurnameInfo = ref({});
    const surnameOptions = ref([]);

    return {
      searchValue,
      chosenLetter,
      chosenSurnameId,
      surnameInfo,
      chosenSurnameInfo,
      surnameOptions,
      mobileHasResult
    }
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  created() {
    // 获取当前 URL 的查询参数
    const searchParams = new URLSearchParams(window.location.search);
    // 将查询参数转换为对象形式
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    this.chosenLetter = params['letter'];
    this.chosenSurnameId = params['surname-id'];
    if (this.chosenSurnameId) {
      this.getSurnameInfo(this.chosenLetter, this.chosenSurnameId);
    }
    // 获取姓氏筛选列表
    this.getRemoteSurname();
  },
  methods: {
    /**
     * 检索姓氏
     */
    searchSurnameInfo(surname) {
      if (!surname.id) {
        const messageIconUrl = require('@/assets/images/common/message_icon_common.svg');
        let messageSorry = this.$t('user.非常抱歉');
        let message = this.$t('暂未收录该姓氏数据');
        this.$alert(`<div class="message-info"><div>${messageSorry}</div><div>${message}</div><img class="w-24 h-24 mt-4" src="` + messageIconUrl + `"></div>`, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('user.我知道了'),
          callback: (action) => {
          }
        })
        return;
      }
      this.getSurnameInfoAndScrollToView(null, surname.id);
    },
    /**
     * 获取姓氏信息
     * @param letter
     * @param surnameId
     */
    getSurnameInfo(letter, surnameId) {
      this.chosenSurnameId = surnameId;
      let queryParams = {
        id: surnameId
      }
      getSurnameInfo(queryParams).then(res => {
        if(res.code == SUCCESS_CODE && res.data) {
          this.surnameInfo = res.data;
          this.chosenLetter = res.data.zimu;
          this.mobileHasResult = true;
        } else {
          this.surnameInfo = {};
        }
      }).catch(error => {

      })
    },
    /**
     * 获取姓氏信息并将姓氏信息滚动到视线内
     * @param letter
     * @param surnameId
     */
    getSurnameInfoAndScrollToView(letter, surnameId) {
      this.getSurnameInfo(letter, surnameId);
      setTimeout(() => {
        const target = document.getElementById('surnameIntro');
        target.scrollIntoView({behavior: "smooth"});
      }, 1000)
    },
    /**
     * 获取姓氏筛选列表
     */
    getRemoteSurname() {
      getPubSurname().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.surnameOptions = res.data;
        } else {
          this.surnameOptions = [];
        }
      }).catch(error => {
        this.surnameOptions = [];
      })
    },
    changeSearchValue(value) {
      this.chosenSurnameInfo.surname = this.surnameOptions.find(item => item.id == value).surname;
      this.chosenSurnameId = value;
      this.searchValue = this.chosenSurnameInfo.surname;
    },
  }
}
</script>

<style scoped lang="scss">
.surname-content {
  background-color: var(--lighter-grey);

  .top-banner {
    height: 45rem;
    padding-top: calc(8rem + var(--header-height));
    background-image: url("../../assets/images/surname/surname_banner.jpg");
    background-size: 100% 100%;

    .top-info {
      width: 42rem;
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      .top-info-title {
        font-size: var(--common-title-font-size);
        line-height: 1.3;
        color: var(--dark-blue);
      }

      .info {
        width: 30rem;
        font-size: 1rem;
        color: var(--dark-blue);
        margin-top: 0.6rem;
      }
    }
  }

  .surname-filter {
    width: var(--common-content-width);
    max-width: 100%;
  }

  .surname-intro {
    width: var(--common-content-width);
    max-width: 100%;

    .surname-intro-title {
      color: var(--dark-blue);
      font-size: 1.5rem;
    }

    .surname-intro-info {
      background-color: white;

      .intro-info-left {

        .surname-name {
          background-image: url("../../assets/images/surname/tianzige.svg");
          background-size: 100% 100%;
          width: 8rem;
          height: 8rem;
          font-size: 4.4rem;
          color: var(--dark-blue);
          line-height: 8rem;
          text-align: center;
          font-weight: 700;
        }

        .view-genealogy {
          color: var(--light-orange);
        }

        .detail-info {

          ::v-deep .el-descriptions__label.is-vertical-label {
            font-weight: 700;
            position: relative;
            padding-left: 0.6rem;

            &:before {
              content: "";
              width: 0.1rem;
              height: 0.94rem;
              background-color: black;
              position: absolute;
              top: 0.3rem;
              left: 0.06rem;
            }
          }

          ::v-deep .el-descriptions__content.is-vertical-content {
            color: black;
            padding-bottom: 1rem;
          }

          .book-info {

            ::v-deep .el-descriptions__cell {
              padding-bottom: 0;
              font-size: 0.875rem;

              .el-descriptions__label {
                font-weight: 600;
              }

              .el-descriptions__content {
                color: black;
              }
            }
          }
        }
      }

      .intro-info-right {
        background-color: #f1f1f1;

        ::v-deep .el-descriptions__body {
          background-color: transparent;
        }

        .basic-info {
          .basic-info-label {
            display: inline-block;
            width: 4rem;
            text-align: justify;
            text-align-last: justify;
            position: relative;
          }

          .basic-info-label::after {
            content:'：';
            position: absolute;
            top: 0;
            right: -1rem;
          }

          ::v-deep tr {
            display: flex;
            gap: 1rem;

            .el-descriptions__cell {
              width: 50%;
              vertical-align: top;
              display: flex;
            }
          }

          .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis; /* 显示省略号 */
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        ::v-deep .el-divider {
          border-color: var(--middle-grey);
          margin: 0.6rem 0 1rem;
        }

        .detail-info {
          .detail-info-label {
            display: inline-block;
            width: 4rem;
            text-align: justify;
            text-align-last: justify;
            position: relative;
          }

          .detail-info-label::after {
            content:'：';
            position: absolute;
            top: 0;
            right: -1rem;
          }

          ::v-deep .el-descriptions__cell {
            display: flex;
          }
        }
      }
    }
  }
}

.surname-content-mobile {
  background-color: white;

  .top-banner {
    background-image: url("../../assets/images/surname/surname_mobile_banner.jpg");
    background-size: 100% 100%;

    .top-info {

      .top-info-title {
        font-size: var(--common-title-font-size);
        line-height: 1.3;
        color: white;
      }

      .info {
        font-size: 1rem;
        color: white;
        margin-top: 0.6rem;
      }

      ::v-deep .surname-select {
        .el-select__wrapper {
          border-radius: 9999px;
          height: 3rem;
        }
      }
    }
  }

  .top-banner-has-result {
    padding-top: calc(var(--header-height) + 1rem);
  }

  .surname-filter {
    width: var(--common-content-width);
    max-width: 100%;
  }

  .surname-intro {
    width: var(--common-content-width);
    max-width: 100%;

    .surname-intro-title {
      color: var(--dark-blue);
      font-size: 1.5rem;
    }

    .surname-intro-info {
      background-color: white;

      .intro-info-left {

        .surname-name {
          background-image: url("../../assets/images/surname/tianzige.svg");
          background-size: 100% 100%;
          width: 8rem;
          height: 8rem;
          font-size: 4.4rem;
          color: var(--dark-blue);
          line-height: 8rem;
          text-align: center;
          font-weight: 700;
        }

        .view-genealogy {
          color: var(--light-orange);
        }

        .detail-info {

          ::v-deep .el-descriptions__label.is-vertical-label {
            font-weight: 700;
            position: relative;
            padding-left: 0.6rem;

            &:before {
              content: "";
              width: 0.1rem;
              height: 0.94rem;
              background-color: black;
              position: absolute;
              top: 0.3rem;
              left: 0.06rem;
            }
          }

          ::v-deep .el-descriptions__content.is-vertical-content {
            color: black;
            padding-bottom: 1rem;
          }

          .book-info {

            ::v-deep .el-descriptions__cell {
              padding-bottom: 0;
              font-size: 0.875rem;

              .el-descriptions__label {
                font-weight: 600;
              }

              .el-descriptions__content {
                color: black;
              }
            }
          }
        }
      }

      .intro-info-right {
        background-color: var(--grey-2);

        ::v-deep .el-descriptions__body {
          background-color: transparent;
        }

        .basic-info {
          .basic-info-label {
            display: inline-block;
            width: 4rem;
            text-align: justify;
            text-align-last: justify;
            position: relative;
          }

          .basic-info-label::after {
            content:'：';
            position: absolute;
            top: 0;
            right: -1rem;
          }

          ::v-deep tr {
            display: flex;
            gap: 1rem;

            .el-descriptions__cell {
              width: 100%;
              vertical-align: top;
              display: flex;
            }
          }

          .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis; /* 显示省略号 */
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        ::v-deep .el-divider {
          border-color: var(--middle-grey);
          margin: 0.6rem 0 1rem;
        }

        .detail-info {
          .detail-info-label {
            display: inline-block;
            width: 4rem;
            text-align: justify;
            text-align-last: justify;
            position: relative;
          }

          .detail-info-label::after {
            content:'：';
            position: absolute;
            top: 0;
            right: -1rem;
          }

          ::v-deep .el-descriptions__cell {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
