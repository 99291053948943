<template>
  <div class="auth-content flex flex-col justify-between">
    <div class="flex items-center flex-auto">
      <div class="center-content max-w-full mx-auto px-4">
        <div class="h-full relative">
          <div class="left-img hidden lg:block">
            <img class="w-full h-full bg-cover" src="@/assets/images/auth/left_main_img.png">
          </div>
          <div class="right-content w-full lg:w-1/2 px-8 sm:px-32 pb-8">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>

    <Footer class="grow-0 shrink-0"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  name: "Auth",
  components: {Footer}
}
</script>

<style scoped lang="scss">
.auth-content {
  background-image: url("@/assets/images/auth/bg.jpg");
  background-size: 100% 100%;
  min-height: 100vh;
  padding-top: calc(4rem + var(--header-height));

  .center-content {
    width: 82rem;
    height: 39rem;
    border-radius: 2rem;
    position: relative;

    .left-img {
      width: 54%;
      height: 100%;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    .right-content {
      height: 100%;
      background-color: white;
      border-radius: 2rem;
      position: absolute;
      right: 0;
      top: 0;
      padding-top: 4.5rem;
    }
  }

  ::v-deep .footer-content {
    border: 0;
    background-color: transparent;
    color: var(--dark-grey);
  }

  ::v-deep .footer-content-mobile {
    margin-top: 4rem;
  }
}
</style>