<template>
  <div class="surname-filter-content">
    <div class="flex gap-x-4 flex-wrap gap-y-4" :class="{'justify-center': !justifyBetween, 'justify-between': justifyBetween}">
      <div class="letter rounded-full text-center cursor-pointer" :class="{active: letter == activeLetter}"
           v-for="letter in letters" :key="letter" @click="changeLetter(letter)">{{ letter.toUpperCase() }}</div>
    </div>
    <div class="surname-list grid gap-4 mt-8" :style="{height: surnameListHeight}">
      <div class="surname-item cursor-pointer" v-for="surnameItem in limitedSurnames" :key="surnameItem.id"
           :class="{active: activeSurnameId == surnameItem.id || chosenSurnameId == surnameItem.id}"
           :style="{backgroundColor: surnameItemBgColor}" @mouseenter="activeSurnameId = surnameItem.id;"
           @mouseleave="activeSurnameId = chosenSurnameId" @click="changeChosenSurname(surnameItem.id)" @dblclick="dblChangeSurname(surnameItem.id)">
        <div class="surname-item-title relative pt-3 px-3 pb-1" :title="surnameItem.surname + ' ' + surnameItem.pinyin"><span class="font-bold text-2xl">{{surnameItem.surname}}</span><span class="ml-2">{{surnameItem.pinyin}}</span></div>
        <div class="surname-item-info flex p-3 pb-4 justify-between">
          <div>{{surnameItem.books_num}}{{ $t('www.册') }}</div>
          <div class="text-right">
            <div>{{ $t('www.先祖名人') }}<span>{{surnameItem.celebrity_num}}</span></div>
            <div class="mt-2">{{ $t('www.始于') }}<span>{{surnameItem.start_in}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineEmits } from 'vue'
import { SUCCESS_CODE } from '@/utils/constants'
import {getSurnameList, getSurnameZimu} from "@/api/surname";

export default {
  name: "SurnameFilter",
  props: {
    // 姓氏单条的背景色
    surnameItemBgColor: String,
    // 每个字母最多显示几个姓氏
    limit: Number,
    // 选中的字母
    chosenLetter: String,
    // 选中的姓氏
    chosenSurnameId: Number,
    // 姓氏列表区域限制高度
    surnameListHeight: String,
    // 字母两端对齐
    justifyBetween: Boolean,
  },
  setup() {

    // 首字母
    const letters = ref([]);
    // 当前选中首字母
    const activeLetter = ref('');
    // 姓氏列表
    const surnames = ref([]);
    // 当前选中姓氏
    const activeSurnameId = ref(0);

    return {
      letters,
      activeLetter,
      surnames,
      activeSurnameId
    }
  },
  computed: {
    limitedSurnames() {
      return this.limit ? this.surnames.slice(0, this.limit) : this.surnames;
    }
  },
  watch: {
    chosenLetter() {
      this.activeLetter = this.chosenLetter;
      this.getSurnameList(this.activeLetter);
    },
    chosenSurnameId() {
      this.activeSurnameId = this.chosenSurnameId;
    }
  },
  created() {
    this.activeLetter = this.chosenLetter;
    this.activeSurnameId = this.chosenSurnameId;
    // 获得姓氏首字母列表
    this.getSurnameZimu();
  },
  methods: {
    /**
     * 生成26个字母
     */
    generateAlphabet() {
      const alphabet = [];
      for (let i = 65; i <= 90; i++) {
        alphabet.push(String.fromCharCode(i));
      }
      return alphabet;
    },
    /**
     * 获得姓氏首字母列表
     */
    getSurnameZimu() {
      let params = {
        "id": 1
      }
      getSurnameZimu(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data && res.data.length >= 0) {
          this.letters = res.data.map(item => item.zimu);
        } else {
          this.letters = this.generateAlphabet();
        }
        if (!this.activeLetter) {
          this.activeLetter = this.letters[0];
          this.getSurnameList(this.activeLetter)
        }
      }).catch(error => {

      })
    },
    /**
     *
     * @param letter
     */
    getSurnameList(letter) {
      let params = {
        zimu: letter
      }
      getSurnameList(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data && res.data.length >= 0) {
          // this.surnames = res.data.map(item => {
          //   item.pinyin = 'rao';
          //   item.typeNum = 305;
          //   item.famousNum = 572;
          //   item.startYear = 1602;
          //   return item;
          // });
            this.surnames = res.data;
          if (!this.activeSurnameId) {
            this.activeSurnameId = this.surnames[0].id
            this.$emit("init-chosen-surname", this.activeLetter, this.activeSurnameId);
          }
        } else {
          this.surnames = [];
          this.activeSurnameId = '';
        }
      }).catch(error => {

      })
    },
    /**
     * 改变选中字母
     * @param letter
     */
    changeLetter(letter) {
      this.activeLetter = letter;
      this.getSurnameList(letter);
    },
    /**
     * 更改选中姓氏
     * @param surnameId
     */
    changeChosenSurname(surnameId) {
      this.activeSurnameId = surnameId;
      this.$emit("change-chosen-surname", this.activeLetter, surnameId);
    },
    /**
     * 更改选中姓氏，并使元素滚动到视线中
     * @param surnameId
     */
    dblChangeSurname(surnameId) {
      this.changeChosenSurname(surnameId);
      this.$emit("dbl-change-chosen-surname", this.activeLetter, surnameId);
    }
  }
}
</script>

<style scoped lang="scss">
.surname-filter-content {
  .letter {
    background-color: var(--grey-1);
    color: white;
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.475rem;

    &.active {
      background-color: var(--light-orange);
    }
  }

  .surname-list {
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(7rem, 1fr));
    overflow: hidden;

    .surname-item {
      border: 0.06rem solid var(--light-grey);

      .surname-item-title {
        //border-bottom: 0.06rem solid var(--light-grey);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &::after {
          content: '';
          width: 90%;
          height: 0.08rem;
          background-color: var(--light-grey);
          position: absolute;
          bottom: 0;
          left: 5%;
        }
      }

      .surname-item-info {

        &>div:first-child {
          font-size: 1.2rem;
          line-height: 1.2rem;
          color: var(--darker-grey);
        }

        &>div:last-child {
          font-size: 0.9rem;
          line-height: 0.9rem;

          span {
            color: var(--darker-grey);
          }
        }
      }

      &.active {

        .surname-item-title {
          background-color: var(--light-orange);

          &::after {
            background-color: var(--light-orange);
          }
        }

        .surname-item-info {
          background-color: var(--dark-blue);
          color: white;

          &>div:first-child {
            color: white;
          }

          &>div:last-child {

            span {
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
