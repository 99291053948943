import {createI18n} from 'vue-i18n';
import en from './langs/en.json';
import zh from './langs/zh.json';
import tw from './langs/tw.json';


import www_zh from './langs/zh/www.json';
import home_zh from './langs/zh/home.json';
import user_zh from './langs/zh/user.json';
import tree_zh from './langs/zh/tree.json';
import toots_zh from './langs/zh/roots.json';

import www_en from './langs/en/www.json';
import home_en from './langs/en/home.json';
import user_en from './langs/en/user.json';
import tree_en from './langs/en/tree.json';
import toots_en from './langs/en/roots.json';


import www_tw from './langs/tw/www.json';
import home_tw from './langs/tw/home.json';
import user_tw from './langs/tw/user.json';
import tree_tw from './langs/tw/tree.json';
import toots_tw from './langs/tw/roots.json';


const i18n = createI18n({
    allowComposition: true,
    locale: 'zh', // 默认语言
    messages: {
        en: {
            ...en,
            www: www_en,
            home: home_en,
            user: user_en,
            tree: tree_en,
            root: toots_en
        },
        zh: {
            ...zh,
            www: www_zh,
            home: home_zh,
            user: user_zh,
            tree: tree_zh,
            root: toots_zh
        },
        tw: {
            ...tw,
            www: www_tw,
            home: home_tw,
            user: user_tw,
            tree: tree_tw,
            root: toots_tw
        }
    }
});

export default i18n;
