import axios from 'axios';
import store from '@/store';
import moment from 'moment';
import util from '@/utils/util'
import {ERROR_CODE, ERROR_LOG_IN_SIGN, ERROR_SIGN} from "@/utils/constants";
import {ElMessage} from 'element-plus'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Accept'] = 'application/json;charset=utf-8'

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 设置基础URL
    timeout: 60000, // 设置超时时间
});

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        config.headers['Lang'] =  store.getters.lang;
        config.headers['H-Access-Token'] =  store.getters.getToken;

        // get请求映射params参数
        if (config.method === 'get') {
            // 拼接时间戳和版本参数
            config.params = {
                ...config.params,
                version: process.env.VUE_APP_VERSION,
                ts: moment().unix().toString()
            }
            // 获取签名字符串
            config.params.sign = util.getSign(config.params, config.needAccessToken);

            let url = config.url + '?';
            for (const propName of Object.keys(config.params)) {
                const value = config.params[propName];
                var part = encodeURIComponent(propName) + "=";
                if (value !== null && typeof(value) !== "undefined") {
                    if (typeof value === 'object') {
                        for (const key of Object.keys(value)) {
                            let params = propName + '[' + key + ']';
                            var subPart = encodeURIComponent(params) + "=";
                            url += subPart + encodeURIComponent(value[key]) + "&";
                        }
                    } else {
                        url += part + encodeURIComponent(value) + "&";
                    }
                }
            }
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        } else if (config.method === 'post'){
            // 拼接时间戳和版本参数
            config.data = {
                ...config.data,
                version: process.env.VUE_APP_VERSION,
                ts: moment().unix().toString()
            }
            // 获取签名字符串
            config.data.sign = util.getSign(config.data, config.needAccessToken);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        if (response.data.code === ERROR_CODE && (response.data.sub_code == ERROR_SIGN || response.data.sub_code == ERROR_LOG_IN_SIGN) ) {
            store.dispatch('removeTokenStore');
            if (response.config.silence) {
                return Promise.reject(response.data);
            }
            ElMessage({
                message: response.data.sub_msg,
                type: 'error',
                duration: '1000',
                onClose: () => {
                    location.href = '/auth/login';
                }
            })
        }
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 6. 在需要发送请求的地方使用该实例
export default service;
