import request from '@/utils/request'

/**
 * 获得姓氏首字母列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSurnameZimu(data) {
    return request({
        url: '/api/web/surname/zimu',
        method: 'post',
        data
    })
}

/**
 * 通过首字母获得姓氏列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSurnameList(data) {
    return request({
        url: 'api/web/surname/list',
        method: 'post',
        data
    })
}

/**
 * 获得姓氏信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSurnameInfo(data) {
    return request({
        url: 'api/web/surname/info',
        method: 'post',
        data
    })
}