<template>
  <div class="login-content">
    <!--登录注册标题-->
    <div class="login-register-title flex justify-center">
      {{$t("user.重置密码")}}
    </div>

    <!--登录内容项-->
    <div class="login-input mt-8">
      <el-form ref="resetPwdFormRef"
               :model="resetPwdForm" class="register-form flex flex-wrap"
               :rules="rules"
               label-width="auto">
        <el-form-item :label="$t('user.登录账号')" prop="login_name" class="w-full" :class="{active: resetPwdForm.login_name}">
          <el-input :placeholder="$t('user.登录账号')" v-model="resetPwdForm.login_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.手机号码')" prop="mobile" class="mobile w-full" :class="{active: resetPwdForm.mobile}">
          <div class="absolute h-10 left-14 top-0 leading-10 text-[var(--dark-grey)]">+{{resetPwdForm.country}}</div>
          <vue-tel-input class="w-full h-10 mb-2.5"
                         v-model="resetPwdForm.mobile" mode="national" :input-options="{placeholder: $t('user.手机号码')}" :auto-format="false"
                         :default-country="defaultCountry" @country-changed="changeCountry" ></vue-tel-input>
        </el-form-item>
        <el-form-item :label="$t('user.短信验证码')" prop="sms_code" class="sms w-full" :class="{active: resetPwdForm.sms_code}">
          <el-input :placeholder="$t('user.短信验证码')" v-model="resetPwdForm.sms_code">
            <template #append>
              <el-button @click="getSMS" :disabled="smsCountdown > 0">{{ smsCountdown > 0 ? smsCountdown : $t('user.发送验证码') }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('user.重置密码')" prop="passwd" class="w-full" :class="{active: resetPwdForm.passwd}">
          <el-input :placeholder="$t('user.重置密码')" v-model="resetPwdForm.passwd" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.确认密码')" prop="passwd_confirmation" class="w-full" :class="{active: resetPwdForm.passwd_confirmation}">
          <el-input :placeholder="$t('user.确认密码')" v-model="resetPwdForm.passwd_confirmation" type="password"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <el-button round @click="resetPwd()">{{$t('user.重置密码')}}</el-button>
    <el-button round style="margin-left: 0;" @click="$router.push('/auth/login')">{{$t('返回')}}</el-button>
  </div>
</template>

<script>
import {getLoginSMS, userResetPwd} from "@/api/auth";
import {MESSAGE_ICON_COMMON, MESSAGE_ICON_ERROR, SUCCESS_CODE} from "@/utils/constants";
import {ref, reactive} from 'vue'
import util from '@/utils/util';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "ResetPwd",
  components: {
    VueTelInput
  },
  setup() {
    // 注册表单
    const resetPwdForm = reactive({
      login_name: '',
      passwd: '',
      passwd_confirmation: '',
      country: '86',
      mobile: '',
      sms_code: ''
    });

    // 短信倒计时秒数
    const smsCountdown = ref(0);

    const defaultCountry = ref('cn');

    return {
      resetPwdForm,
      smsCountdown,
      defaultCountry,
    }
  },
  computed: {
    rules() {

      // 验证密码
      const validatePasswd = (rule, value, callback) => {
        if (this.resetPwdForm.passwd !== '') {
          this.$refs.resetPwdFormRef.validateField('passwd_confirmation', () => null)
        }
        callback()
      }

      // 验证确认密码
      const validatePasswdConfirmation = (rule, value, callback) => {
        if (value !== this.resetPwdForm.passwd) {
          callback(new Error('密码与确认密码不一致'))
        } else {
          callback()
        }
      }

      return {
        login_name: {
          required: true,
          message: this.$t('user.请输入登录账号'),
          trigger: 'blur'
        },
        passwd: [{
          required: true,
          message: this.$t('user.请输入密码'),
          trigger: 'blur'
        }, {
          min: 5, message: '至少为5个字符', trigger: 'blur'
        },{
          validator: validatePasswd, trigger: 'blur'
        }],
        passwd_confirmation: [{
          required: true,
          message: this.$t('user.请输入确认密码'),
          trigger: 'blur'
        }, {
          min: 5, message: '至少为5个字符', trigger: 'blur'
        },{
          validator: validatePasswdConfirmation, trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: this.$t('user.请输入手机号码'),
          trigger: 'blur'
        }],
        sms_code: {
          required: true,
          message: this.$t('user.请输入短信验证码'),
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    /**
     * 获取验证码
     */
    getSMS() {
      if (!this.resetPwdForm.mobile) {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), this.$t('user.请输入手机号码'));
        return;
      }
      getLoginSMS({"mobile": this.resetPwdForm.mobile, country: this.resetPwdForm.country}).then(res => {
        if (res.code == SUCCESS_CODE) {
          this.smsCountdown = 60;
          const timer = setInterval(() => {
            this.smsCountdown--;
            if (this.smsCountdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
          // TODO 临时弹窗显示密码
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, '注意', res.msg);
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('获取短信验证码失败'));
      });
    },
    /**
     * 重置密码
     */
    resetPwd() {
      let params = {
        login_name: this.resetPwdForm.login_name,
        mobile: this.resetPwdForm.mobile,
        sms_code: this.resetPwdForm.sms_code,
        passwd: this.resetPwdForm.passwd,
      }
      userResetPwd(params).then(res => {
        if (res.code == SUCCESS_CODE) {
          this.$message({
            message: this.$t('重置密码成功'),
            type: 'success',
            onClose: () => {
              // 跳转至登录页
              this.$router.push('/auth/login');
            }
          })
        } else {
          util.commonAlert(this.$t, MESSAGE_ICON_COMMON, this.$t('user.非常抱歉'), res.msg);
        }
      }).catch(error => {
        util.commonAlert(this.$t, MESSAGE_ICON_ERROR, this.$t('user.非常抱歉'), error && error.msg ? error.msg : this.$t('重置密码失败'));
      })
    },
    changeCountry(value) {
      this.resetPwdForm.country = value.dialCode;
    }
  }
}
</script>

<style scoped lang="scss">
.login-content {
  position: relative;
  width: 100%;
  height: 100%;

  .login-register-title {
    font-size: 1.8rem;
    padding: 0 1.5rem;
    position: relative;
    cursor: pointer;
    color: var(--dark-blue);
  }

  .login-input {


    ::v-deep .register-form {

      .el-form-item__error {
        font-size: 1rem;
        right: 0.25rem;
        padding-top: 0.125rem;
        bottom: 0.7rem;
        left: unset;
        top: unset;
      }

      .el-form-item.sms .el-form-item__error {
        right: 7.65rem;
      }

      .el-form-item {
        position: relative;
        margin-bottom: 0;

        .el-form-item__label-wrap {
          position: absolute;
          top: 0;
          left: 1rem;
          z-index: 1;
          display: none;
          transform: translateY(-50%);
          background-image: linear-gradient(to bottom, white 0%, white 51%, var(--blue-2) 52%, var(--blue-2) 100%);
          height: 0.875rem;
          line-height: 1;

          .el-form-item__label {
            color: var(--middle-grey);
            height: 0.875rem;
            font-size: 0.875rem;
            line-height: 1;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
          }
        }

        &.active {

          .el-form-item__label-wrap {
            display: block;
            animation: from-bottom-up 0.2s forwards;
          }

          @keyframes from-bottom-up {
            0% {
              transform: translateY(0) scale(1.2);
            }
            100% {
              transform: translateY(-50%) scale(1);
            }
          }

          .el-input__wrapper {
            background-color: var(--blue-2);
          }

          .vue-tel-input {
            background-color: var(--blue-2);

            .vti__input {
              background-color: var(--blue-2);
            }

          }
        }
      }

      .el-form-item.surname {

        .el-input__wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .el-form-item.name {

        .el-input__wrapper {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: 0 0.06rem 0 0 var(--el-input-border-color) inset, 0 -0.06rem 0 0 var(--el-input-border-color) inset, -0.06rem 0 0 0 var(--el-input-border-color) inset;
        }

        &.is-error {
          .el-input__wrapper {
            box-shadow: 0 0.06rem 0 0 var(--el-color-danger) inset, 0 -0.06rem 0 0 var(--el-color-danger) inset, -0.06rem 0 0 0 var(--el-color-danger) inset;
          }
        }
      }

      .el-input-group__append {
        padding: 0;
        background-color: var(--light-grey);
        width: 7.4rem;

        .el-button {
          width: 100%;
          height: 100%;
          background-color: transparent;
          color: var(--dark-grey);
          border-radius: 0;
          margin-top: 0;
          padding: 0;
          line-height: 100%;
        }
      }

      .vue-tel-input {
        border: 0.06rem solid var(--el-border-color);

        .vti__dropdown {
          padding: 0.5rem;

          .vti__selection {

            .vti__flag {
              width: 1.25rem;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }

        }

        .vti__dropdown-list {
          width: 24rem;
        }

        .vti__input {
          padding-left: 2.5rem;
        }
      }

      .vue-tel-input:focus-within {
        box-shadow: unset;
        border-color: var(--light-orange);
      }

      .vue-tel-input {
        border: 0.06rem solid var(--el-border-color);

        .vti__dropdown {
          padding: 0.5rem;

          .vti__selection {

            .vti__flag {
              width: 1.25rem;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }

        }

        .vti__dropdown-list {
          width: 24rem;
        }

        .vti__input {
          padding-left: 2.5rem;
        }
      }

      .vue-tel-input:focus-within {
        box-shadow: unset;
        border-color: var(--light-orange);
      }
    }

    ::v-deep .el-input {
      height: 2.5rem;
      margin-bottom: 0.6rem;
      font-size: 1.3rem;

      .el-input__wrapper {
        padding: 0 1rem;
      }
    }
  }

  ::v-deep .el-button {
    width: 100%;
    height: 3.4rem;
    margin-top: 1rem;
    background-color: var(--blue-1);
    color: white;
    border-radius: 9999px;
    line-height: 3.4rem;
  }
}

</style>
