import {BasePersonInfo} from "./BasePersonInfo";

export class TreePersonInfo extends BasePersonInfo{

    constructor(obj) {
        super(obj);
        // 初始化类字段
        this.master_id = obj && obj.master_id || '';
        this.spouse_id = obj && obj.spouse_id || '';
        this.node_type = obj && obj.node_type || 0;
        this.children = obj && obj.children || [];
        this.children_ids = obj && obj.children_ids || [];
        this.father_id = obj && obj.father_id || '';
        this.id = obj && obj.id || '';
        this.mather = obj && obj.mather || {};
        this.mather_id = obj && obj.mather_id || '';
        this.parent_id = obj && obj.parent_id || '';
        this.ranking = obj && obj.ranking || '';
        this.spouse_ids = obj && obj.spouse_ids || [];
        this.spouses = obj && obj.spouses || [];
        this.relatedPersonInfo = obj && obj.relatedPersonInfo || {};
    }
}
