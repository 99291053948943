<template>
  <div>
    <div class="genealogy-content max-lg:bg-[var(--middle-blue)] max-lg:pt-[var(--header-height)] max-lg:px-8 max-lg:pb-10">
      <!--地图-->
      <div id="geo_map" class="w-full h-screen max-lg:h-96"></div>

      <!--谱牒档案搜索-->
      <div class="genealogy-search-and-result lg:absolute lg:left-40 rounded-3xl lg:flex"
           :style="{width: !isMobileDevice && showDetailInfo ? '40rem' : !isMobileDevice && !showDetailInfo ? '20rem' : '100%', height: isMobileDevice ? 'auto' : 'calc(100vh - 8rem)'}">
        <!--搜索-->
        <div class="genealogy-search rounded-3xl px-10 py-12 relative lg:w-80 lg:h-full max-lg:h-auto max-lg:w-full">
          <div class="title text-center">
            <span v-if="confirmSearchSurname">{{confirmSearchSurname}}</span>
            <span>{{ $t('www.谱牒档案') }}</span>
          </div>

          <!--搜索方式切换-->
          <div class="search-type mt-1 px-2 py-1 max-lg:hidden">
            <el-radio-group v-model="searchType" class="w-full justify-between" @change="changeSearchType">
              <el-radio value="1" size="small">{{ $t('快捷搜索') }}</el-radio>
              <el-radio value="2" size="small">{{ $t('高级搜索') }}</el-radio>
            </el-radio-group>
          </div>

          <!--快捷检索-->
          <div class="quick-search mt-6 ">
            <span class="quick-search-title text-base">{{ $t('您的姓氏') }}</span>
            <el-select v-model="searchSurname" :placeholder="''" class="rounded-full"
                       filterable @change="setChosenSurname">
              <template v-for="surnameItem in surnameOptions" :key="surnameItem.id">
                <el-option :label="surnameItem.alias" :value="surnameItem.id"></el-option>
              </template>
            </el-select>
            <el-form v-if="searchType == 2" ref="advancedSearchFormRef" :model="advancedSearchForm"
                     class="advanced-search-form mt-2.5" label-position="top" :rules="advancedSearchFormRules">
              <el-form-item :label="$t('祖籍地省份')" prop="province_id">
                <el-select :class="{active:advancedSearchForm.province_id}" v-model="advancedSearchForm.province_id"
                           :placeholder="$t('祖籍地省份')" @change="getCityOptions" clearable>
                  <template v-for="province in provinceOptions" :key="province.id">
                    <el-option :label="province.aname" :value="province.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('祖籍地城市')" prop="city_id">
                <el-select :class="{active:advancedSearchForm.city_id}" class="mt-2.5" v-model="advancedSearchForm.city_id"
                           :placeholder="$t('祖籍地城市')" clearable @change="cityChange">
                  <template v-for="city in cityOptions" :key="city.id">
                    <el-option :label="city.aname" :value="city.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button round class="primary-search-button h-10 w-full mt-2" @click="quickSearchBySurname">{{ $t('搜索') }}</el-button>
          </div>

          <!--检索结果-->
          <div v-if="provinceList && provinceList.length > 0" class="genealogy-search-result mt-8 w-full max-lg:hidden">
            <div class="result-chosen">
              <span>{{ $t('所有位置') }}</span>
              <span> > {{ $t('中国') }}</span>
              <!--            <span> > {{ $t('安徽省') }}</span>-->
            </div>
            <div class="result-title flex justify-between items-center">
              <div>{{ $t('您的家谱出现在') }}</div>
              <div>
                <span class="result-number">{{ genealogyBookPlacesNum }}&nbsp;</span>
                <span>{{ $t('位置') }}</span>
              </div>
            </div>
            <div class="result-sub-title">{{ $t('范围内的') }}{{ provinceList.length }}{{ $t('个地区') }}</div>
            <el-collapse v-if="!placesCollapse" v-model="activeCollapseName" :accordion="true" @change="collapseChange">
              <template v-for="provinceItem in provinceList" :key="provinceItem.title">
                <el-collapse-item :name="provinceItem.title">
                  <template #title>
                    <div class="flex justify-between w-full relative">
                      <span>{{ provinceItem.title }}</span>
                      <span>{{ provinceItem.num }}</span>
                    </div>
                  </template>
                  <div class="result-detail flex justify-end items-center text-end" v-for="cityItem in provinceItem.children" :key="cityItem.city">
                    <div class="w-1/2 common-ellipsis">{{ cityItem.title }}</div>
                    <div class="w-1/2">{{ cityItem.num }}</div>
                  </div>
                </el-collapse-item>
              </template>
            </el-collapse>
            <div v-if="searchType == 2" class="places-collapse-button cursor-pointer" :class="{expand: placesCollapse}" @click="placesCollapse = !placesCollapse">{{ placesCollapse ? $t('全部展开') : $t('全部收起') }}</div>
          </div>
        </div>

        <!--最终结果-->
        <div v-if="showDetailInfo" class="genealogy-result py-24 max-lg:py-12 pl-10 pr-12" :style="{width: isMobileDevice ? '100%' : 'calc(100% - 20rem)'}">
          <div class="result-title relative"><img class="w-6 h-6" src="@/assets/images/genealogy/search_result_icon.svg">{{ $t('搜索结果共') }}{{ total_count }}{{ $t('项') }}</div>
          <div class="h-full overflow-y-auto">
            <div class="result-detail-item mt-6" v-for="bookItem in bookList" :key="bookItem.id">
              <div class="text-base font-semibold">{{ bookItem.book_name }}</div>
              <div class="flex mt-1 py-2 justify-between">
                <div class="w-2/3 pr-2">
                  <div v-if="bookItem.author" class="truncate-2-lines">{{ $t('修撰者') }}:&nbsp;{{bookItem.author}}</div>
                </div>
                <div class="w-1/3 flex items-center pl-3 cursor-pointer text-[var(--darker-grey)] cursor-pointer">
                  <a :href="'/genealogy-detail?book_id=' + bookItem.id"
                     target="_blank">{{ $t('查看详情') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img v-if="showDetailInfo" class="detail-collapse absolute w-4 h-auto lg:left-80 max-lg:left-[22rem] cursor-pointer max-lg:hidden" src="@/assets/images/genealogy/collapse.svg" @click="showDetailInfo = false;">
      </div>
    </div>

    <Footer class="lg:hidden"></Footer>
  </div>
</template>

<script>
import {ref, reactive} from 'vue';
import * as echarts from 'echarts';
import geoJson from '@/assets/map/100000_full.json'
import '@/assets/styles/common.scss'
import {getPubCity, getPubSurname} from "@/api/common";
import {SUCCESS_CODE} from "@/utils/constants";
import {getCityBooks, getGenealogyBook} from "@/api/genealogy";
import booksInfoSvg from '@/assets/images/genealogy/booksInfo.svg'
import {getJSONData} from "@/api/map";
import Footer from "@/components/Footer.vue";

export default {
  name: "Genealogy",
  components: {Footer},
  setup() {

    // 搜索方式
    const searchType = ref('1');

    // 搜索姓氏
    const searchSurname = ref('');
    const confirmSearchSurname = ref('');
    // 姓氏选择列表
    const surnameOptions = ref([]);

    // 姓氏检索省份列表和城市列表
    const provinceList = ref([]);

    // 结果当前展开面板
    const activeCollapseName = ref('');

    // 是否展示详情
    const showDetailInfo = ref(false);

    // 家谱列表
    const bookList = ref([]);
    const total_count = ref(0);

    // 地图数据
    const mapDataResult = reactive([]);

    // 搜索地区列表是否收起
    const placesCollapse = ref(false);

    // 高级检索表单
    const advancedSearchForm = reactive({});

    // 省市数据
    const provinceOptions = reactive([]);
    const cityOptions = ref([]);

    return {
      // 样式
      rootStyles: getComputedStyle(document.documentElement),
      // 搜索方式
      searchType,
      searchSurname,
      confirmSearchSurname,
      provinceList,
      surnameOptions,
      showDetailInfo,
      activeCollapseName,
      bookList,
      total_count,
      mapDataResult,
      placesCollapse,
      advancedSearchForm,
      provinceOptions,
      cityOptions,
    }
  },
  computed: {
    // 家谱总数
    genealogyBookPlacesNum() {
      return this.provinceList.reduce((pre,cur) => {
        return pre + cur.num;
      }, 0)
    },
    // 当前激活的省份数据
    activeProvinceInfo() {
      return this.provinceList.find(item => item.title == this.activeCollapseName);
    },
    advancedSearchFormRules() {
      return {
        province_id: [
          {required: true, message: this.$t('请选择省份'), trigger: "blur"}
        ]
      };
    },
    isMobileDevice() {
      return window.innerWidth < 960;
    }
  },
  created() {
    // 获取姓氏筛选列表
    this.getRemoteSurname();
    // 获取省份列表
    this.getProvinceOptions()
  },
  mounted() {
    this.initGeoMap();
    if (this.$route.query['surname']) {
      this.searchSurname = this.$route.query['surname'];
      this.quickSearchBySurname();
    }
    this.isMobileDevice ? this.searchType = 2 : null;
  },
  methods: {
    /**
     * 初始化地图
     */
    initGeoMap() {
      let echartsMapDom = document.getElementById("geo_map");    // 地图dom元素
      if (!echartsMapDom){
        return
      }
      let echartsMapInstance = echarts.getInstanceByDom(echartsMapDom);
      if( !echartsMapInstance ){     // 如果实例不存在，则重新初始化实例
        echartsMapInstance = echarts.init(echartsMapDom);
      }
      echarts.registerMap("china", geoJson);
      echartsMapInstance.showLoading();

      const _this = this;

      let option = {
        tooltip: {
          show: true
        },
        geo: {
          map: 'china',
          center: null,
          layoutSize: '120%',
          layoutCenter: this.isMobileDevice ? ["50%", "70%"] : ["60%", "70%"],
          label: {
            show: this.isMobileDevice ? false : true,
            fontSize: 12,
            color: this.rootStyles.getPropertyValue('--dark-blue'),
            zlevel: 10,
            emphasis: {
              show:false, //取消悬浮显示覅去名称效果
            },
          },
          roam: true, //是否允许缩放
          emphasis: {
            disabled: true
          },
          itemStyle: {
            normal: {
              color: this.rootStyles.getPropertyValue('--middle-blue'), //地图背景色
              borderColor: this.rootStyles.getPropertyValue('--light-blue'), //省市边界线00fcff 516a89
              borderWidth: 1,
              areaColor: this.rootStyles.getPropertyValue('--pale-white')
            },
            emphasis:{
              show:false,
              areaColor: this.rootStyles.getPropertyValue('--middle-blue'), //悬浮背景
            },
          },
          select:{
            disabled: true
          },
          tooltip: {
            show: true,
            position: function (point, params, dom, rect, size) {
              return [point[0], point[1]];
            },
            formatter(params) {
              return params.name;
            },
            className: 'aaa',
          }
        },
        series: [
          {
            name: 'booksNum',
            type: 'scatter',
            coordinateSystem: 'geo',
            symbol:`image://${require('@/assets/images/genealogy/location.svg')}`,
            symbolSize: [26, 35],
            label: {
              normal: {
                show: true,
                offset: [0, -5],
                textStyle: {
                  color: '#141f56',
                  fontSize: 14,
                },
                formatter(value) {
                  return '{label|' + value.data.num + '}';
                },
                rich: {
                  label: {
                    color: '#141f56',
                    align: 'center',
                    fontSize: 14,
                    lineHeight: 14
                  },
                },
              },
            },
            itemStyle: {
              normal: {},
              emphasis: {
                areaColor: 'rgba(150, 0, 0, 0.35)', //悬浮背景
              }
            },
            data: [],
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
              scale: 1000
            },
            hoverAnimation: true,
            zlevel: 1,
            tooltip: {
              show: true,
              position: 'top',
              formatter(params) {
                if (params.seriesType == 'scatter' && params.data.name && params.data.num) {
                  return `<div style="color: #f8972c; text-align: center; font-size: 1rem; line-height: 2.8;">${_this.confirmSearchSurname}氏谱牒档案</div>` +
                      `<div style="color: #141f56; text-align: center; font-size: 1rem; line-height: 1.5;">${params.data.name}</div>` +
                      `<div style="color: #141f56; text-align: center; font-size: 1rem; line-height: 1.5;">${params.data.num}个收藏</div>`
                } else {
                  return '';
                }
              },
              renderMode:"html",
              appendToBody:true,
              textStyle: {
                color: "#fff",
                fontSize: 12,
                lineHeight: 15,
              },
              extraCssText: 'padding: 0 !important;\n' +
                  '  border: none !important;\n' +
                  '  background-color: transparent !important;\n' +
                  '  background: url(' + booksInfoSvg + ') center center no-repeat;\n' +
                  '  background-size: contain;\n' +
                  '  width: 9rem;\n' +
                  '  height: 7rem;\n' +
                  '  color: #fff;\n' +
                  '  justify-content: start;',
            }
          }
        ],
      };
      setTimeout(() => {
        echartsMapInstance.setOption(option, true);
        echartsMapInstance.off("click");// 点击前解绑，防止点击事件触发多次
        echartsMapInstance.hideLoading();

        // 屏幕大小改变时，调整树图区域大小
        if (typeof window !== 'undefined') {
          window.onresize = () => {
            echartsMapInstance.resize();
          };
        }
      }, 500)
    },
    /**
     * 获取姓氏筛选列表
     */
    getRemoteSurname() {
      getPubSurname().then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.surnameOptions = res.data;
        } else {
          this.surnameOptions = [];
        }
      }).catch(error => {
        this.surnameOptions = [];
      })
    },
    /**
     * 姓氏筛选
     * @param queryString
     * @returns {*}
     */
    surnameSearch(queryString) {
      return this.surnameOptions.filter(item => item.alias.toLowerCase().indexOf(queryString.toLowerCase()) >= 0).map(item => {
        return {value: item.surname}
      });
    },
    /**
     * 快速搜索
     */
    quickSearchBySurname() {
      if (!this.searchSurname) {
        this.$message({
          message: this.$t('请输入姓氏'),
          type: 'info',
        });
        return;
      }
      this.confirmSearchSurname = this.surnameOptions.find(item => item.id == this.searchSurname)?.surname;
      let params = {
        surname_id: this.searchSurname,
        province_id: this.advancedSearchForm.province_id || '',
        city_id: this.advancedSearchForm.city_id || ''
      }
      getGenealogyBook(params).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          if (!this.advancedSearchForm.province) {
            this.provinceList = res.data.location;

            // 处理地图散点图数据
            this.handleMapData(this.provinceList)
          } else {
            this.provinceList.some(item => {
              if (item.title == this.advancedSearchForm.province) {
                item.children = res.data.location;
                return true;
              }
              return false;
            });

            // 获取渲染地图数据
            this.getProvinceMap(this.advancedSearchForm.province, res.data.location);
          }
          this.bookList = res.data.list;
          this.bookList && this.bookList.length ? this.showDetailInfo = true : null;
          this.total_count = res.data.total_count;
        } else {
          this.provinceList = [];
        }
      }).catch(error => {
        this.provinceList = [];
      })
    },
    collapseChange(activeName) {
      if(!activeName) {
        this.showDetailInfo = false;
        return;
      }

      this.advancedSearchForm.province = activeName;

      this.quickSearchBySurname();
    },
    /**
     * 获取省份地图数据
     */
    getProvinceMap(provinceName, mapData) {
      let province = geoJson.features.find(provinceItem => provinceItem.properties.name == provinceName);
      if (!province) {
        return;
      }
      getJSONData(province.properties.adcode).then(res => {
        let provinceGeoJson = res.data;
        echarts.registerMap(province.properties.adcode, provinceGeoJson);

        let echartsMapDom = document.getElementById("geo_map");    // 地图dom元素
        if (!echartsMapDom){
          return
        }
        let echartsMapInstance = echarts.getInstanceByDom(echartsMapDom);
        if( !echartsMapInstance ){     // 如果实例不存在，不任何处理
          return
        }

        this.mapDataResult = mapData.map(item => {
          item.name = item.title;
          let city = provinceGeoJson.features.find(cityItem => cityItem.properties.name == item.title);
          city ? item.value = city.properties.center: null;
          return item;
        });

        echartsMapInstance.setOption({
          geo: {
            map: province.properties.adcode,
            layoutSize: '100%',
            layoutCenter: this.isMobileDevice ? ["50%", "56%"] : ["70%", "56%"]
          },
          series: [{
            name: 'booksNum',
            data: this.mapDataResult
          }]
        })

      })
    },
    /**
     * 处理地图散点数据
     * @param mapData
     */
    handleMapData(mapData) {

      let echartsMapDom = document.getElementById("geo_map");    // 地图dom元素
      if (!echartsMapDom){
        return
      }
      let echartsMapInstance = echarts.getInstanceByDom(echartsMapDom);
      if( !echartsMapInstance ){     // 如果实例不存在，不任何处理
        return
      }
      echarts.registerMap("china", geoJson);

      this.mapDataResult = mapData.map(item => {
        item.name = item.title;
        let province = geoJson.features.find(provinceItem => provinceItem.properties.name == item.title);
        province ? item.value = province.properties.center: null;
        return item;
      });

      setTimeout(() => {
        echartsMapInstance.setOption({
          geo: {
            map: 'china',
            center: null,
            layoutSize: '120%',
            layoutCenter: ["60%", "70%"],
          },
          series: [{
            name: 'booksNum',
            data: this.mapDataResult
          }]
        })
      }, 500);

    },
    setChosenSurname() {

    },
    /**
     * 获取省份可选列表
     */
    getProvinceOptions() {
      getPubCity({parent_id: 1}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          Object.assign(this.provinceOptions, res.data);

          // 获取城市可选列表
          if (this.advancedSearchForm.province_id) {
            this.getCityOptions(this.advancedSearchForm.province_id);
          }
        } else {
          this.provinceOptions = [];
        }
      }).catch(error => {
        this.provinceOptions = [];
      })
    },
    /**
     * 获取市区可选列表
     */
    getCityOptions(value) {
      this.advancedSearchForm.province = this.provinceOptions.find(item => item.id == value).aname;
      this.advancedSearchForm.city_id = null;
      this.advancedSearchForm.city = null;
      getPubCity({parent_id: value}).then(res => {
        if (res.code == SUCCESS_CODE && res.data) {
          this.cityOptions = res.data;
        } else {
          this.cityOptions = [];
        }
      }).catch(() => {
        this.cityOptions = [];
      })
    },
    /**
     * 城市改变
     */
    cityChange(value) {
      this.advancedSearchForm.city = this.cityOptions.find(item => item.id == value).aname;
    },
    /**
     * 高级表单重置
     */
    advancedSearchReset() {
      Object.assign(this.advancedSearchForm, {
        province_id: null,
        city_id: null
      })
    },
    /**
     * 高级检索
     */
    advancedSearch() {
      this.$refs.advancedSearchFormRef.validate((valid, fields) => {
        if (valid) {
          let province = this.provinceOptions.find(item => item.id == this.advancedSearchForm.province_id);
          let params = {
            surname: this.searchSurname,
            province: province.aname,
          }
          getCityBooks(params).then(res => {
            if (res.code == SUCCESS_CODE && res.data) {
              this.provinceList.some(item => {
                if (item.title == province.aname) {
                  item.children = res.data.location;
                  return true;
                }
                return false;
              });
              this.showDetailInfo = true;
              this.bookList = res.data.list;

              // 获取渲染地图数据
              this.getProvinceMap(province.aname, res.data.location);
            }
          }).catch(error => {
          })
        }
      });
    },
    changeSearchType(value) {
      if (value == 1) {
        this.advancedSearchForm.province_id = null;
        this.advancedSearchForm.province = null;
        this.advancedSearchForm.city_id = null;
        this.advancedSearchForm.city = null;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.genealogy-content {

  #geo_map {
    background-color: var(--middle-blue);
  }

  .genealogy-search-and-result {
    height: calc(100vh - 8rem);
    background-color: var(--light-orange);
    top: calc(1rem + var(--header-height));

    .genealogy-search {
      border: 0.06rem solid var(--middle-grey);
      background-color: white;
      overflow-y: auto;

      .title {
        font-size: 2.4rem;
        color: var(--dark-blue);
      }

      .search-type {
        border: 0.06rem solid var(--light-grey);
        border-left: none;
        border-right: none;

        ::v-deep .el-radio {
          margin-right: 0.2rem;

          .el-radio__input.is-checked {

            &+.el-radio__label {
              color: var(--dark-blue);
            }

            .el-radio__inner {
              background-color: var(--dark-blue);
              border-color: var(--dark-blue);
            }
          }

          .el-radio__input {

            .el-radio__inner {
              width: 0.8rem;
              height: 0.8rem;

              &::after {
                width: 0.4rem;
                height: 0.4rem;
              }
            }
          }

          .el-radio__label {
            font-size: 1rem;
          }
        }

        .el-radio.el-radio--small {
          height: 1.5rem;
        }

        .el-radio:last-child::before {
          content: '';
          width: 0.06rem;
          height: 1rem;
          position: absolute;
          top: 50%;
          left: -1.5rem;
          background-color: var(--light-grey);
          transform: translateY(-50%);
        }
      }

      .quick-search {

        .quick-search-title {
          color: var(--dark-blue);
        }

        ::v-deep .el-autocomplete {
          width: 100%;
          height: 2.6rem;
          margin-top: 0.2rem;

          .el-input {
            height: 100%;
            width: 100%;

            .el-input__wrapper {
              border-radius: 9999px;
              background-color: var(--lighter-grey);
              padding: 0.06rem 1rem;
              height: 100%;

              .el-input__prefix-inner>:last-child {
                margin-right: 1rem;
              }
            }
          }
        }

        .primary-search-button {
          min-width: unset;
        }

        :deep(.el-select__wrapper) {
          border-radius: 9999px;
          height: 2.5rem;
        }

        ::v-deep .advanced-search-form {

          .el-form-item__error {
            font-size: 0.75rem;
            right: 0.25rem;
            padding-top: 0.125rem;
            bottom: 0.5rem;
            left: unset;
            top: unset;
          }

          .el-form-item {
            position: relative;
            margin-bottom: 0;

            .el-form-item__label {
              display: none;
            }

            .el-form-item__label-wrap {
              position: absolute;
              top: 0;
              left: 1rem;
              z-index: 1;
              display: none;
              transform: translateY(-50%);
              background-image: linear-gradient(to bottom, white 0%, white 51%, var(--blue-2) 52%, var(--blue-2) 100%);
              height: 0.875rem;
              line-height: 1;

              .el-form-item__label {
                color: var(--middle-grey);
                height: 0.875rem;
                font-size: 0.875rem;
                line-height: 1;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              }
            }

            &.active {

              .el-form-item__label-wrap {
                display: block;
                animation: from-bottom-up 0.2s forwards;
              }

              @keyframes from-bottom-up {
                0% {
                  transform: translateY(0) scale(1.2);
                }
                100% {
                  transform: translateY(-50%) scale(1);
                }
              }

              .el-input__wrapper {
                background-color: var(--blue-2);
              }
            }
          }
        }
      }

      .genealogy-search-result {

        .result-chosen {
          color: var(--dark-blue);
          font-size: 0.9rem;
        }

        .result-title {
          color: var(--dark-blue);
          line-height: 1.8rem;
          font-weight: 600;
          border-bottom: 0.06rem solid var(--light-grey);

          .result-number {
            font-size: 1.2rem;
            color: var(--light-orange);
          }
        }

        .result-sub-title {
          font-size: 0.9rem;
          color: var(--dark-grey);
          border-bottom: 0.06rem solid var(--light-grey);
        }

        ::v-deep .el-collapse {
          border-color: var(--light-grey);
          border-top: 0.06rem solid var(--el-collapse-border-color);

          .el-collapse-item {

            .el-collapse-item__header {
              height: 2.4rem;
              color: var(--dark-blue);
              padding-left: 1.2rem;
              position: relative;
              border-bottom: 0.06rem solid var(--el-collapse-border-color);
              line-height: 1;

              .el-collapse-item__arrow {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border: 0.06rem solid var(--light-grey);
                border-radius: 9999px;
                padding: 0.06rem;

                &.is_active {
                  svg {
                    transform: rotate(90deg);
                  }
                }
              }

              &.is-active {
                border-bottom: none;
              }
            }

            .el-collapse-item__wrap {
              border-color: var(--light-grey);

              .el-collapse-item__content {
                padding-bottom: 0.6rem;

                .result-detail {
                  color: var(--dark-blue);
                  font-size: 0.8rem;
                }
              }
            }
          }

        }

        .places-collapse-button {
          width: 6rem;
          background-image: url("@/assets/images/genealogy/places_collapse.svg");
          background-size: contain;
          background-repeat: no-repeat;
          font-size: 0.75rem;
          margin: 0 auto;
          text-align: center;
          padding-left: 0.6rem;
          color: white;

          &.expand {
            background-image: url("@/assets/images/genealogy/places_expand.svg");
          }
        }
      }

      .advanced-search {


        ::v-deep .el-button {
          color: white;
          background-color: var(--light-orange);

          &.first {
            border-radius: 9999px 0 0 9999px;
            background-color: var(--middle-grey);
          }

          &.last {
            border-radius: 0 9999px 9999px 0;
          }
        }
        ::v-deep .el-button+.el-button {
          margin-left: 0;
        }
      }
    }

    .genealogy-result {
      color: var(--dark-blue);
      font-size: 0.8rem;
      position: relative;

      .result-title {

        img {
          position: absolute;
          left: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .result-detail-item {

        &>div:nth-child(2) {
          border-top: 0.06rem solid var(--middle-grey);
          border-bottom: 0.06rem solid var(--middle-grey);

          &>div:last-child {
            border-left: 0.06rem solid var(--middle-grey);
          }
        }
      }
    }

    .detail-collapse {
      top: 50%;
      transform: translateY(-50%);
    }
  }

}
</style>
